<div>
  <app-header-component></app-header-component>

  <div class="card-details-mobile-wrapper" *ngIf="!isDesktop">
    <div class="spinner-container" *ngIf="isLoading">
      <div class="title-section">
        <div class="title">
          <span>Validating Card</span>
        </div>
      </div>
      <mat-spinner mat-primary></mat-spinner>
    </div>
    <div class="card-details-container" *ngIf="!isLoading">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction"></app-card-info-box>
      <div class="title-section">
        <div class="title">
          <span>Enter Payment Details</span>
        </div>
        <div class="stepper">
          <app-stepper [numSteps]="numSteps" [selectedStep]="selectedStep"></app-stepper>
        </div>
      </div>

      <form [formGroup]="cardFormGroup" class="form-wrapper">

        <div class="row">
          <mat-label>Cardholder Name</mat-label>
          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="cardholderName" id="cardholderName" autocomplete="cc-name">
            <mat-error *ngIf="isControlHasError('cardholderName', 'required')">
              Cardholder Name Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('cardholderName', 'maxlength')">
              Cardholder Name cannot exceed Max Length.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Card Number</mat-label>
          <mat-form-field appearance="outline">
            <input type="text" inputmode="decimal" matInput formControlName="cardNumber" id="cardNumber" maxlength="16" autocomplete="cc-number">
            <mat-error *ngIf="isControlHasError('cardNumber', 'required')">
              Card Number Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('cardNumber', 'minlength')
            || isControlHasError('cardNumber', 'maxlength')">
              Card Number must be 16 digits.
            </mat-error>
          </mat-form-field>

        </div>
        <div class="row">
          <div class="form-field">
            <mat-label>Expiry Date</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" inputmode="decimal" matInput formControlName="expiryDate" id="expiryDate" placeholder="MM/YY"
                [textMask]="{mask: expiryDateMask}" autocomplete="cc-exp">
              <mat-error *ngIf="isControlHasError('expiryDate', 'required')">
                Expiry Date Required
              </mat-error>
              <mat-error
                *ngIf="isControlHasError('expiryDate', 'pattern') || isControlHasError('expiryDate', 'invalidYear')">
                Must be valid expiry date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-field">
            <mat-label>CVC</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" inputmode="decimal" matInput formControlName="cvc" id="cvc" autocomplete="cc-csc">
              <mat-error *ngIf="isControlHasError('cvc', 'required')">
                CVC required
              </mat-error>
              <mat-error *ngIf="isControlHasError('cvc', 'minlength') || isControlHasError('cvc', 'maxlength')">
                Invalid CVC
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-field form-field">
            <mat-label>Address</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="streetName" id="streetName" autocomplete="address-line1">
              <mat-error *ngIf="isControlHasError('streetName', 'required')">
                <span class="error-label table-error">Cannot be empty</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <mat-label>Country</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="countryControl" (selectionChange)="updateAddressOptions($event)">
              <mat-select-trigger>
                <img src="{{cardFormGroup.value.countryControl.img}}">
                {{ cardFormGroup.value.countryControl.viewValue }}
              </mat-select-trigger>
              <mat-option *ngFor="let country of countries" [value]="country">
                <img src="{{country.img}}">
                {{ country.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="isControlHasError('countryControl', 'required')">
              <span class="error-label table-error">Cannot be empty</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="isCanadaOrUS">
          <div class="form-field">
            <mat-label>Province/State</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="provinceState">
                <mat-option *ngFor="let province of provinces" [value]="province">{{ province }}</mat-option>
              </mat-select>
              <mat-error *ngIf="isControlHasError('provinceState', 'required')">
                <span class="error-label table-error">Cannot be empty</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-field">
            <mat-label>Postal/Zip</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" id="textInput" name="textInput" matInput formControlName="postalCode" id="postalCode" autocomplete="postal-code">
              <mat-error *ngIf="isControlHasError('postalCode', 'pattern')">
                <span class="error-label table-error">Postal code/Zip code not valid</span>
              </mat-error>
              <mat-error *ngIf="isControlHasError('postalCode', 'required')">
                <span class="error-label table-error">Cannot be empty</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="btn-wrapper">
          <button (click)="nextPage()" class="next-btn">
            <span>Next</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="card-details-wrapper" *ngIf="isDesktop">
    <div class="spinner-container" *ngIf="isLoading">
      <div class="title-section">
        <div class="title">
          <span>Validating Card</span>
        </div>
      </div>
      <mat-spinner mat-primary></mat-spinner>
    </div>
    <div class="card-details-container" *ngIf="!isLoading">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction"></app-card-info-box>
      <div class="title-section">
        <div class="title">
          <span>Enter Payment Details</span>
        </div>
        <div class="stepper">
          <app-stepper [numSteps]="numSteps" [selectedStep]="selectedStep"></app-stepper>
        </div>
      </div>

      <form [formGroup]="cardFormGroup" class="form-wrapper">

        <div class="row">
          <mat-label>Cardholder Name</mat-label>
          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="cardholderName" id="cardholderName" autocomplete="cc-name">
            <mat-error *ngIf="isControlHasError('cardholderName', 'required')">
              Cardholder Name Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('cardholderName', 'maxlength')">
              Cardholder Name cannot exceed Max Length.
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Card Number</mat-label>
          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="cardNumber" id="cardNumber" maxlength="16" autocomplete="cc-number">
            <mat-error *ngIf="isControlHasError('cardNumber', 'required')">
              Card Number Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('cardNumber', 'minlength')
            || isControlHasError('cardNumber', 'maxlength')">
              Card Number must be 16 digits.
            </mat-error>
          </mat-form-field>

        </div>
        <div class="row">
          <div class="form-field">
            <mat-label>Expiry Date</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="expiryDate" id="expiryDate" placeholder="MM/YY"
                [textMask]="{mask: expiryDateMask}" autocomplete="cc-exp">
              <mat-error *ngIf="isControlHasError('expiryDate', 'required')">
                Expiry Date Required
              </mat-error>
              <mat-error
                *ngIf="isControlHasError('expiryDate', 'pattern') || isControlHasError('expiryDate', 'invalidYear')">
                Must be valid expiry date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-field">
            <mat-label>CVC</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="cvc" id="cvc" autocomplete="cc-csc">
              <mat-error *ngIf="isControlHasError('cvc', 'required')">
                CVC required
              </mat-error>
              <mat-error *ngIf="isControlHasError('cvc', 'minlength') || isControlHasError('cvc', 'maxlength')">
                Invalid CVC
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-field form-field">
            <mat-label>Address</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="streetName" id="streetName" autocomplete="address-line1">
              <mat-error *ngIf="isControlHasError('streetName', 'required')">
                <span class="error-label table-error">Cannot be empty</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <mat-label>Country</mat-label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="countryControl" (selectionChange)="updateAddressOptions($event)">
              <mat-select-trigger>
                <img src="{{cardFormGroup.value.countryControl.img}}">
                {{ cardFormGroup.value.countryControl.viewValue }}
              </mat-select-trigger>
              <mat-option *ngFor="let country of countries" [value]="country">
                <img src="{{country.img}}">
                {{ country.viewValue }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="isControlHasError('countryControl', 'required')">
              <span class="error-label table-error">Cannot be empty</span>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row" *ngIf="isCanadaOrUS">
          <div class="form-field">
            <mat-label>Province/State</mat-label>
            <mat-form-field appearance="outline">
              <mat-select formControlName="provinceState">
                <mat-option *ngFor="let province of provinces" [value]="province">{{ province }}</mat-option>
              </mat-select>
              <mat-error *ngIf="isControlHasError('provinceState', 'required')">
                <span class="error-label table-error">Cannot be empty</span>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-field">
            <mat-label>Postal/Zip</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="postalCode" id="postalCode" autocomplete="postal-code">
              <mat-error *ngIf="isControlHasError('postalCode', 'pattern')">
                <span class="error-label table-error">Postal code/Zip code not valid</span>
              </mat-error>
              <mat-error *ngIf="isControlHasError('postalCode', 'required')">
                <span class="error-label table-error">Cannot be empty</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="btn-wrapper">
          <button (click)="nextPage()" class="next-btn">
            <span>Next</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <app-footer-component></app-footer-component>
</div>
