import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { BuyerInfo, CreateUserResponse, DeepLinkResponse, GiftInfo, MailchimpTemplate, PaymentMethodResponse, WebOrderTemplate } from '@app/shared/models/temp-data-source';
import * as fromApp from '@store/index'
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'
import { Store } from '@ngrx/store';
import { getBuyerInfoSelector, getCardQuantitySelector, getDeepLinkResponseSelector, getGiftInfoSelector, getIsLoadingSelector, getPaymentResponseSelector, getUserResponseSelector } from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { AngularDeviceInformationService } from 'angular-device-information';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DealData } from '@app/shared/models/temp-data-source';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessageDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';
import { environment } from '@environment';
import { CardChangeDialogComponent } from '@app/shared/partials/layouts/dialogs/card-change-dialog/card-change-dialog.component';
import { NetworkInfoService } from '@app/core/services/network-info.service';
import { MailchimpService } from '@app/core/services/mailchimp.service';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';

@Component({
  selector: 'app-order-confirmation-tab',
  templateUrl: './order-confirmation-tab.component.html',
  styleUrls: ['./order-confirmation-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderConfirmationTabComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isDesktop: boolean = this.deviceInformationService.isDesktop();
  @Input() stepper: MatStepper;

  notifier = new Subject<void>();

  infinity: number = Infinity;

  mailchimpChecked: boolean = true;

  dealData: DealData;

  isLoading: boolean;
  isLoadingSub: Subscription;
  quantity: number;
  quantity$: Observable<number>;
  buyerInfo: BuyerInfo;
  buyerInfo$: Observable<BuyerInfo>;
  giftInfo: GiftInfo;
  giftInfo$: Observable<GiftInfo>;
  userResponse: CreateUserResponse;
  userResponse$: Observable<CreateUserResponse>;
  paymentResponse: PaymentMethodResponse;
  paymentResponse$: Observable<PaymentMethodResponse>;
  deepLinkResponse: DeepLinkResponse;
  deepLinkResponse$: Observable<DeepLinkResponse>;

  ipAddress$: Observable<any>;
  ipAddress: string;

  cardChangeRef: MatDialogRef<CardChangeDialogComponent>;

  numSteps: number = 3;
  selectedStep: number = 3;
  isGift: boolean;
  isNext: boolean;

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private deviceInformationService: AngularDeviceInformationService,
    private mailchimpService: MailchimpService,
    private sessionStorageService: SessionStorageService,
    private networkInfoService: NetworkInfoService,
    private infoContentDialog: MatDialog,
    private cardChangeDialog: MatDialog,
    private gas: GoogleAnalyticsService
  ) {
    this.isNext = false;
  }

  ngOnInit(): void {
    if (this.sessionStorageService.getIsGift()) {
      this.numSteps = 4;
      this.selectedStep = 4;
    }

    this.dealData = this.sessionStorageService.getSelectedDealData();
    this.dealData.dealImg = environment.moolaImagesServer + this.dealData.dealImg;
    this.isGift = this.sessionStorageService.getIsGift();

    this.isLoadingSub = this.store.select(getIsLoadingSelector).subscribe(
      (isLoading) => this.isLoading = isLoading
    )

    this.buyerInfo$ = this.store.select(getBuyerInfoSelector);
    this.buyerInfo$.pipe(
      tap(buyerInfo => {
        if (!buyerInfo || buyerInfo == null) this.router.navigateByUrl(AppRoutes.BASKET);
        this.buyerInfo = buyerInfo
      }), takeUntil(this.notifier)
    ).subscribe()

    this.giftInfo$ = this.store.select(getGiftInfoSelector);
    this.giftInfo$.pipe(
      tap(giftInfo => {
        this.giftInfo = giftInfo
      }), takeUntil(this.notifier)
    ).subscribe()

    this.quantity$ = this.store.select(getCardQuantitySelector);
    this.quantity$.pipe(
      tap(quantity => {
        if (!quantity || quantity == null) this.router.navigateByUrl(AppRoutes.BASKET);
        this.quantity = quantity
      }), takeUntil(this.notifier)
    ).subscribe()

    this.userResponse$ = this.store.select(getUserResponseSelector);
    this.userResponse$.pipe(
      tap(userResponse => {
        this.userResponse = userResponse
      }), takeUntil(this.notifier)
    ).subscribe()

    this.paymentResponse$ = this.store.select(getPaymentResponseSelector);
    this.paymentResponse$.pipe(
      tap(paymentResponse => {
        this.paymentResponse = paymentResponse
      }), takeUntil(this.notifier)
    ).subscribe()

    this.deepLinkResponse$ = this.store.select(getDeepLinkResponseSelector);
    this.deepLinkResponse$.pipe(
      tap(deepLinkResponse => {
        this.deepLinkResponse = deepLinkResponse
      }), takeUntil(this.notifier)
    ).subscribe()

    this.ipAddress$ = this.networkInfoService.getIpClient();
    this.ipAddress$.pipe(
      tap(ipAddress => {
        this.ipAddress = ipAddress.ip
      }), takeUntil(this.notifier)
    ).subscribe()
  }

  ngAfterViewInit(): void {
    var gao : GoogleAnalyticsObject = {
      userId: this.userResponse.id, paymentMethodId: this.paymentResponse.creditCardType == 'VISA' ? 1 :
      this.paymentResponse.creditCardType == 'MC' ? 2 : 3};
    this.gas.eventEmitter(this.isGift ? globals.GC_EVENT_WEB_GIFT_ADD_PAYMENT_INFO : globals.GC_EVENT_WEB_ADD_PAYMENT_INFO, gao);
  }

  ngOnDestroy(): void {
    if (this.isLoadingSub) {
      this.isLoadingSub.unsubscribe();
    }

    if (!this.isNext) {
      this.gas.eventEmitter(this.isGift ? globals.GC_EVENT_WEB_GIFT_LEAVE_CHECK_OUT:globals.GC_EVENT_WEB_LEAVE_CHECK_OUT)
    }
    this.notifier.next()
    this.notifier.complete();
  }

  goToIndex(index: number) {
    switch (index) {
      case 1:
        this.router.navigateByUrl(AppRoutes.CUSTOMER);
        break;
      case 2:
        this.router.navigateByUrl(AppRoutes.PAYMENT);
        break;
      case 3:
        this.router.navigateByUrl(AppRoutes.GIFT);
        break;
    }
  }

  nextPage() {
    var gao: GoogleAnalyticsObject = {userId: this.userResponse.id, dealData: this.dealData };
    this.gas.eventEmitter(this.isGift ? globals.GC_EVENT_WEB_GIFT_BEGIN_CHECK_OUT:globals.GC_EVENT_WEB_BEGIN_CHECK_OUT, gao);

    const deal = this.sessionStorageService.getSelectedDealData();
    const userRef: any = this.sessionStorageService.getUserReferral();

    let uniqueId = this.ipAddress + ' '
    + this.deviceInformationService.getDeviceType() + ' '
    + this.deviceInformationService.getDeviceInfo().os + ' '
    + this.deviceInformationService.getDeviceInfo().osVersion.toString() + ' '
    + this.deviceInformationService.getDeviceInfo().browser + ' '
    + this.deviceInformationService.getDeviceInfo().browserMajorVersion;


    uniqueId = uniqueId.substring(0,254);

    //TODO: FIX PLACEHOLDER INFO
    let webOrderTemplate: WebOrderTemplate = {
      buyer: {
        firstName: this.buyerInfo.firstName,
        lastName: this.buyerInfo.lastName,
        email: this.buyerInfo.email,
        phoneNumber: this.buyerInfo.phoneNumber,
        phoneCountryCode: "1"
      },

      paymentMethodToken: this.paymentResponse.paymentReference,
      // paymentMethodType: this.paymentResponse.creditCardType,
      paymentMethodType: "CreditCard",
      deviceInfo: {
        platform: "desktop",
        uniqueId: uniqueId,
        osVersion: this.deviceInformationService.getDeviceInfo().osVersion.toString(),
        appVersion: this.deviceInformationService.getDeviceInfo().browserVersion,
        deviceName: this.deviceInformationService.getDeviceType(),
        deviceModel: this.deviceInformationService.getDeviceInfo().os,
        localTime: 100,
        timeZone: "PST",
        locationInfo: {
          latitude: 0,
          longitude: 0,
          city: "N/A",
          region: "N/A",
          postalCode: "N/A"
        }
      },
      items: [{
        dealId: deal.id,
        valueInCents: deal.faceVal,
        quantity: this.quantity,
        recipient: {
          name: this.isGift ? this.giftInfo.recipientName : this.buyerInfo.firstName + " " + this.buyerInfo.lastName,
          email: this.isGift ? this.giftInfo.recipientEmail : this.buyerInfo.email,
        },
      }],
      kountSessionId: "",
      riskifiedCartToken: this.sessionStorageService.getUUID(),
      riskifiedDeviceId: "",
      userReferralReference: {
        ...userRef,
        action: "Order",
      },
      ipAddress: this.ipAddress,
      userAgent: navigator.userAgent,
    }

    if (this.isGift) {
      webOrderTemplate = {...webOrderTemplate, isGift: this.isGift, giftInfo: {
        buyerNickname: this.giftInfo.from ? this.giftInfo.from : '',
        message: this.giftInfo.message ? this.giftInfo.message : '',
        occasion: this.giftInfo.occasion,
        shareType: this.giftInfo.deliveryMethod
      }}

      if (this.giftInfo.deliveryMethod === "Link") {
        this.gas.eventEmitter(globals.GC_EVENT_WEB_ORDER_GIFT_VIA_LINK, gao)
      } else if (this.giftInfo.deliveryMethod === "Email") {
        this.gas.eventEmitter(globals.GC_EVENT_WEB_ORDER_GIFT_VIA_EMAIL, gao)
      }
    }

    if(this.mailchimpChecked) {
      const mailchimpTemplate: MailchimpTemplate = {
        FNAME: this.buyerInfo.firstName,
        LNAME: this.buyerInfo.lastName,
        PHONE: this.buyerInfo.phoneNumber,
        MMERGE9: this.calculateTotalPrice(),
      }

      this.mailchimpService.addEmailToList(
        this.buyerInfo.email,
        mailchimpTemplate,
        'Web Purchase'
      ).subscribe()

    }

    if (this.deepLinkResponse || this.deepLinkResponse != null) {
      webOrderTemplate = {
        ...webOrderTemplate,
        userReferralReference: {
          action: "Order",
          referenceId: this.deepLinkResponse?.referenceId.toString(), //merchant id
          referenceSource: this.deepLinkResponse?.referenceSource, //Website/QRCode
          referenceType: this.deepLinkResponse?.referenceType //merchant
        }
      }
      this.store.dispatch(
        PurchaseOrderActions.followRefCode(
          this.deepLinkResponse.referralCode,
          webOrderTemplate,
          this.userResponse
        )
      )
    }
    else
      this.store.dispatch(
        PurchaseOrderActions.createWebOrder(
          webOrderTemplate,
          this.userResponse
        )
      )

    scroll(0, 0)
  }

  calculateUnitTotalFaceValue(): number {
    const deal = this.dealData;

    let result = 0;
    switch (deal.bonusValType) {
      case "FixedCard":
        result = deal.faceVal + deal.bonusVal;
        break;
      case "MoolaCash":
        result = deal.faceVal + (deal.bonusVal / 10000 * deal.faceVal);
        break;
      default:
        result = deal.faceVal
    }
    result /= 100;

    return result;
  }

  calculateUnitTotalFaceValueStr(): string {
    return this.calculateUnitTotalFaceValue().toFixed(2);
  }

  calculateCardValue(): string {
    let result = 0;

    result = this.calculateUnitTotalFaceValue() * this.quantity;

    return result.toFixed(2);
  }

  calculateBonusValue(): number {
    const deal = this.dealData;

    let result = 0;
    switch (deal.bonusValType) {
      case "PercentageOff":
        result = deal.bonusVal / 100 * deal.faceVal;
        break;
      case "MoolaCash":
        result = deal.bonusVal / 10000 * deal.faceVal;
        break;
      default:
        result = deal.bonusVal;
        break;
    }
    result *= this.quantity;

    result /= 100;

    return result;
  }

  calculateBonusValueStr(): string {
    return this.calculateBonusValue().toFixed(2);
  }

  calculateTotalPrice(): string {
    const deal = this.dealData;

    let result = 0;
    switch (deal.bonusValType) {
      case "PercentageOff":
        result = this.calculateUnitTotalFaceValue() * this.quantity - this.calculateBonusValue();
        break;
      default:
        result = deal.faceVal * this.quantity;
        result /= 100;
    }

    return result.toFixed(2);
  }

  openRedemptionInstructionsDialog() {
    this.openInfoContentDialog(
      this.dealData.termsAndRedemption.redemptionInstructions,
      this.dealData.merchant.name + ' Gift Card Redemption Instructions'
    );
  }

  openTermsAndConditionsDialog() {
    this.openInfoContentDialog(
      this.dealData.termsAndRedemption.termsAndConditions,
      this.dealData.merchant.name + ' Gift Card Terms & Conditions'
    );
  }

  openInfoContentDialog(content: string, title: string) {
    this.infoContentDialog.open(MessageDialogComponent, {
      data: {
        message: content,
        title: title,
      },
      width: '580px',
      closeOnNavigation: true,
    });
  }

  openCardChangeDialog() {
    this.cardChangeRef = this.cardChangeDialog.open(CardChangeDialogComponent, {
      closeOnNavigation: true,
    });

    const cardChangeSub = this.cardChangeRef.componentInstance.cardChangeBoolean.subscribe(result => {
      this.cardChangeRef.close();
      cardChangeSub.unsubscribe();
      if (result)
        this.goToIndex(2);
    });
  }

  quantityUpdate(q: number) {
    this.store.dispatch(
      PurchaseOrderActions.setCardQuantity(
        q
      )
    )

    this.quantity = q;
  }

  formatPhoneNumber(phoneNumber) {
    const areaCode = phoneNumber.slice(0, 3);
    const firstPart = phoneNumber.slice(3, 6);
    const secondPart = phoneNumber.slice(6, 10);

    return `(${areaCode}) ${firstPart}-${secondPart}`;
  }
}
