import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogContentData {
    message: '';
  }

@Component({
    selector: 'warning-popup-dialog',
    templateUrl: './warning-popup-dialog.component.html',
    styleUrls: ['./warning-popup-dialog.component.scss'],
    encapsulation: ViewEncapsulation.Emulated
  })
  export class MessageDialogComponent implements OnInit {
    title: string | undefined;
    message: string | undefined;
    title2: string | undefined;
    message2: string | undefined;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,) { }

    ngOnInit(): void {
      this.message = this.data.message;
      this.title = this.data.title;
      this.title2 = this.data.title2;
      this.message2 = this.data.message2;
    }
  }
