<div class="stepper-wrapper">
    <div class="first-num-container">
        <div class="first-num">
            <span class="circle" [class.selected]="selectedStep === 1">1</span>
        </div>
    </div>
    <div class="dot-circle-section" *ngFor="let step of getNumSteps()">
        <div class="dotted-container">
            <span class="dot grow"></span>
            <span class="dot grow"></span>
            <span class="dot grow"></span>
            <span class="dot grow"></span>
        </div>
        <div class="num-container">
            <span class="circle" [class.selected]="selectedStep === step">{{ step }}</span>
        </div>
    </div>    
</div>