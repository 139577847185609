import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { Store } from '@ngrx/store';
import { AngularDeviceInformationService } from 'angular-device-information';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'
import { WebOrderResponse } from '@app/shared/models/temp-data-source';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { getWebOrderResponseSelector } from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { DealData } from '@app/shared/models/temp-data-source';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { environment } from '@environment';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';
import * as globals from '@app/shared/models/globals';


@Component({
  selector: 'app-order-completed-tab',
  templateUrl: './order-completed-tab.component.html',
  styleUrls: ['./order-completed-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderCompletedTabComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isDesktop: boolean = this.deviceInformationService.isDesktop();

  notifier = new Subject<void>();

  dealData: DealData;
  webOrderResponse: WebOrderResponse;
  webOrderResponse$: Observable<WebOrderResponse>;

  constructor(
    private deviceInformationService: AngularDeviceInformationService,
    private store: Store,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private gas: GoogleAnalyticsService
  ) { }

  ngOnInit(): void {
    if(environment.production) {
      this.addGoogleTag();
    }
    this.webOrderResponse$ = this.store.select(getWebOrderResponseSelector);
    this.webOrderResponse$.pipe(
      tap(webOrderResponse => {
        this.webOrderResponse = webOrderResponse
      }) , takeUntil(this.notifier)
    ).subscribe()

    this.dealData = this.sessionStorageService.getSelectedDealData();
    this.dealData.dealImg = environment.moolaImagesServer + this.dealData.dealImg;

    this.sessionStorageService.removeRefToken();
    this.store.dispatch(
      PurchaseOrderActions.clearState()
    )
  }

  ngAfterViewInit(): void {
    var gao: GoogleAnalyticsObject = {dealData: this.dealData, transactionId: this.webOrderResponse.orderNo };
    this.gas.eventEmitter(globals.GC_EVENT_WEB_ORDER_RECEIVED, gao);
  }

  ngOnDestroy(): void {
    this.notifier.next()
    this.notifier.complete();
  }

  addGoogleTag() {
    let node = document.createElement('script');
    node.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-11320006856/lBJXCLrvv-EYEMix5pUq'});";
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  nextPage() {
    this.store.dispatch(
      PurchaseOrderActions.completedWebOrder()
    )

    this.router.navigateByUrl(AppRoutes.MARKETPLACE);
  }

}
