<div>
  <app-header-component></app-header-component>

  <div class="order-completed-mobile-wrapper" *ngIf="!isDesktop">
    <div class="order-completed-container">
      <div class="gift-card" [class.multiple-cards]="dealData.bonusValType == 'FixedCard'">
        <img src={{dealData.dealImg}}>
      </div>
      <div class="center-text">
        <div class="header">
          <span>Thank you for your Purchase.</span>
        </div>
        <div class="sub-header">
          <span>Your order has been received! Your order number is {{webOrderResponse.orderNo}}. 
            Please check your email for an order confirmation message.</span>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="done-btn" (click)="nextPage()">
          <span>Continue Shopping</span>
        </button>
      </div>
    </div>
  </div>

  <div class="order-completed-wrapper" *ngIf="isDesktop">
    <div class="order-completed-container">
      <div class="gift-card" [class.multiple-cards]="dealData.bonusValType == 'FixedCard'">
        <img src={{dealData.dealImg}}>
      </div>
      <div class="center-text">
        <div class="header">
          <span>Thank you for your Purchase.</span>
        </div>
        <div class="sub-header">
          <span>Your order has been received! Your order number is {{webOrderResponse.orderNo}}. 
            Please check your email for an order confirmation message.</span>
        </div>
      </div>
      <div class="btn-wrapper">
        <button class="done-btn" (click)="nextPage()">
          <span>Continue Shopping</span>
        </button>
      </div>
    </div>
  </div>

  <app-footer-component></app-footer-component>
</div>