<div>
  <app-header-component></app-header-component>

  <div class="spinner-container-2" *ngIf="isLoading">
    <mat-spinner mat-primary></mat-spinner>
  </div>

  <div class="gift-info-wrapper"  *ngIf="!isLoading">
    <div class="gift-info-container">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction"></app-card-info-box>
      <div class="stepper">
        <app-stepper [numSteps]="4" [selectedStep]="1"></app-stepper>
      </div>
      <hr>

      <form autocomplete="off" [formGroup]="recipientFormGroup" class="form-wrapper">
        <!-- Occasion carousel -->
        <div class="row">
          <span class="title-label mb-1">Choose an Occasion</span>
          <swiper [slidesPerView]="4" [freeMode]="true" [spaceBetween]="10" [pagination]="true">
            <ng-template swiperSlide *ngFor="let occasion of occasionData">
              <div [ngClass]="isSelected(occasion) ? 'card-box-active':''" class="card-box" *ngIf="occasion.name"
                (click)="occasionOnClick(occasion)">
                <img [src]="imagePath + occasion.imageFileName" />
                <span>{{occasion.displayTitle}}</span>
              </div>
            </ng-template>
          </swiper>
        </div>
        <hr>

        <!-- Recipient Info -->
        <div class="row">
          <div class="form-field">
            <mat-label class="title-label">Recipient Name</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="recipientName" id="recipientName">
              <mat-error *ngIf="isControlHasError('recipientName', 'required')">
                Recipient Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('recipientName', 'maxlength')">
                Recipient Name cannot exceed Max Length.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Delivery Method -->
        <div class="row">
          <div class="form-field">
            <mat-label class="title-label">Choose Your Delivery Method</mat-label>
            <mat-radio-group formControlName="deliveryMethod" (change)="deliveryMethodOnChnage()">
              <div class="delivery-method-item">
                <hr>
                <mat-radio-button value="Link">
                  <img src="../../../assets/media/icons/share_link.png" height="36px" width="36px" />
                  <div class="radio-desc">
                    <span>Send Myself</span>
                    <span>Use any of your favourite messaging apps</span>
                  </div>
                </mat-radio-button>
              </div>
              <div class="delivery-method-item">
                <hr>
                <mat-radio-button value="Email">
                  <img src="../../../assets/media/icons/share_email.png" height="26px" width="32px" />
                  <div class="radio-desc">
                    <span>Send by Email</span>
                    <span>We'll email the gift card for you</span>
                  </div>
                </mat-radio-button>
              </div>
            </mat-radio-group>
            <hr>

            <!-- If share by link -->
            <div class="share-link-desc" *ngIf="recipientFormGroup.controls['deliveryMethod'].value === 'Link'">
              <span>We will send you an email with a link you can share yourself</span>
            </div>

            <!-- Extra gift info if email -->
            <div class="email-info-container" *ngIf="recipientFormGroup.controls['deliveryMethod'].value === 'Email'">
              <div class="row">
                <div class="form-field">
                  <mat-label class="title-label">Recipient Email</mat-label>
                  <mat-form-field appearance="outline">
                    <input type="text" matInput formControlName="recipientEmail" id="recipientEmail">
                    <mat-error *ngIf="isControlHasError('recipientEmail', 'required')">
                      Email is Required
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('recipientEmail', 'pattern')">
                      Email is not valid
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row">
                <div class="form-field">
                  <mat-label class="title-label">Confirm Email</mat-label>
                  <mat-form-field appearance="outline">
                    <input type="text" matInput formControlName="confirmEmail" id="confirmEmail">
                    <mat-error *ngIf="isControlHasError('confirmEmail', 'required')">
                      Email is Required
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('confirmEmail', 'pattern')">
                      Email is not valid
                    </mat-error>
                    <mat-error *ngIf="isControlHasError('confirmEmail', 'notEqual')">
                      Email is not match.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="form-field">
                  <mat-label class="title-label">From</mat-label>
                  <mat-form-field appearance="outline">
                    <input matInput type="text" formControlName="from" id="from">
                    <mat-error *ngIf="isControlHasError('from', 'required')">
                      From is required
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="form-field">
                  <mat-label class="textarea-label">
                    <span class="title-label">Personal Message</span>
                    <span>200 character max</span>
                  </mat-label>
                  <mat-form-field appearance="outline">
                    <textarea matInput type="text" formControlName="message" id="message"></textarea>
                    <mat-error *ngIf="isControlHasError('message', 'maxlength')">
                      200 character max
                    </mat-error>
                </mat-form-field>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Button Group -->
        <div class="btn-wrapper">
          <button mat-flex-button (click)="nextPage()" class="next-btn"
            color="primary">
            <span>Next</span>
          </button>
        </div>

        <div class="moola-cash-desc">
          <span>*Download the Moola App to Redeem your Moola Cash</span>
        </div>

      </form>

    </div>

  </div>

  <app-footer-component *ngIf="!isLoading"></app-footer-component>

</div>
