<div id="merchant-row">


  <div class="card" *ngFor="let dealMerchant of contentList; let i = index">
    <div [class]="isHeroImg(dealMerchant) ? 'card-img-hero' : 'card-img-logo'" (click)="cardClickHandle(dealMerchant)">
      <img *ngIf="isHeroImg(dealMerchant)" class="card-logo-corner" src="{{getLogoImage(dealMerchant)}}" alt="Logo">
      <img class="card-img-top" src="{{getMerchantImage(dealMerchant)}}" alt="Card image cap">
      <!-- <img class="card-img-top" src="../../../../../assets/media/images/TestHeroImage.png" alt="Card image cap"> -->

    </div>
    <div class="card-body" (click)="cardClickHandle(dealMerchant)">
      <h5 class="card-title"
        *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length >= 15">
        {{dealMerchant.merchant.name| slice:0:15 }}...</h5>
      <h5 class="card-title"
        *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length < 15">
        {{dealMerchant.merchant.name}}</h5>
      <span class="card-text" *ngIf="dealMerchant.bonusValType != 'NoBonus'">Buy Now And Get</span>
      <div class="card-text">
        <div class="card-title-second"
          *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType!='MoolaCash' && dealMerchant.discountPercent > 0">
          {{dealMerchant.discountPercent}}% OFF</div>
        <div class="card-title-second"
          *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='MoolaCash' && dealMerchant.discountPercent > 0">
          {{dealMerchant.discountPercent / 100}}% Moola Cash</div>
        <div class="card-title-second"
          *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='PercentageOff' && dealMerchant.discountPercent > 0">
          {{dealMerchant.discountPercent}}% OFF</div>
        <div class="card-title-second"
          *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='FixedCard') && dealMerchant.discountPercent > 0">
          {{dealMerchant.discountPercent}}% More</div>
          <div class="card-title-second"
          *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='MoolaCash') && dealMerchant.discountPercent > 0">
          {{dealMerchant.discountPercent / 100}}% Moola Cash</div>
        <div *ngIf="dealMerchant && dealMerchant.merchant.name && !dealMerchant.discountPercent"
          class="invisible">_</div>
      </div>
    </div>
  </div>




<!--
    <mat-grid-list cols="{{gridColsQtd}}" rowHeight="{{rowHeight}}" [gutterSize]="'5px'">
        <ng-container *ngFor="let item of contentList; let i=index">
            <mat-grid-tile>
                <div class="item card text-center deal-item" (click)="cardClickHandle(item)">
                    <img src="{{getMerchantImage(item)}}" class="deal-item-content" alt="{{item.merchant.name}}">
                    <div class="card-body pt-1">
                        <h5 class="card-title m-0" *ngIf="item.merchant && item.merchant.name.length >= nameTruncateChars">{{item.merchant.name| slice:0:nameTruncateChars }}...</h5>
                        <h5 class="card-title m-0" *ngIf="item.merchant && item.merchant.name.length < nameTruncateChars">{{item.merchant.name}}</h5>
                        <div *ngIf="!showDealsInPercentage && item.discountPercent && item.discountPercent > 0 && item.bonusValType!='MoolaCash'"><b>{{item.discountPercent}}% OFF</b></div>
                        <div *ngIf="!showDealsInPercentage && item.discountPercent && item.discountPercent > 0 && item.bonusValType=='MoolaCash'"><b>+ {{item.discountPercent}}% Moola Cash</b></div>
                        <div *ngIf="showDealsInPercentage && item.discountPercent && item.discountPercent > 0 && item.bonusValType=='PercentageOff'"><b>{{item.discountPercent}}% OFF</b></div>
                        <div *ngIf="showDealsInPercentage && item.discountPercent && item.discountPercent > 0 && (item.bonusValType=='MoolaCash' || item.bonusValType=='FixedCard')"><b>{{item.discountPercent}}% More</b></div>
                        <div *ngIf="!item.discountPercent || item.discountPercent <= 0" class="invisible"><b>_</b></div>
                    </div>
                </div>
            </mat-grid-tile>
        </ng-container>
    </mat-grid-list> -->
</div>


<!-- <div class="card"
            *ngIf="dealMerchant && dealMerchant != null && dealMerchant.merchant.name && dealMerchant.merchant.name !== 'SeeAll'"
            [ngClass]="getMerchantExtraInfo(dealMerchant, category)"
            (click)="cardClickHandle(dealMerchant, category.enumValue)">
            <div class="card-img">
              <img class="card-img-top" src="{{getMerchantImage(dealMerchant)}}" alt="Card image cap">
            </div>
            <div class="card-body">
              <h5 class="card-title"
                *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length >= 15">
                {{dealMerchant.merchant.name| slice:0:15 }}...</h5>
              <h5 class="card-title"
                *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length < 15">
                {{dealMerchant.merchant.name}}</h5>
              <span class="card-text" *ngIf="dealMerchant.bonusValType != 'NoBonus'">Buy Now And Get</span>
              <div class="card-text">
                <div class="card-title text-black"
                  *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType!='MoolaCash' && dealMerchant.discountPercent > 0">
                  {{dealMerchant.discountPercent}}% OFF</div>
                <div class="card-title text-black"
                  *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='MoolaCash' && dealMerchant.discountPercent > 0">
                  {{dealMerchant.discountPercent}}% Moola Cash</div>
                <div class="card-title text-black"
                  *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='PercentageOff' && dealMerchant.discountPercent > 0">
                  {{dealMerchant.discountPercent}}% OFF</div>
                <div class="card-title text-black"
                  *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='MoolaCash' || dealMerchant.bonusValType=='FixedCard') && dealMerchant.discountPercent > 0">
                  {{dealMerchant.discountPercent}}% More</div>
                <div *ngIf="dealMerchant && dealMerchant.merchant.name && !dealMerchant.discountPercent"
                  class="invisible">_</div>
              </div>
            </div>
          </div> -->
