import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '@app/core/services/app.services';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
// import { WarningPopupDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';
import { environment } from '@environment';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { MessageDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';
import { Meta, Title } from '@angular/platform-browser';
import { DealsHelper } from '@app/shared/utiles/helpers/deals.helper';
import { MerchantQrCodeDialogComponent } from '@app/shared/partials/layouts/dialogs/merchant-qr-code-dialog/merchant-qr-code-dialog.component';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { NgDialogAnimationService } from '@app/core/services/dialog-animation.service';
import { BrowserSelectDialogComponent } from '@app/shared/partials/layouts/dialogs/browser-select-dialog/browser-select-dialog.component';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as globals from '@app/shared/models/globals';
import { ReferralInfo } from '@app/shared/models/deal-info.model';
import { CreateDynamicLinkService } from '@app/core/services/create-dynamic-link.service';
import {
  DealData,
  DynamicLinkResponse,
} from '@app/shared/models/temp-data-source';
import { RangedDealDialogComponent } from '@app/shared/partials/layouts/dialogs/ranged-deal-dialog/ranged-deal-dialog.component';
import { HttpParams } from '@angular/common/http';
import QRCode from 'easyqrcodejs';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions';
import * as fromApp from '@store/index';
import { Store } from '@ngrx/store';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';

SwiperCore.use([Navigation, Autoplay]);

declare const gtag: Function;

@Component({
  selector: 'app-merchant-details',
  templateUrl: './merchant-details.component.html',
  styleUrls: ['./merchant-details.component.scss', './swiper.min.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantDetailsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('collapseContainer') collapseContainer?: ElementRef;
  @ViewChild('collapseButton') collapseButton?: ElementRef;
  @ViewChild('qrcode', { static: false }) qrcode: ElementRef;

  @HostListener('document:click', ['$event.target'])
  onClickOutside(targetElement: any) {
    const isClickedInside =
      this.collapseContainer?.nativeElement.contains(targetElement) ||
      this.collapseButton?.nativeElement.contains(targetElement);

    if (!isClickedInside) {
      this.isCollapseMoolaDesc = true;
    }
  }

  //isLoading!: Boolean | true;
  gridColsQtd: number = 4;
  merchantsGridColsQtd: number = this.gridColsQtd;
  relatedmerchantsGridColsQtd: number = this.gridColsQtd;
  rowHeight?: string;
  nameTruncateChars = 25;
  responsiveOptions!: any[];
  state$: Observable<object> | undefined;
  // showFullButton!: Boolean | true;
  // mobilemode!: Boolean | true;
  require: any;
  referralInfo: ReferralInfo;
  isCollapseMoolaDesc: boolean = true;
  showMoolaDesc: boolean;
  isDesktop: boolean;
  merchantDisplayName: string;

  link$: Observable<DynamicLinkResponse>;

  swiperDealConfig = {
    navigation: true,
    breakpoints: {
      350: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 25,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 32,
      },
    },
  };

  private unsubscribe: Subject<any>;

  // merchantData!: {};
  merchantId!: number;
  merchantName!: string;
  name!: string;
  cateogryEnum!: any;
  dealsInfo$: any;
  dealsInfo: any;
  systemDealsInfo$: any;
  systemDealsInfo: any;
  merchant: any;
  logoPath: string;
  termsAndConditions: string;
  redemptionInstruction: string;
  merchantDescription: string;
  briefDescription: string;
  balanceCheckUrl: string;
  companyWebsite: string;
  shareUrl: string;
  emailShareUrl: string;
  deals: any[] = [];
  dealsWithBonus: any[] = [];
  routeParams: any;
  breadcrumbConfig: any;
  relatedMerchants$: any;
  relatedmerchants: any;
  relatedmerchantsLength!: number | 0;
  populars: any[] = [];
  imagesBase = environment.moolaImagesServer;
  merchantData: any;
  merchantStructuredData!: {};
  dynamicLinkUrl: string;
  dynamicLink$: Observable<DynamicLinkResponse>;
  showDealsInPercentage = true;

  dealBannerRef: MatDialogRef<RangedDealDialogComponent>;

  influencerToken: string;

  qrcodeSize: number;

  qrBgImgUrl: string = './assets/media/images/qr-background.png';

  bannerColour: string;
  titleBannerUrl: string;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private infoConentDialog: MatDialog,
    private dealBannerDialog: MatDialog,
    private openInDialog: NgDialogAnimationService,
    private meta: Meta,
    private title: Title,
    public dialog: NgDialogAnimationService,
    private sessionStorageService: SessionStorageService,
    private deviceService: DeviceDetectorService,
    private createDynamicLinkService: CreateDynamicLinkService,
    private deviceDetectorService: DeviceDetectorService,
    private store: Store<fromApp.AppState>,
    private gas: GoogleAnalyticsService
  ) {
    this.unsubscribe = new Subject();
    this.referralInfo = this.sessionStorageService.getReferralInfo();

    this.termsAndConditions = '';
    this.redemptionInstruction = '';
    this.merchantDescription = '';
    this.briefDescription = '';
    this.balanceCheckUrl = '';
    this.shareUrl = '';
    this.emailShareUrl = '';
    this.logoPath = '';
    this.dynamicLinkUrl = '';

    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 6,
        numScroll: 1,
      },
      {
        breakpoint: '768px',
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: '560px',
        // numVisible: 3,
        numVisible: 4,
        numScroll: 1,
      },
    ];
  }

  ngAfterViewInit(): void {
    if (this.dynamicLink$ != null) {
      this.dynamicLink$
        .pipe(
          tap((data) => {
            // this.createQrCode(data.shortLink);
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();
    } else {
      setTimeout(() => {
        this.createQrCode(this.dynamicLinkUrl);
      }, 1000);
    }

    this.setQrCodeSize();
  }

  ngOnDestroy(): void {
    // this.sessionStorageService.removeMerchantIdFromUserRef();
  }

  ngOnInit(): void {
    this.store.dispatch(PurchaseOrderActions.getPortalStatus());
    // setTimeout(() => {
    //   if (!this.sessionStorageService.getOrderEnabled()) {
    //     this.openOrderDisabledPopUp();
    //   }
    // }, 500)
    this.rowHeight = this.rowHeight || '2:1'; //'220px';
    this.activatedRoute.data.subscribe((data) => {
      this.breadcrumbConfig = data['breadcrumb'];
    });
    // this.checkGridColumns();
    if (this.activatedRoute.snapshot.queryParamMap.has('ref')) {
      this.store.dispatch(
        PurchaseOrderActions.fetchDeepLinkParams(
          this.activatedRoute.snapshot.queryParamMap.get('ref'),
          null
        )
      );
    }
    this.dynamicLinkUrl = this.createDynamicLinkService.createLinkFromRef(
      this.activatedRoute.snapshot.queryParamMap
    );
    this.createDynamicLink();
    this.routeParams = this.activatedRoute.snapshot.queryParams;
    this.SetRefObject(this.activatedRoute.snapshot.queryParams);
    this.activatedRoute.params.subscribe((routeParams) => {
      this.merchantName = routeParams['merchantName'];
      this.name = routeParams['merchantName']
        .replace('-', '')
        .match(/[A-Z][^A-Z]*/g);

      if (window.history != null && window.history.state != null) {
        this.cateogryEnum = window.history.state.hasOwnProperty('category')
          ? window.history.state.category
          : this.merchantData != null &&
            this.merchantData.hasOwnProperty('category')
          ? this.merchantData.category
          : '';
        this.merchantId = window.history.state.hasOwnProperty('merchantId')
          ? window.history.state.merchantId
          : this.merchantData != null &&
            this.merchantData.hasOwnProperty('merchantId')
          ? this.merchantData.merchantId
          : '';
        this.logoPath = window.history.state.hasOwnProperty('logoPath')
          ? window.history.state.logoPath
          : this.merchantData != null &&
            this.merchantData.hasOwnProperty('logoPath')
          ? this.merchantData.logoPath
          : '';
      }
      this.resetSEO();
      this.getMerchantInfo(this.merchantId, this.merchantName);

      this.gas.eventEmitter(globals.GC_EVENT_WEB_VIEW_MERCHANT_DETAILS, {
        merchantId: this.merchantId,
      });

      let categoryFromQuery =
        this.activatedRoute.snapshot.queryParams['category'];
    });

    setTimeout(() => {
      this.showPreferenceDialog();
    }, 1000);

    this.isDesktop = this.deviceDetectorService.isDesktop();
  }

  createQrCode(link: string) {
    var options = {
      text: link,
      height: 110,
      width: 110,
      drawer: 'png',
      correctLevel: this.dynamicLinkUrl.includes('?link=')
        ? QRCode.CorrectLevel.L
        : QRCode.CorrectLevel.M,
    };
    return new QRCode(this.qrcode?.nativeElement, options);
  }

  setQrCodeSize() {
    let width = window.innerWidth;
    this.qrcodeSize = 140;
    if (width > 992) {
      this.qrcodeSize = 100;
    } else if (width > 767) {
      this.qrcodeSize = 80;
    }
  }

  setTitle() {
    this.title.setTitle(this.merchant.name + ' Gift Cards - Moola');
  }

  setSEO(bestDealValue: string) {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Web Marketplace - ' +
          this.merchant.name +
          ' deals, sales, discounts, ' +
          this.merchant.name,
      },
      {
        name: 'keywords',
        content:
          'Moola, Marketplace, Giftcards, Gifts, Shopping, Deals, Discounts, ' +
          this.merchant.name,
      },
      ,
      { name: 'robots', content: 'index, follow' },
      {
        property: 'og:title',
        content:
          this.merchant.name +
          ' E-Gift Cards: Get the Best Deals and Bonuses Online - Moola - Gift Cards that Give Back',
      },
      {
        property: 'og:description',
        content:
          'Save big on ' +
          this.merchant.name +
          ' e-gift cards with Moola. Get the best deals and bonuses on gift cards for your favorite brands and local merchants. Buy online today!',
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.moola.com' },
      {
        property: 'og:image',
        content:
          'https://content.moola.com/wp-content/uploads/2023/04/og-image.png',
      },
      {
        property: 'og:title',
        content:
          this.merchant.name +
          ' E-Gift Cards: Get the Best Deals and Bonuses Online - Moola - Gift Cards that Give Back',
      },
      {
        property: 'og:description',
        content:
          'Save big on ' +
          this.merchant.name +
          ' e-gift cards with Moola. Get the best deals and bonuses on gift cards for your favorite brands and local merchants. Buy online today!',
      },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://www.moola.com' },
      {
        property: 'og:image',
        content:
          'https://content.moola.com/wp-content/uploads/2023/04/og-image.png',
      },
    ]);

    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    this.merchantStructuredData = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: this.merchant.name,
      image: [this.logoPath],
      description: this.merchantDescription,
      brand: {
        '@type': 'Brand',
        name: this.merchant.name,
      },
      offers: {
        '@type': 'Offer',
        url: this.shareUrl,
        priceCurrency: 'CAD',
        price: bestDealValue,
        itemCondition: 'https://schema.org/New',
        availability: 'https://schema.org/InStock',
      },
    };
    script.textContent = JSON.stringify(this.merchantStructuredData);
    document.head.appendChild(script);
  }

  resetSEO() {
    if (
      document.head.getElementsByTagName('script') != null &&
      document.head.getElementsByTagName('script').length > 0
    ) {
      document.head.removeChild(
        document.head.getElementsByTagName('script')[0]
      );
    }
    (
      document.querySelector("[name='description']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[name='keywords']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:title']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:description']") || {
        remove: () => 0,
      }
    ).remove();
    (
      document.querySelector("[property='og:type']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:url']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:image']") || { remove: () => 0 }
    ).remove();
  }

  async getMerchantInfo(merchantId: number, merchantSimpleName: string) {
    this.merchant = null;
    this.termsAndConditions = '';
    this.redemptionInstruction = '';
    this.merchantDescription = '';
    this.briefDescription = '';
    this.balanceCheckUrl = '';
    this.deals = [];
    this.dealsWithBonus = [];
    this.shareUrl = '';

    this.dealsInfo$ =
      merchantId != null && Number(merchantId) > 0
        ? this.appService.getMerchant(merchantId)
        : this.appService.getMerchantBySimpleName(merchantSimpleName);
    this.dealsInfo$
      .pipe(
        tap(
          (dealsInfo) => {
            if (!dealsInfo) {
              this.router.navigate([AppRoutes.MARKETPLACE]);
            } else {
              this.dealsInfo = dealsInfo;
              this.arrangeDeals(this.dealsInfo.deals);
              this.merchant =
                this.deals != null &&
                this.deals.length > 0 &&
                this.deals[0].merchant != null
                  ? this.deals[0].merchant
                  : this.dealsInfo.deals != null &&
                    this.dealsInfo.deals.length > 0
                  ? this.dealsInfo.deals[0].merchant
                  : {};
              this.termsAndConditions =
                this.dealsInfo.termsAndConditions != null
                  ? this.dealsInfo.termsAndConditions
                  : '';
              this.redemptionInstruction =
                this.dealsInfo.redemptionInstruction != null
                  ? this.dealsInfo.redemptionInstruction
                  : '';
              this.merchantDescription =
                this.dealsInfo.merchantDescription != null
                  ? this.dealsInfo.merchantDescription.replace(/<[^>]*>/g, '')
                  : '';
              this.briefDescription =
                this.dealsInfo.briefDescription != null
                  ? this.dealsInfo.briefDescription
                  : '';
              this.balanceCheckUrl =
                this.dealsInfo.balanceCheckUrl != null
                  ? this.dealsInfo.balanceCheckUrl
                  : '';
              this.SetUserReferral(this.routeParams, this.dealsInfo.deals[0]);
              this.shareUrl =
                this.dealsInfo.shareUrl != null
                  ? `https://www.moola.com/${this.merchant.webUrlName}`
                  : '';
              const userRef = this.sessionStorageService.getUserReferral();
              if (userRef != null) {
                this.shareUrl += '?';
                let temp = '';
                Object.keys(userRef).forEach(function (key) {
                  temp += key + '=' + userRef[key] + '&';
                });
                this.shareUrl += temp;
                this.shareUrl = this.shareUrl.substring(
                  0,
                  this.shareUrl.length - 1
                );
              }
              if (this.dynamicLinkUrl.length == 0) {
                this.dynamicLinkUrl =
                  this.dealsInfo.dynamicLinkUrl != null
                    ? this.dealsInfo.dynamicLinkUrl
                    : '';
              }
              this.companyWebsite =
                this.dealsInfo.companyWebsite != null
                  ? this.dealsInfo.companyWebsite
                  : '';
              this.setTitle();
              this.logoPath =
                this.logoPath == null || this.logoPath.length == 0
                  ? this.imagesBase + this.merchant.logoFilename
                  : this.logoPath;
              this.cateogryEnum =
                this.cateogryEnum == '' &&
                this.dealsInfo.bisCategories != null &&
                this.dealsInfo.bisCategories.length > 0
                  ? this.dealsInfo.bisCategories[0].enumValue
                  : this.cateogryEnum;

              const bestDeal =
                this.dealsInfo != null &&
                this.dealsInfo.deals != null &&
                this.dealsInfo.deals.length > 0
                  ? this.dealsInfo.deals[0]
                  : null;
              const bestValue =
                bestDeal != null ? this.getDealTextOld(bestDeal) : '0';
              this.setSEO(bestValue);
              // this.checkGridColumns();
              this.getRelated();
              this.getSystemDealsInfo();
              this.SetMoolaCashDesc(this.dealsInfo.deals);
              this.merchant.defaultColor
                ? (this.bannerColour = this.merchant.defaultColor)
                : (this.bannerColour = '#494C56');
              if (this.merchant.heroFilename) {
                this.titleBannerUrl =
                  environment.moolaImagesServer + this.merchant.heroFilename;
              } else {
                if (this.isDesktop) {
                  this.titleBannerUrl =
                    environment.moolaImagesServer + 'hero_default.png';
                } else {
                  this.titleBannerUrl =
                    environment.moolaImagesServer + 'hero_default_mobi.png';
                }
              }
              this.merchantDisplayName = this.dealsInfo.deals[0].merchant.name;
              this.merchant.name = this.merchantDisplayName;
              
            }
          },
          (err) => {
            this.router.navigate([AppRoutes.MARKETPLACE]);
          }
        ),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
      
  }

  getNonRangeDeals(): any {
    let result = this.deals
      .filter((temp) => temp.faceValType != 'Range')
      .slice(0, 4);
    result = result.sort((a, b) => a.faceVal - b.faceVal);
    return result;
  }

  getRangeDeals(): any {
    return this.deals.filter((temp) => temp.faceValType == 'Range');
  }

  getSystemDealsInfo() {
    this.systemDealsInfo$ = this.appService.getMarketplaceDeals();
    this.systemDealsInfo$
      .pipe(
        tap((sysDealsInfo: { popularDeals: any[]; deals: any[] }) => {
          if (!sysDealsInfo) return;

          this.systemDealsInfo = sysDealsInfo.deals;
          if (
            sysDealsInfo.popularDeals != null &&
            sysDealsInfo.popularDeals.length > 0
          ) {
            this.getPopularDeals(sysDealsInfo.popularDeals);
          }
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();
  }

  getMerchantName() {
    return this.merchant != null
      ? this.merchant.name
      : this.merchantName.replace('-', ' ');
  }

  getRemainingDealsOrOutOfStockText(deal: any) {
    if (deal.dealStatus == 'OutOfStock') {
      return 'Out of Stock';
    } else if (deal.quantity > 0) {
      return `${deal.quantity} Remaining`;
    }

    return '';
  }

  /**
   * Gets the deal by ID
   * @param dealId
   * @returns The deal according to its ID to build the card
   */
  getDeal(dealId: any) {
    if (this.systemDealsInfo) {
      let abc = this.systemDealsInfo.find(
        (deal: { merchant: any }) => deal.merchant.id === dealId
      );
      return abc;
    }
  }

  arrangeDeals(deals: any[]) {
    deals.forEach((deal) => {
      if (deal.bonusValType === 'NoBonus') {
        this.deals.push(deal);
      } else {
        this.dealsWithBonus.push(deal);
      }
    });
  }

  getRelated() {
    let category =
      typeof this.cateogryEnum == 'object'
        ? this.cateogryEnum.enumValue
        : this.cateogryEnum;
    this.relatedMerchants$ = this.appService.getMerchantsByKeyword(
      category,
      'PopularDeals'
    );

    this.relatedMerchants$
      .pipe(
        tap((data: { deals: DealData[] }) => {
          if (!data) return;
          data.deals.splice(
            data.deals.findIndex(
              (deal) => deal.merchant.id === this.merchantId
            ),
            1
          );
          this.relatedmerchants = data.deals.slice(0, 10);
          this.relatedmerchantsLength = this.relatedmerchants.length;
          // place holder for swiper item aligment
          this.relatedmerchants.push({ merchant: { name: 'SeeAll' } });
        })
      )
      .subscribe();
  }

  navBtnClick(value: string, parentDivId: any) {
    var targetSwiper = document.getElementById(parentDivId);
    var btn = targetSwiper.getElementsByClassName(value)[0] as HTMLElement;
    btn.click();
  }

  getPopularDeals(popularDeals: any[]) {
    this.populars = popularDeals.slice(0, 10);
    this.populars.push({ merchant: { name: 'SeeAll' } });
  }

  getCarouselClass(dealIds: any[]) {
    if (dealIds != null && dealIds.length <= this.gridColsQtd) {
      return 'disable-carousel-navigation';
    }
    return '';
  }

  getPopularCarouselClass(dealIds: any[]) {
    if (dealIds != null && dealIds.length <= this.relatedmerchantsGridColsQtd) {
      let count = 0;
      dealIds.forEach((deal) => {
        if (deal.merchant.name.length > 1) {
          count++;
        }
      });
      if (count <= this.relatedmerchantsGridColsQtd) {
        return 'disable-carousel-navigation';
      } else {
        return '';
      }
    }
    return '';
  }

  seeAllMerchants(category: any) {
    this.router.navigate([AppRoutes.SEARCH], {
      state: {
        keyword: typeof category == 'object' ? category.enumValue : category,
      },
    });
  }

  getMerchantImage(merchant: any) {
    var imageToUse =
      merchant.heroFilename == null
        ? merchant.logoFilename
        : merchant.heroFilename;

    return this.imagesBase + imageToUse;
  }

  isHeroImg(merchant: any) {
    if (merchant.heroFilename == null) return false;
    return true;
  }

  getLogoImage(merchant: any) {
    return this.imagesBase + merchant.logoFilename;
  }

  getMerchantCardImage(deal: { dealImg: any }) {
    let dealImg = deal.dealImg;

    return this.imagesBase + dealImg;
  }

  openRedemptionInstructionsDialog() {
    this.openInfoContentDialog(
      this.redemptionInstruction,
      this.merchant.name + ' Gift Card Redemption Instructions'
    );
  }

  openTermsAndConditionsDialog() {
    this.openInfoContentDialog(
      this.termsAndConditions,
      this.merchant.name + ' Gift Card Terms & Conditions'
    );
  }

  openBalanceCheckPage() {
    window.open(this.balanceCheckUrl, '_blank');
  }

  openShareUrlPage() {
    // window.open('https://applink.moola.com/?action=merchantPage&merchantId=' + this.merchant.id + '&redirect=' + this.shareUrl, '_blank')
    //a href="mailto:?subject=Check out these amazing deals from {{merchant.name}} on Moola!&body=Check out these amazing deals from {{merchant.name}} on Moola! %0D%0AClick the link to download the Moola app.%0D%0A {{shareUrl}}"
    const subject =
      'Check out these amazing deals from ' + this.merchant.name + ' on Moola!';
    const emailBody =
      'Check out these amazing deals from ' +
      this.merchant.name +
      ' on Moola! %0D%0AClick the link to download the Moola app.%0D%0A' +
      this.shareUrl;
    window.open(`mailto:?subject=${subject}&body=${emailBody}`, '_blank');
  }

  redirectToApp(merchantId: number, merchantName: String) {
    // window.location.href = this.dynamicLinkUrl;
    window.open(this.dynamicLinkUrl.toString(), '_blank');
    // this.openBalanceCheckPage();
    // https://applink.moola.com/?action=merchantPage&merchantId=' + this.merchant.id + '&redirect=' + this.shareUrl
  }

  checkIfRangedDeal(deal: DealData) {
    if (deal.faceValType == 'Range') this.openRangedDialog(deal);
    else this.checkoutOnClick(deal);
  }

  openRangedDialog(deal: DealData) {
    this.dealBannerRef = this.dealBannerDialog.open(RangedDealDialogComponent, {
      data: {
        dealInfo: deal,
      },
      closeOnNavigation: true,
      panelClass: 'ranged-header',
    });

    const dealBannerSub =
      this.dealBannerRef.componentInstance.finalAmount.subscribe((result) => {
        deal.faceVal = result;
        this.dealBannerRef.close();
        this.checkoutOnClick(deal);
        dealBannerSub.unsubscribe();
      });
  }

  checkoutOnClick(deal: DealData) {
    const termsAndRedemption = {
      termsAndConditions: this.termsAndConditions,
      redemptionInstructions: this.redemptionInstruction,
    };

    deal.termsAndRedemption = termsAndRedemption;
    this.sessionStorageService.setSelectedDealData(deal);
    this.router.navigateByUrl(AppRoutes.BASKET);
  }

  dealOnClick(deal: DealData) {
    this.dealBannerDialog.open(MerchantQrCodeDialogComponent, {
      data: {
        dealInfo: deal,
        shareUrl: this.shareUrl,
        dynamicLinkUrl: this.dynamicLinkUrl,
        dynamicLinkObs: this.dynamicLink$,
      },
      // position: { top: '150px' },
      closeOnNavigation: true,
      panelClass: 'banner-header',
    });
  }

  cardClickHandle(merchant: any) {
    var merchantName = merchant.merchant.webUrlName
      .replace(/\s/g, '-')
      .replace(/'/g, '');
    var logoFileName = this.imagesBase + merchant.merchant.logoFilename;
    this.router.navigate([merchantName], {
      state: {
        category: this.cateogryEnum,
        merchantId: merchant.merchant.id,
        logoPath: logoFileName,
      },
    });
  }

  openInfoContentDialog(content: string, title: string) {
    this.infoConentDialog.open(MessageDialogComponent, {
      data: {
        message: content,
        title: title,
      },
      width: '580px',
      closeOnNavigation: true,
    });
  }

  openOrderDisabledPopUp() {
    this.infoConentDialog.open(MessageDialogComponent, {
      data: {
        message: 'Purchasing gift cards on www.moola.com is currently unavailable. Please check back soon.',
        title: 'Sorry!! Something went wrong on our end.'
      }, 
      width: '580px',
      closeOnNavigation: true
    })
  }

  getDollarValue(value: number) {
    let dollarValue =
      value % 100 == 0 ? Math.trunc(value / 100) : (value / 100).toFixed(2);

    return dollarValue;
  }

  /**
   * Returns the deal text to be shown on the card
   *
   * @param deal
   */
  getDealTextOld(deal: any) {
    let dealText = DealsHelper.getDollarValue(deal.faceVal);
    return dealText.toString();
  }

  getDealText(deal: any, returnLineIndex: number) {
    let dealText = DealsHelper.getDealText(deal, false, false);
    var firstLiner = dealText.split(' + ');
    return firstLiner[returnLineIndex];
  }

  showPreferenceDialog() {
    if (
      this.deviceService.isDesktop() ||
      this.sessionStorageService.getBrowserPreference() != null
    )
      return;

    this.sessionStorageService.setBrowserPerference(
      globals.VIEW_PREFERENCE_APP
    );

    var test = window.innerHeight - 181;
    this.openInDialog.open(BrowserSelectDialogComponent, {
      data: {
        dynamicLinkUrl: this.dynamicLinkUrl,
        dynamicLinkObs: this.createDynamicLink(),
      },
      position: { top: test + 'px' },
      width: '100%',
      maxWidth: '100vw',
      animation: { to: 'top' },
      panelClass: 'select-browser-wrapper',
    });
  }

  SetMoolaCashDesc(deals) {
    this.showMoolaDesc =
      deals.filter((d) => d.bonusValType == 'MoolaCash').length > 0;
  }

  SetRefObject(routeParams) {
    if (routeParams['ref'])
      //influencer
      this.sessionStorageService.setRefToken(routeParams['ref'], 10);
  }

  createDynamicLink() {
    let userRef = this.sessionStorageService.getUserReferral();
    let referralRes = this.sessionStorageService.getReferralResponse();

    if (referralRes != null) {
      Object.keys(referralRes).forEach(
        (r) => referralRes[r] == null && delete referralRes[r]
      );
      let httpParams = new HttpParams();
      Object.keys(referralRes).forEach(function (key) {
        httpParams = httpParams.append(key, referralRes[key]);
      });

      this.dynamicLink$ =
        this.createDynamicLinkService.createLinkFireBaseHTTP(httpParams);
      return this.dynamicLink$;
    }
    if (userRef != null && referralRes == null) {
      let httpParams = new HttpParams();
      Object.keys(userRef).forEach(function (key) {
        httpParams = httpParams.append(key, userRef[key]);
      });
      this.dynamicLink$ =
        this.createDynamicLinkService.createLinkFireBaseHTTP(httpParams);
    } else
      this.dynamicLink$ = this.createDynamicLinkService.createLinkFireBase(
        this.activatedRoute.snapshot.queryParamMap
      );
  }

  SetUserReferral(routeParams, deal) {
    let userReferralReference = this.sessionStorageService.getUserReferral();
    if (routeParams['referenceSource']) {
      //QRCode
      userReferralReference = {
        referenceId: routeParams['referenceId']
          ? routeParams['referenceId'].toString()
          : deal.merchant.id.toString(),
        referenceSource: routeParams['referenceSource'],
        referenceType: routeParams['referenceType']
          ? routeParams['referenceType']
          : 'Merchant',
        action: routeParams['action'],
      };
    } else if (routeParams['ref']) {
      let res = this.sessionStorageService.getReferralResponse();
      userReferralReference = {
        referenceId: res.referenceId,
        referenceSource: res.referenceSource,
        referenceType: res.referenceType,
        action: res.action,
        referralCode: res.referralCode,
      };
    } else if (userReferralReference == null && !routeParams['ref']) {
      //Website
      userReferralReference = {
        referenceId: deal.merchant.id.toString(),
        referenceSource: 'Website',
        referenceType: 'Merchant',
        action: 'merchantPage',
      };
    }

    userReferralReference['merchantId'] = deal.merchant.id.toString();
    this.sessionStorageService.setUserReferral(userReferralReference);
    this.createDynamicLink();
  }

  //test function for converting hex to rgb
  // hexToRgb(hex) {
  //   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  //   return result ? {
  //     r: parseInt(result[1], 16),
  //     g: parseInt(result[2], 16),
  //     b: parseInt(result[3], 16)
  //   } : null;
  // }

  // alert(hexToRgb("#0033ff").g); // "51";
}
