import { AppVariables as VAR } from "@app/shared/enums/app-variables.enum";

export class DealsHelper {
    /**
     * Returns the deal text to be shown on the card
     *
     * @param deal
     * @param simpleDeal if the text returned is the complete or the short version
     * @param showOnlyDeal if is to return only offers or all options
     */
     static getDealText(deal: any, simpleDeal: boolean, showOnlyDeal:boolean) {
      const bonus = deal.bonusVal;
      const min = deal.faceValMin;
      const max = deal.faceValMax;
      let dealText: string = '';

      if(deal.bonusValType == VAR.NO_BONUS) {
        if(deal.faceVal && deal.faceValType != VAR.RANGE) {
          return `$${this.getDollarValue(deal.faceVal)} Gift Card`
        } else {
          return simpleDeal ? 'Buy Gift Cards' : 'Buy Now'
        }
      }

      if(deal.faceValType == VAR.RANGE) {

        if(min && max && bonus) {
          let minStr = this.getDollarValue(min);
          let maxStr = this.getDollarValue(max);
          let bonusStr = this.getDollarValue(bonus);
          let prefix = `Buy $${minStr} - $${maxStr} + `
          // The text will be like: Buy $50 - $100 + 5% Off
          if (deal.bonusValType == VAR.PERCENTAGE_OFF) {
             dealText = `${bonus}% Off`;
          }
          // The text will be like: Buy $50 - $100 + $10 Off
          else if (deal.bonusValType == VAR.FIXED_CARD) {
              dealText = `$${bonusStr} Off`;
          }
          // The text will be like: Buy $50 + 10% Moola Cash
          else if(deal.bonusValType == VAR.MOOLA_CASH) {
            dealText = `${bonus/100}% Moola Cash`;
          }
          return simpleDeal ? dealText : prefix + dealText;

        } else {
            // This will be the case of options into autocomplete list for the search
            if(showOnlyDeal) {
                return '';
            }else {
                if(!deal.faceVal) {
                  return VAR.GIFT_CARD;
                }
                return `$${this.getDollarValue(deal.faceVal)} ${VAR.GIFT_CARD}`;
            }
        }
      } else if (deal.faceValType == VAR.FIXED) {
        if(deal.faceVal && bonus) {
          let bonusStr = this.getDollarValue(bonus);
          let buyValue = this.getDollarValue(deal.faceVal);
          let prefix = `Buy $${buyValue} + `;

           // The text will be like: Buy $50 + $10 Bonus
          if(deal.bonusValType == VAR.FIXED_CARD) {
            dealText = `$${bonusStr} ${VAR.BONUS}`;
          }
           // The text will be like: Buy $50 + 10% Off
          else if(deal.bonusValType == VAR.PERCENTAGE_OFF) {
              dealText = `${bonus}% Off`;
          }
          // The text will be like: Buy $50 + 10% Moola Cash
          else if(deal.bonusValType == VAR.MOOLA_CASH) {
            dealText = `${bonus/100}% Moola Cash`;
          }
          return simpleDeal ? dealText : prefix + dealText;

        } else {
           // The text will be like: $50 Gift Card
          if(deal.faceVal && !showOnlyDeal) {
            let faceValStr = this.getDollarValue(deal.faceVal);
            return `$${faceValStr} ${VAR.GIFT_CARD}`;
          }
          return "";
        }
      }
      return ""
    }

    /**
     * Get value in pennies and return in dollar
     * @param value
     * @returns Ex: $10 or $6.99
     */
     static getDollarValue(value: number) {
      let dollarValue =  value % 100 == 0 ? Math.trunc(value / 100) : (value/100).toFixed(2);

      return dollarValue;
    }

    static getPurchaseInfo(deal:any, fixedValue?: number) {
      const bonus = deal.bonusVal;
      const min = deal.faceValMin;
      const max = deal.faceValMax;
      let discount: number;
      let cardValue: number;

      if(deal.faceValType == VAR.RANGE) {
        if(min && max && bonus) {
          // The discount is the percentage value
          if (deal.bonusValType == VAR.PERCENTAGE_OFF && fixedValue != undefined) {
             discount = (bonus / 100) * fixedValue;
             cardValue = fixedValue;
          }
          // The card value will be the value selected by the user plus the discount
          else if (deal.bonusValType == VAR.FIXED_CARD && fixedValue != undefined) {
              // in this case the bonus come in cents so the dollar value is calculated
              discount = bonus / 100;
              cardValue = fixedValue + discount;
          }
        } else {
            discount = 0;
            cardValue = fixedValue != undefined ? fixedValue : 0;
        }
      } else if (deal.faceValType == VAR.FIXED) {
        if(deal.faceVal && bonus) {
          let bonusValue = Number(this.getDollarValue(bonus));
          let buyValue = Number(this.getDollarValue(deal.faceVal));

          // The card value will be the card value plus the discount
          if(deal.bonusValType == VAR.FIXED_CARD) {
            discount = bonusValue;
            cardValue = buyValue + discount;
          }
          // The discount is the percentage value
          else if(deal.bonusValType == VAR.PERCENTAGE_OFF) {
            discount = (bonus / 100) * buyValue;
            cardValue = buyValue;
          }

        } else {
           // The text will be like: $50 Gift Card
          if(deal.faceVal) {
            cardValue = Number(this.getDollarValue(deal.faceVal));
            discount = 0;
          }
        }
      }

      /**
       * Checking both quantity available cards of that deal and limit by available user credit
       * to see the maximum cards available to purchase
       */
      let maxCardsQuantity = 0; //!deal.quantityLimited ? this.getCardsQuantity(cardValue) : deal.quantity < this.getCardsQuantity(cardValue) ? deal.quantity : this.getCardsQuantity(cardValue);

      const purchaseInfo = {
        cardValue: 0, //Number(cardValue),
        discount: 0, //discount,
        cardAfterDiscount: 0, //cardValue - discount,
        maxCardsQuantity: maxCardsQuantity,
        valueInCents: fixedValue ? (fixedValue * 100): deal.faceVal
      }

      return purchaseInfo;
    }

    static getCardsQuantity(cardValue: number): number {
      return Math.trunc(25000 / cardValue);
    }

  }
