import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import * as fromApp from '@store/index';
import {
  getMerchantCardUrl,
  getPromoDealSelector,
} from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { environment } from '@environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-merchant-promo-completed',
  templateUrl: './merchant-promo-completed.component.html',
  styleUrls: ['./merchant-promo-completed.component.scss'],
})
export class MerchantPromoCompletedComponent implements OnInit, OnDestroy {
  notifier = new Subject<void>();
  mercahntCardUrl: string;
  mercahntCardUrlSub: Subscription;
  promoDealSub: Subscription;
  deliveryType: string;
  isShowBackButton: boolean = true;

  imagesBase = environment.moolaImagesServer;
  constructor(
    private store: Store<fromApp.AppState>,
    private location: Location
  ) {}

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    if (this.mercahntCardUrlSub != null) {
      this.mercahntCardUrlSub.unsubscribe();
    }

    if (this.promoDealSub != null) {
      this.promoDealSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.mercahntCardUrlSub = this.store
      .select(getMerchantCardUrl)
      .subscribe((data) => {
        this.mercahntCardUrl = this.imagesBase + data;
      });

    this.promoDealSub = this.store
      .select(getPromoDealSelector)
      .subscribe((data) => {
        this.deliveryType = data.deliveryType;
        this.isShowBackButton = data.isShowBackButton;

        if (data.isShowBackButton) {
          setTimeout(() => {this.goBack()}, 7000);
        } 
      });

      
  }
}
