import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import {
  DealData,
  GiftInfo,
  OccasionSwiperData,
  PaymentMethodResponse,
} from '@app/shared/models/temp-data-source';
// import Swiper core and required modules
import SwiperCore, { Virtual, Autoplay, FreeMode, Pagination } from 'swiper';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as fromApp from '@store/index';
import { Router } from '@angular/router';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { Store } from '@ngrx/store';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import {
  getBuyerInfoSelector,
  getGiftInfoSelector,
  getPaymentResponseSelector,
} from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';


SwiperCore.use([Autoplay, Virtual, FreeMode, Pagination]);

@Component({
  selector: 'app-gift-info-tab',
  templateUrl: './gift-info-tab.component.html',
  styleUrls: ['./gift-info-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GiftInfoTabComponent implements OnInit, OnDestroy {
  dealData: DealData;
  hasRestriction: boolean = false;

  imagePath: string = '../../../assets/media/images/';
  occasionData: Array<OccasionSwiperData> = [
    {
      displayTitle: 'None',
      name: 'None',
      imageFileName: 'occasion_none.png',
    },
    {
      displayTitle: 'Happy Birthday',
      name: 'Birthday',
      imageFileName: 'occasion_birthday.png',
    },
    {
      displayTitle: 'Merry Christmas',
      name: 'Christmas',
      imageFileName: 'occasion_christmas.png',
    },
    {
      displayTitle: 'Happy Holidays',
      name: 'Holidays',
      imageFileName: 'occasion_holidays.png',
    },
    {
      displayTitle: 'Thank You',
      name: 'ThankYou',
      imageFileName: 'occasion_thankyou.png',
    },
    {
      displayTitle: '',
      name: '',
      imageFileName: '',
    },
  ];

  selectedOccasion: OccasionSwiperData = this.occasionData[0];

  recipientFormGroup: FormGroup;

  giftInfo: GiftInfo;
  giftInfo$: Observable<GiftInfo>;

  paymentResponse: PaymentMethodResponse;
  paymentResponse$: Observable<PaymentMethodResponse>;

  notifier = new Subject<void>();
  isLoading = true;

  constructor(
    private sessionStorageService: SessionStorageService,
    private fb: FormBuilder,
    private router: Router,
    private gas: GoogleAnalyticsService,
    private store: Store<fromApp.AppState>
  ) {}
  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  ngOnInit(): void {
    this.dealData = this.sessionStorageService.getSelectedDealData();
    this.hasRestriction =
      this.dealData.restriction != null && this.dealData.restriction != '';
    setTimeout(() => {
      this.isLoading = false;
    }, 500)


    this.giftInfo$ = this.store.select(getGiftInfoSelector);
    this.giftInfo$
      .pipe(
        tap((giftInfo) => {
          if (giftInfo) {
            this.selectedOccasion = this.setOccasionByStr(giftInfo.occasion);
            this.giftInfo = giftInfo;
          }
        }),
        takeUntil(this.notifier)
      )
      .subscribe();

    this.paymentResponse$ = this.store.select(getPaymentResponseSelector);
    this.paymentResponse$
      .pipe(
        tap((paymentResponse) => {
          this.paymentResponse = paymentResponse;
        }),
        takeUntil(this.notifier)
      )
      .subscribe();

    this.recipientFormGroup = this.fb.group({
      recipientName: new FormControl(
        this.giftInfo ? this.giftInfo.recipientName : '',
        [Validators.required, Validators.maxLength(100)]
      ),
      deliveryMethod: new FormControl(
        this.giftInfo ? this.giftInfo.deliveryMethod : 'Link',
        [Validators.required]
      ),
      occasion: new FormControl(
        this.giftInfo ? this.giftInfo.occasion : 'None',
        [Validators.required]
      ),
      recipientEmail: new FormControl(
        this.giftInfo ? this.giftInfo.recipientEmail : '',
        []
      ),
      confirmEmail: new FormControl(this.giftInfo ? this.giftInfo.confirmEmail : '', []),
      from: new FormControl(this.giftInfo ? this.giftInfo.from : '', []),
      message: new FormControl(this.giftInfo ? this.giftInfo.message : '', [Validators.maxLength(200)]),
    });
  }

  setOccasionByStr(occasionStr: string) {
    return this.occasionData.filter((od) => od.name === occasionStr)[0];
  }

  occasionOnClick(occasionData: OccasionSwiperData) {
    this.selectedOccasion = occasionData;
    this.recipientFormGroup.controls['occasion'].setValue(occasionData.name);
  }

  isSelected(occasionData: OccasionSwiperData) {
    if (occasionData.name === this.selectedOccasion.name) {
      return true;
    }
    return false;
  }

  deliveryMethodOnChnage() {
    if (this.recipientFormGroup.controls['deliveryMethod'].value === 'Link') {
      // remove email validator
      this.recipientFormGroup.controls['recipientEmail'].clearValidators();
      this.recipientFormGroup.controls['confirmEmail'].clearValidators();
      this.recipientFormGroup.controls['from'].clearValidators();
    } else {
      // add email validator
      this.recipientFormGroup.controls['recipientEmail'].addValidators([
        Validators.required,
        Validators.pattern(/^\w+(?:\.\w+)*@\w+(?:\.\w+)+$/), this.whitespaceTrimmer
      ]);
      this.recipientFormGroup.controls['confirmEmail'].addValidators([
        Validators.required,
        Validators.pattern(/^\w+(?:\.\w+)*@\w+(?:\.\w+)+$/), this.whitespaceTrimmer,
        this.emailConfirming.bind(this),
      ]);
      this.recipientFormGroup.controls['from'].addValidators([
        Validators.required,
      ]);
    }
    this.recipientFormGroup.controls['recipientEmail'].updateValueAndValidity();
    this.recipientFormGroup.controls['confirmEmail'].updateValueAndValidity();
    this.recipientFormGroup.controls['from'].updateValueAndValidity();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.recipientFormGroup.controls[controlName];
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  nextPage() {
    //add information to store
    if (this.recipientFormGroup.invalid) {
      this.recipientFormGroup.markAllAsTouched()
      return;
    }

    this.gas.eventEmitter(globals.GC_EVENT_WEB_GIFT_ADD_GIFT_INFO)

    this.store.dispatch(
      PurchaseOrderActions.createGiftInfo(this.recipientFormGroup.getRawValue())
    );

    if (this.paymentResponse || this.paymentResponse != null) {
      this.router.navigateByUrl(AppRoutes.ORDER_CONFIRMATION);
    } else {
      this.router.navigateByUrl(AppRoutes.CUSTOMER);
    }

  }

  emailConfirming(fieldControl: FormControl) {
    if (this.recipientFormGroup) {
      return fieldControl.value ===
        this.recipientFormGroup.controls['recipientEmail'].value
        ? null
        : { notEqual: true };
    }
    return null;
  }

  whitespaceTrimmer(control: AbstractControl): { [key: string]: any } | null {
    const trimmedValue = control.value?.trim();

    if (trimmedValue !== control.value) {
      control.setValue(trimmedValue);
    }

    return null;
  }
}
