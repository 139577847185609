import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EffectsModule } from '@ngrx/effects';
import { DealEffects } from './core/store/app/app.effects';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MerchantDetailsComponent } from './merchant-details/merchant-details.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { StoreModule } from '@ngrx/store';
import { HttpClientModule } from '@angular/common/http';
import { PartialsModule } from './shared/partials/partials.module';
import { AppMaterialModule } from './app-material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { MatDialogModule } from '@angular/material/dialog';
import { SwiperModule } from 'swiper/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PurchaseOrderReducer } from './core/store/purchaseOrder/purchaseOrder.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PurchaseOrderEffects } from './core/store/purchaseOrder/purchaseOrder.effects';
import { MerchantPromoCompletedComponent } from './merchant-details/merchant-promo/merchant-promo-completed/merchant-promo-completed.component';
import { MainPageComponent } from './main-page/main-page.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    AppComponent,
    MarketplaceComponent,
    MerchantDetailsComponent,
    SearchPageComponent,
    MerchantPromoCompletedComponent,
    MainPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LayoutModule,
    EffectsModule.forRoot([PurchaseOrderEffects]),
    StoreModule.forRoot({}, {}),
    StoreModule.forFeature('purchaseOrder', PurchaseOrderReducer),
    StoreDevtoolsModule.instrument(),
    EffectsModule.forFeature([PurchaseOrderEffects]),
    HttpClientModule,
    PartialsModule,
    AppMaterialModule,
    NgbModule,
    BrowserAnimationsModule,
		StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer, stateKey: 'router'}),
    ReactiveFormsModule,
    MatDialogModule,
    SwiperModule,
    FontAwesomeModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  // schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
