import { BuyerInfo, CardDetailsTemplate, CreateUserResponse, DeepLinkResponse, PaymentMethodResponse, PortalStatusResponse, PromoDealResponse, PromoReqData, WebOrderResponse, WebOrderTemplate } from '@app/shared/models/temp-data-source';
import { createAction } from '@ngrx/store';

export const setCardQuantity = createAction(
  '[CardQuantity] Set Card Quantity Store',
  (cardQuantity: number) => ({ cardQuantity: cardQuantity })
)

export const getCardQuantity = createAction(
  '[CardQuantity] Get Card Quantity Store',
  (cardQuantity: number) => ({ cardQuantity: cardQuantity })
)

export const createGiftInfo = createAction(
  '[GiftInfo] Create Gift Info',
  (giftInfo: any) => ({ giftInfo: giftInfo })
)

export const clearGiftInfo = createAction(
  '[GiftInfo] ClearGiftInfo'
)

export const createBuyerInfo = createAction(
  '[BuyerInfo] Create Buyer Info',
  (buyerInfo: BuyerInfo, refToken: string) => ({ buyerInfo: buyerInfo, refToken: refToken})
);

// export const getBuyerInfo = createAction(
//   '[BuyerInfo] Get Buyer Info Store',
//   (dummyInfo: number) => ({ dummyInfo: dummyInfo })
// )

export const buyerInfoCreated = createAction(
  '[BuyerInfo] Buyer Info Creation Complete',
  (userResponse: CreateUserResponse) => ({ userResponse: userResponse })
);

export const buyerInfoCreationFailed = createAction(
  '[BuyerInfo] Buyer Info Creation Failed',
)

export const editBuyerInfo = createAction(
  '[BuyerInfo] Edit Buyer Info',
  (buyerInfo: BuyerInfo, userResponse: CreateUserResponse) => ({
    buyerInfo: buyerInfo, userResponse: userResponse
  })
)

export const editBuyerInfoSuccess = createAction(
  '[BuyerInfo] Edit Buyer Info Complete',
  (userResponse: CreateUserResponse) => ({ userResponse: userResponse })
);

export const editBuyerInfoFailed = createAction(
  '[BuyerInfo] Edit Buyer Info Failed',
)

export const fetchDeepLinkParams = createAction(
  '[BuyerInfo] Fetch Deep Link Params',
  (refToken: string, userResponse: CreateUserResponse) => ({refToken: refToken, userResponse: userResponse })
);

export const fetchDeepLinkParamsSuccess = createAction(
  '[BuyerInfo] Fetch Deep Link Params Success',
  (deepLinkParams: DeepLinkResponse) => ({deepLinkParams: deepLinkParams})
)

export const fetchDeepLinkParamsFailed = createAction(
  '[BuyerInfo] Fetch Deep Link Params Failed'
)

export const createPaymentMethod = createAction(
  '[PaymentDetails] Create Payment Method',
  (cardDetails: CardDetailsTemplate, userResponse: CreateUserResponse) => ({
    cardDetails: cardDetails, userResponse: userResponse
  })
)
export const paymentMethodCreated = createAction(
  '[PaymentDetails] Payment Method Creation Complete',
  (paymentResponse: PaymentMethodResponse) => ({ paymentResponse: paymentResponse })
);

export const paymentMethodCreationFailed = createAction(
  '[PaymentDetails] Payment Method Creation Failed',
)

export const followRefCode = createAction(
  '[WebOrder] Follow Referral Code',
  (referralCode: string, webOrderDetails: WebOrderTemplate, userResponse: CreateUserResponse) => ({
    referralCode: referralCode, webOrderDetails: webOrderDetails, userResponse: userResponse })
)

export const followRefCodeSuccess = createAction(
  '[WebOrder] Follow Referral Code Successful'
)

export const followRefCodeFailed = createAction(
  '[WebOrder] Follow Referral Code Failed'
)

export const createWebOrder = createAction(
  '[WebOrder] Create Web Order',
  (webOrderDetails: WebOrderTemplate, userResponse: CreateUserResponse) => ({
    webOrderDetails: webOrderDetails, userResponse: userResponse })
)

export const webOrderCreated = createAction(
  '[WebOrder] Web Order Creation Complete',
  (webOrderResponse: WebOrderResponse) => ({ webOrderResponse: webOrderResponse })
);

export const webOrderCreationFailed = createAction(
  '[WebOrder] Web Order Creation Failed',
)

export const clearState = createAction(
  '[WebOrder] Clear State'
)

export const completedWebOrder = createAction(
  '[WebOrder] Purchase Order Flow Finished'
)

export const createPromoOrder = createAction(
  '[WebPromoOrder] Create Promo Order', (promoReqData: PromoReqData, merchantCardUrl: string) => ({
    promoReqData: promoReqData,
    merchantCardUrl
  })
)

export const getPromoDeal = createAction(
  '[WebPromoOrder] Get Promo Deal', (dealId: number, merchantName: string) => ({
    dealId: dealId,
    merchantName: merchantName
  })
)

export const getPromoDealCompleted = createAction(
  '[WebPromoOrder] Get Promo Deal Completed', (promoDealResponse: PromoDealResponse) => ({
    promoDealResponse: promoDealResponse
  })
)

export const getPortalStatus = createAction(
  '[WebPromoOrder] Get Protal Status'
)

export const getPortalStatusCompleted = createAction(
  '[WebPromoOrder] Get Promo Deal Completed', (portalStatusResponse: PortalStatusResponse) => ({
    portalStatusResponse: portalStatusResponse
  })
)

