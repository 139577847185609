import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() numSteps: number;
  @Input() selectedStep: number;

  constructor() { }

  ngOnInit(): void {
  }

  getNumSteps(): number[] {
      return Array.from({ length: this.numSteps - 1 }, (_, i) => i + 2);
  }

}
