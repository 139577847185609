import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BuyerInfo, CardDetailsTemplate, CreateUserResponse, DeepLinkResponse, MailchimpTemplate, PaymentMethodResponse, WebOrderResponse, WebOrderTemplate } from '@app/shared/models/temp-data-source';
import { AngularDeviceInformationService } from 'angular-device-information';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MailchimpService {
  apiKey = "825d2cd2dfcefa0da13a71d6373a3f77-us19";
  jsonContentType = new HttpHeaders({ 'Authorization': 'apikey ' + this.apiKey });
  localMailchimpURL = "/mailchimp/"


  constructor(
    private httpClient: HttpClient,
    private deviceInformationService: AngularDeviceInformationService,
  ) {
  }

  addEmailToList(hash: string, mailchimpTemplate: MailchimpTemplate, tag: string): Observable<any> {
    const uri =
      this.localMailchimpURL + hash;
    return this.httpClient.put<any>(
      uri,
      JSON.stringify({
        "email_address": hash,
        "status_if_new": "subscribed",
        "merge_fields": mailchimpTemplate,
        "tags": [tag]
      }),
      {
        headers: this.jsonContentType,
        observe: 'response'
      }
    );
  }

}
