import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { AppState } from '@app/core/store';
import { AppService } from '@app/core/services/app.services';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Category } from '@app/marketplace/marketplace.component';
import {
  CategoryItem,
  CategoryItemList,
} from '@app/shared/models/deal-info.model';
import { environment } from '@environment';
import { MatDialog } from '@angular/material/dialog';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { MessageDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-search-page-component',
  templateUrl: './search-page.component.html',
  styleUrls: [
    './search-page.component.scss',
    '../marketplace/marketplace.component.scss',
  ],
  template: '<pre>{{ state$ | async | json }}</pre>',
  encapsulation: ViewEncapsulation.None,
})
export class SearchPageComponent implements OnInit, OnDestroy {
  breadcrumbConfig: any;
  merchants$!: Observable<any>;
  merchants: any;
  merchantsInfo: any;
  currentCategory: any;
  state$: Observable<object> | undefined;
  searchValue: any;
  titleImgUrl: string = './assets/media/images/title-image.png';
  categories: Category[] = [];
  imagesBase = environment.moolaImagesServer;
  //for filter
  categoryItemList$: Observable<CategoryItemList>;
  categoryItems: CategoryItem[] = [];
  unsubscribe: Subject<void> = new Subject<void>();
  filteredCategories;
  swiperFilterConfig = {
    slidesPerView: 6,
    spaceBetween: 0,
    navigation: true,
  };
  showNoResultMessage: boolean = false;
  merchantCount: number = 0;
  isDesktop: boolean = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private meta: Meta,
    private title: Title,
    private infoConentDialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private deviceDetectorService: DeviceDetectorService
  ) {}

  filterMerchantByKeyword(keyword) {
    let merchantList;
    if (keyword === 'all') {
      merchantList = this.merchantsInfo.deals;
    } else {
      merchantList = this.merchantsInfo.deals.filter((d) => {
        let ds = d.categories;
        let dslist = ds.filter((ls) => ls.enumValue === keyword);
        return dslist.length > 0;
      });
    }

    if (merchantList.length == 0) {
      merchantList = this.merchantsInfo.deals.filter((d) => {
        return d.merchant.name
          .toLowerCase()
          .includes(this.searchValue.toLowerCase());
      });
    }

    return merchantList;
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.store.dispatch(PurchaseOrderActions.getPortalStatus());
    this.activatedRoute.paramMap.pipe(
      map(() => {
        return window.history.state.category;
      })
    );

    this.searchValue = window.history.state.keyword;
    if (this.router.url.includes('category')) {
      this.searchValue = this.router.url.split('/')[2];
    }
    this.resetSEO();
    this.categoryItemList$ = this.appService.getCategories();

    this.merchants$ = this.appService.getMerchantsByKeyword(
      'all',
      'PopularDeals'
    );

    this.merchants$
      .pipe(
        tap((data) => {
          this.merchantsInfo = data;

          let searchString =
            this.searchValue == undefined ? 'all' : this.searchValue;
          this.merchants = this.filterMerchantByKeyword(searchString);

          if (this.router.url.includes('weekly-deals')) {
            //stg: PopularDeals dev: SpecialityRetail prod:	WeeklyDeals
            this.merchants = this.filterMerchantByKeyword('WeeklyDeals');
            this.searchValue = 'Weekly Deals';
          }

          if (this.merchants.length == 0) {
            this.showNoResultMessage = true;
            //stg prod: PopularDeals dev: SpecialityRetail
            this.merchants = this.filterMerchantByKeyword('PopularDeals');
          }

          this.merchantsInfo.deals = this.merchants;
          if (data.deals.length != 0) {
            this.currentCategory =
              window.history.state.keyword != undefined
                ? data.deals[0].categories.find(
                    (category) =>
                      category.enumValue == window.history.state.keyword
                  )
                : null;
          }
        })
      )
      .subscribe();

    // this.route.queryParams.subscribe((params) => {
    //   this.merchants$ = this.appService.getMerchantsByKeyword(
    //     this.searchValue,
    //     'PopularDeals'
    //   );
    //   // this.checkGridColumns();

    //   this.merchants$
    //     .pipe(
    //       tap((data: { deals: { categories: any[] }[] }) => {
    //         if (!data) return;
    //         this.merchantsInfo = data;
    //         this.merchants = data.deals;
    //         if (data.deals.length != 0) {
    //           this.currentCategory =
    //           window.history.state.keyword != undefined
    //             ? data.deals[0].categories.find(
    //               (category) =>
    //                 category.enumValue == window.history.state.keyword
    //             )
    //             : null;
    //         }

    //         this.setTitle();
    //         this.filteredCategories = this.merchantsInfo.bisCategories.filter(
    //           (cat) => cat.isPromotional
    //         );
    //         this.setSEO();
    //       })
    //     )
    //     .subscribe();
    // });

    setTimeout(() => {
      this.categoryItemList$
        .pipe(
          tap((data) => {
            var item: CategoryItem = {
              enumValue: 'all',
              text: 'All',
              type: 'Consumer',
              isCurated: false,
              isPromotional: true,
              isParent: true,
              status: 'Active',
              categoryIconFilename: 'cat_icon_services.png',
              categoryHeroFilename: 'hero_popular.jpg',
            };

            // this.categoryItems = data.categories.filter(
            //   (cat) => !cat.isPromotional
            // );
            this.categoryItems = data.categories.filter((obj) =>
              this.merchantsInfo.bisCategories.some(
                (refObj) => refObj.enumValue === obj.enumValue
              )
            );

            this.categoryItems = data.categories.filter(
              (c) => !c.isPromotional && c.type === 'Consumer'
            );
            this.categoryItems.unshift(item);
          }),
          takeUntil(this.unsubscribe)
        )
        .subscribe();
    }, 500);

    this.route.data.subscribe((data) => {
      this.breadcrumbConfig = data['breadcrumb'];
    });

    // setTimeout(() => {
    //   if (!this.sessionStorageService.getOrderEnabled()) {
    //     this.openOrderDisabledPopUp();
    //   }
    // }, 500)
  }

  showMarketPlace() {
    this.router.navigate([AppRoutes.MARKETPLACE]);
  }

  setTitle() {
    this.title.setTitle(
      (this.currentCategory ? this.currentCategory.text : this.searchValue) +
        ' Gift Cards - Moola'
    );
  }

  setSEO() {
    this.meta.addTags([
      {
        name: 'description',
        content:
          'Web Marketplace Deal Search -' +
          (this.currentCategory
            ? this.currentCategory.text
            : this.searchValue) +
          ', ' +
          (this.currentCategory
            ? this.currentCategory.text
            : this.searchValue) +
          ', deals, sales, discounts',
      },
      { name: 'author', content: 'Moola' },
      {
        name: 'keywords',
        content:
          'Moola, Web Marketplace, Marketplace, Giftcards, Gifts, Shopping, Deals, Discounts, ' +
          (this.currentCategory ? this.currentCategory.text : this.searchValue),
      },
    ]);

    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    const seo = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: 'Moola Marketplace',
      image: [
        'https://content.moola.com/wp-content/uploads/2019/10/Logo_Desktop_purple.svg',
      ],
      description: 'Digital gift card marketplace',
      brand: {
        '@type': 'Brand',
        name: 'Moola',
      },
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: '0',
          currency: 'CAD',
        },
        shippingDestination: [
          {
            '@type': 'DefinedRegion',
            addressCountry: 'CA',
          },
        ],
      },
      offers: {
        '@type': 'Offer',
        url: 'https://market.qa.moola.com/moola',
        priceCurrency: 'CAD',
        price: '0',
        itemCondition: 'https://schema.org/New',
        availability: 'https://schema.org/InStock',
      },
    };
    script.textContent = JSON.stringify(seo);
    document.head.appendChild(script);
  }

  getCategoryIcon(category: CategoryItem) {
    return this.imagesBase + category.categoryIconFilename;
  }

  resetSEO() {
    if (
      document.head.getElementsByTagName('script') != null &&
      document.head.getElementsByTagName('script').length > 0
    ) {
      document.head.removeChild(
        document.head.getElementsByTagName('script')[0]
      );
    }
    (
      document.querySelector("[name='description']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[name='keywords']") || { remove: () => 0 }
    ).remove();
  }

  // checkGridColumns() {
  //   this.breakpointObserver.observe([
  //     Breakpoints.XSmall,
  //     Breakpoints.Small,
  //     Breakpoints.Medium,
  //     Breakpoints.Large,
  //     Breakpoints.XLarge
  //   ]).subscribe(result => {
  //     if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
  //       this.titleImgUrl = "./assets/media/images/title-image-mobile.png";
  //     }
  //     else if (result.breakpoints[Breakpoints.Medium]) {
  //       this.titleImgUrl = "./assets/media/images/title-image-mobile.png";
  //     }
  //     else if (result.breakpoints[Breakpoints.Large] || result.breakpoints[Breakpoints.XLarge]) {
  //       this.titleImgUrl = "./assets/media/images/title-image.png";
  //     }
  //   });
  // }

  navBtnClick(value: string, parentDivId: any) {
    var section = document.getElementsByTagName('swiper');
    var targetSwiper;
    for (let i = 0; i < section.length; i++) {
      if (section[i].getAttribute('ng-reflect-id') === parentDivId.name) {
        targetSwiper = section[i];
        break;
      }
    }
    var btn = targetSwiper.getElementsByClassName(value)[0] as HTMLElement;
    btn.click();
  }

  categoryBtnOnClick(category: CategoryItem) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    if (category == null) {
      // this.router.navigate(['/']);
      this.router.navigate([AppRoutes.SEARCH], {
        state: { keyword: 'all' },
      });
    } else {
      this.router.navigate([AppRoutes.SEARCH], {
        state: { keyword: category.enumValue },
      });
    }
  }

  openOrderDisabledPopUp() {
    this.infoConentDialog.open(MessageDialogComponent, {
      data: {
        message:
          'Purchasing gift cards on www.moola.com is currently unavailable. Please check back soon.',
        title: 'Sorry!! Something went wrong on our end.',
      },
      width: '580px',
      closeOnNavigation: true,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
