<div class="quantity-counter">
  <div style="display: flex">
    <button mat-icon-button aria-label="Remove" (click)="incrementValue(-_step)" [disabled]="shouldDisableDecrement(_value)">
      <mat-icon>remove</mat-icon>
    </button>
    <span>{{ _value }}</span>
    <button mat-icon-button aria-label="Add" (click)="incrementValue(_step)" [disabled]="shouldDisableIncrement(_value)">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>