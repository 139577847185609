export class Country {
    public static values = {
        Canada: 'CA',
        US: 'US'
    }
}

export class Canada { 
    public static provinces: string[] = ['AB','BC','MB','NB','NL','NS','NT','NU','ON','PE','QC','SK','YT'];
    public static postalCodePattern: string = '^(?!.*[DFIOQUdfioqu])[A-VXYa-vxy][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9]$';
}

export class US {
    public static states: string[] = ["AL","AK","AS","AZ","AR","AA","AE","AP","CA","CO","CT","DE","DC","FL",
                                    "GA","GU","HI","ID","IL","IN","IT","IA","KS","KY","LA","ME","MD","MA","MI",
                                    "MN","MS","MO","MT","NE","NV","NH","NJ","NM","NY","NC","ND","MP","OH","OK",
                                    "OR","PW","PA","PR","RI","SC","SD","TN","TX","VI","US","UT","VT","VA","WA",
                                    "WV","WI","WY"];
    public static zipCodePattern: string = '^[0-9]{5}(?:-[0-9]{4})?$'

}