import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { getBuyerInfoSelector, getIsLoadingSelector, getUserResponseSelector } from '@app/core/store/purchaseOrder/purchaseOrder.selectors';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { BuyerInfo, CardDetailsTemplate, CreateUserResponse } from '@app/shared/models/temp-data-source';
import { Country, Canada, US } from '@app/shared/utiles/helpers/address-data.helper';
import { expireDateValidator } from '@app/shared/utiles/validators/expiry-date.validator';
import { Store } from '@ngrx/store';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'
import * as fromApp from '@store/index'
import { AngularDeviceInformationService } from 'angular-device-information';
import { Observable, Subject, Subscription, takeUntil, tap } from 'rxjs';
import iso3311a2 from 'iso-3166-1-alpha-2';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DealData } from '@app/shared/models/temp-data-source';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';

@Component({
  selector: 'app-card-details-tab',
  templateUrl: './card-details-tab.component.html',
  styleUrls: ['./card-details-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardDetailsTabComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isDesktop: boolean = this.deviceInformationService.isDesktop();
  @Input() stepper: MatStepper;
  @Output() formValidator = new EventEmitter<boolean>();

  flagImgSrc = "assets/media/icons/flags/";

  notifier = new Subject<void>();

  cardFormGroup: FormGroup;

  isLoading: boolean;
  isLoadingSub: Subscription;
  isNext: boolean;

  buyerInfo: BuyerInfo;
  buyerInfo$: Observable<BuyerInfo>;

  userResponse: CreateUserResponse;
  userResponse$: Observable<CreateUserResponse>;

  dealData: DealData;
  hasRestriction: boolean = false;

  countries;
  countryCodeMap;
  expiryDateMask = [/\d/, /\d/, '/', /\d/, /\d/]
  provinces;
  postalCodePattern;

  isCanadaOrUS: boolean = true;

  numSteps: number = 3;
  selectedStep: number = 2;

  constructor(
    private fb: FormBuilder,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private deviceInformationService: AngularDeviceInformationService,
    private gas: GoogleAnalyticsService,
    private sessionStorageService: SessionStorageService
  ) {
    this.isNext = false;
   }

  ngOnInit(): void {
    if (this.sessionStorageService.getIsGift()) {
      this.numSteps = 4;
      this.selectedStep = 3;
    }

    this.dealData = this.sessionStorageService.getSelectedDealData();
    this.hasRestriction =
      this.dealData.restriction != null && this.dealData.restriction != '';

    this.isLoadingSub = this.store.select(getIsLoadingSelector).subscribe(
      (isLoading) => this.isLoading = isLoading
    )

    this.buyerInfo$ = this.store.select(getBuyerInfoSelector);
    this.buyerInfo$.pipe(
      tap(buyerInfo => {
        if (!buyerInfo || buyerInfo == null) this.router.navigateByUrl(AppRoutes.BASKET);
        this.buyerInfo = buyerInfo
      }), takeUntil(this.notifier)
    ).subscribe()

    this.userResponse$ = this.store.select(getUserResponseSelector);
    this.userResponse$.pipe(
      tap(userResponse => {
        this.userResponse = userResponse
      }), takeUntil(this.notifier)
    ).subscribe()

    this.provinces = Canada.provinces;
    this.postalCodePattern = Canada.postalCodePattern;
    this.countryCodeMap = Object.entries(iso3311a2.getData());

    this.countries = [
      { viewValue: "Canada", value: "CA", img: this.flagImgSrc + "ca.svg" },
      { viewValue: "United States", value: "US", img: this.flagImgSrc + "us.svg" }
    ]

    this.countryCodeMap.map((entry) => {
      if (entry[1] == "Canada" || entry[1] == "United States") return;
      const temp = {
        viewValue: entry[1],
        value: entry[0],
        img: this.flagImgSrc + entry[0].toLowerCase() + ".svg"
      }
      this.countries.push(temp);
    })

    this.cardFormGroup = this.fb.group({
      cardholderName: new FormControl(
        '',
        [Validators.required, Validators.maxLength(100)],
      ),
      cardNumber: new FormControl(
        '',
        [Validators.required, Validators.minLength(15), Validators.maxLength(16), this.trimWhitespace],
      ),
      expiryDate: new FormControl(
        '',
        [Validators.required, Validators.pattern('^(0[1-9]|1[0-2])\/?([0-9]{2})$'), expireDateValidator(), this.trimWhitespace],
      ),
      cvc: new FormControl(
        '',
        [Validators.required, Validators.minLength(3), Validators.maxLength(4), this.trimWhitespace],
      ),
      countryControl: new FormControl({
        value: this.countries[0],
        disabled: false
      }, [Validators.required,]
      ),
      provinceState: new FormControl(
        '',
        [Validators.required],
      ),
      postalCode: new FormControl(
        '',
        [Validators.required, Validators.pattern(this.postalCodePattern)],
      ),
      streetName: new FormControl(
        '',
        [Validators.required, Validators.maxLength(255)],
      ),
    })
  }

  ngAfterViewInit(): void {
    var gao: GoogleAnalyticsObject = {userId: this.userResponse.id, itemId: this.dealData.id};
    this.gas.eventEmitter(this.sessionStorageService.getIsGift() ? globals.GC_EVENT_WEB_GIFT_ADD_BUYER_INFO:globals.GC_EVENT_WEB_ADD_BUYER_INFO, gao)
   }

  ngOnDestroy(): void {
    if (this.isLoadingSub) {
      this.isLoadingSub.unsubscribe();
    }

    if (!this.isNext) {
      this.gas.eventEmitter(this.sessionStorageService.getIsGift() ? globals.GC_EVENT_WEB_GIFT_LEAVE_CHECK_OUT:globals.GC_EVENT_WEB_LEAVE_CHECK_OUT)
    }

    this.notifier.next()
    this.notifier.complete();
  }

  nextPage() {
    if (this.cardFormGroup.invalid) {
      this.cardFormGroup.markAllAsTouched();
      return;
    }

    this.isNext = true;

    const expDate = this.cardFormGroup.value.expiryDate.split('/');
    expDate[1] = "20" + expDate[1];

    const cardDetails: CardDetailsTemplate = {
      cardNumber: this.cardFormGroup.value.cardNumber,
      cardExpMonth: expDate[0],
      cardExpYear: expDate[1],
      cardCvn: this.cardFormGroup.value.cvc,
      cardHolderName: this.cardFormGroup.value.cardholderName,
      address: {
        streetNumber: "",
        streetName: this.cardFormGroup.value.streetName,
        postalCode: this.cardFormGroup.value.postalCode,
        city: "N/A",
        state: this.cardFormGroup.value.provinceState,
        country: this.cardFormGroup.value.countryControl.value
      },
    };

    this.store.dispatch(
      PurchaseOrderActions.createPaymentMethod(
        cardDetails,
        this.userResponse
      )
    )

    scroll(0, 0)
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.cardFormGroup.controls[controlName]
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    )
  }

  trimWhitespace(control: AbstractControl): { [key: string]: any } | null {
    const trimmedValue = control.value?.trim();

    if (trimmedValue !== control.value) {
      control.setValue(trimmedValue);
    }

    return null;
  }

  updateAddressOptions(event) {
    let country = event.value.value;
    // this.order.paymentInfo['country'] = country;
    this.postalCodePattern = country == Country.values.Canada ? Canada.postalCodePattern : US.zipCodePattern;
    this.provinces = country == Country.values.Canada ? Canada.provinces : US.states;
    this.isCanadaOrUS = country == Country.values.Canada || country == Country.values.US;

    if (!this.isCanadaOrUS) {
      this.cardFormGroup.controls['provinceState'].disable();
      this.cardFormGroup.controls['postalCode'].disable();
    } else {
      this.cardFormGroup.controls['provinceState'].enable();
      this.cardFormGroup.controls['postalCode'].enable();
    }

    this.cardFormGroup.controls['postalCode'].clearValidators();
    this.cardFormGroup.controls['postalCode'].setValidators([Validators.required, Validators.pattern(this.postalCodePattern)])
    // Checking the postal code validation case the country has changed
    this.cardFormGroup.get('postalCode').updateValueAndValidity();
  }
}
