import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { Observable, Subject, tap } from 'rxjs';
import { MerchantQrCodeDialogComponent } from '../dialogs/merchant-qr-code-dialog/merchant-qr-code-dialog.component';
import * as globals from '@app/shared/models/globals';
import { environment } from '@environment';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { ReferralInfo } from '@app/shared/models/deal-info.model';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-header-component',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isMarketplace?: boolean;
  private unsubscribe: Subject<any>;
  mobilemode!: Boolean | true;
  smallestScreen: Boolean | false;
  isNavbarCollapsed = true;
  queryParams$: Observable<Params>;
  ref: string;
  url: string;

  isShopPage: Boolean;
  isDesktop: Boolean;

  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private infoConentDialog: MatDialog,
    private googleAnalyticsServices: GoogleAnalyticsService,
    private activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private deviceDetectorService: DeviceDetectorService
  ) {
    this.unsubscribe = new Subject();
  }

  ngOnInit(): void {
    this.ref = this.activatedRoute.snapshot.queryParamMap.get('ref');
    this.isShopPage = this.router.url.includes('/shop');
    (this.url =
      this.ref == null
        ? 'https://applink.moola.com/?action=merchantPage&referenceSource=QRCode&referenceType=Merchant&redirect=https://www.moola.com/'
        : 'https://moolamarketplace.page.link/' + this.ref),
      (this.queryParams$ = this.activatedRoute.queryParams);
    this.queryParams$
      .pipe(
        tap((data) => {
          var existingInfo: ReferralInfo =
            this.sessionStorageService.getReferralInfo();
          let info: ReferralInfo = { ...existingInfo, ...data };
          this.sessionStorageService.setReferralInfo(info);
        })
      )
      .subscribe();
    this.checkGridColumns();
    if (this.router.url == '/downloadapp') {
      this.downloadBtnClicked();
    }
    this.isDesktop = this.deviceDetectorService.isDesktop();
  }

  headerRedirect(dest: string) {
    var eventName: string;
    var url: string;
    switch (dest) {
      case 'marketPlace':
        eventName = globals.GC_EVENT_MARKETPLACE_LINK_CLICK;
        url = environment.marketplaceURL;
        break;

      case 'shop':
        eventName = globals.GC_EVENT_MARKETPLACE_LINK_CLICK;
        url = environment.marketplaceURL + '/shop';
        break;

      case 'merchant_solution':
        eventName = globals.GC_EVENT_SOLUTION_LINK_CLICK;
        url = 'https://content.moola.com/partners/';
        break;

      case 'how_it_works':
        eventName = globals.GC_EVENT_HOW_IT_WORKS_LINK_CLICK;
        url = 'https://content.moola.com/how-moola-works/';
        break;

      case 'contact_us':
        eventName = globals.GC_EVENT_CONTACT_LINK_CLICK;
        url = 'https://content.moola.com/contact-us/';
        break;

      case 'referral_program':
        eventName = globals.GC_EVENT_REFERRAL_PROGRAM_CLICK;
        url = 'https://content.moola.com/moola-referral-program/';
        break;

      case 'money_tips':
        eventName = globals.GC_EVENT_MONEY_TIPS_CLICK;
        url = 'https://content.moola.com/blog/';
        break;
      case '2023_holiday_savings':
        eventName = globals.GC_EVENT_HOLIDAY_SAVING_2023_CLICK;
        url = 'https://content.moola.com/holiday-savings-2023/';
        break;
      case 'valentines':
        eventName = globals.GC_EVENT_VALENTINES_SAVING_2024_CLICK;
        url = 'https://content.moola.com/valentines-day-gift-cards/';
        break;
      case 'springs':
        eventName = globals.GC_EVENT_VALENTINES_SAVING_2024_CLICK;
        url = 'https://content.moola.com/travel-hotel-cash-back-gift-card-spring/';
        break;
      case 'summer':
        eventName = globals.GC_EVENT_VALENTINES_SAVING_2024_CLICK;
        url = 'https://content.moola.com/summer-gift-sale/';
        break;
      case 'fall':
        eventName = globals.GC_EVENT_SEASONAL_CLICK;
        url = 'https://content.moola.com/fall-gifting-sale-2024/';
        break;
        case 'winter':
          eventName = globals.GC_EVENT_SEASONAL_CLICK;
          url = 'https://content.moola.com/winter-holiday-gift-sale-2024/';
          break;

      default:
        break;
    }
    this.googleAnalyticsServices.eventEmitter(eventName);
    window.open(url, '_self');
  }

  downloadBtnClicked() {
    var eventValue: string = 'Home Page';

    if (this.activatedRoute.routeConfig.path.includes('merchant')) {
      eventValue = 'Merchant Page';
    }

    if (this.activatedRoute.routeConfig.path.includes('search')) {
      eventValue = 'Search Page';
    }

    this.googleAnalyticsServices.eventEmitter(globals.GA_EVENT_DOWNLOAD_CLICK, {
      eventValue: eventValue,
    });

    if (!this.mobilemode) {
      this.infoConentDialog.open(MerchantQrCodeDialogComponent, {
        data: {},
        // position: {top: '150px'} ,
        closeOnNavigation: true,
        panelClass: 'banner-header',
      });
    } else {
      this.redirectToApp();
    }
  }

  redirectToApp() {
    window.location.href = this.url;
  }

  goToMarketplace() {
    this.router.navigate([AppRoutes.MARKETPLACE]);
  }

  ngOnDestroy() {}

  /**
   * Rendering columns according to the browser screen size
   */
  checkGridColumns() {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small]
        ) {
          this.mobilemode = true;
          this.smallestScreen = true;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.mobilemode = true;
          this.smallestScreen = false;
        } else if (
          result.breakpoints[Breakpoints.Large] ||
          result.breakpoints[Breakpoints.XLarge]
        ) {
          this.mobilemode = false;
          this.smallestScreen = false;
        }
      });
  }
}
