import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import QRCode from 'easyqrcodejs';
import { DeviceDetectorService } from 'ngx-device-detector';
import SwiperCore, { Autoplay, Pagination, Swiper } from 'swiper';
import { MerchantQrCodeDialogComponent } from '../dialogs/merchant-qr-code-dialog/merchant-qr-code-dialog.component';
import { MatDialog } from '@angular/material/dialog';

SwiperCore.use([Pagination, Autoplay]);

@Component({
  selector: 'app-hero-component',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeroComponent implements OnInit, AfterViewInit {
  qrcodeSize: number;
  ref: string;
  url: string;

  isDesktop: boolean;
  currentSlideIndex: number = 0;

  swiperConfig = {
    pagination: {clickable: true},
    slidesPerView: 1,
    loop: true,
    centeredSlides: true,
    autoplay: { delay: 7000},
  }


  @ViewChild('qrcode', { static: false }) qrcode: ElementRef;
  @HostListener('window:resize', ['$event'])
  onWindowResize($event) {
    this.setQrCodeSize();
    this.createQrCode();
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router,
    private infoConentDialog: MatDialog,
    ) {}

  ngOnInit(): void {
    this.ref = this.activatedRoute.snapshot.queryParamMap.get('ref');
    this.url = this.ref == null ? 'https://applink.moola.com/?action=MerchantPage&referenceSource=QRCode&referenceType=Merchant&redirect=https://www.moola.com/' : 'https://moolamarketplace.page.link/' + this.ref,
    this.setQrCodeSize();

    this.isDesktop = this.deviceDetectorService.isDesktop();
  }

  ngAfterViewInit(): void {
    this.createQrCode();
  }

  createQrCode() {
    let userRef = this.sessionStorageService.getUserReferral();
    if(userRef != null)
      this.url += `?action=${userRef.action}&referenceId=${userRef.referenceId}&referenceSource=${userRef.referenceSource}&referenceType=${userRef.referenceType}`;

    var options = {
      text: this.url,
      height: this.qrcodeSize,
      width: this.qrcodeSize,
      drawer: 'png',
      correctLevel : QRCode.CorrectLevel.L
    };
    if (document.getElementsByTagName("canvas").length > 0) {
      document.getElementsByTagName("canvas")[0].remove()
    }
    if (this.qrcode) {
    new QRCode(this.qrcode.nativeElement, options);
  }

  }

  setQrCodeSize() {
    let width = window.innerWidth;
    if (width > 992) {
      this.qrcodeSize = 140;
    } else if (width > 768) {
      this.qrcodeSize = 80;
    } else if (width > 576) {
      this.qrcodeSize = 60;
    }
  }

  onSlideChange(event) {
    this.currentSlideIndex = event[0].realIndex
  }

  onSlideClick(event) {
    if (event.currentSlideIndex == 0)
      // this.router.navigate([AppRoutes.SHOP]);
    this.router.navigate([AppRoutes.SEARCH], {
        state: { keyword: 'WeeklyDeals' },
      });

    if (event.currentSlideIndex == 1) {
      // this.router.navigate([AppRoutes.SEARCH], {
      //   state: { keyword: 'WeeklyDeals' },
      // });
      // this.router.navigate([AppRoutes.SHOP]);
      window.open('https://content.moola.com/winter-holiday-gift-sale-2024/', '_self');
    }
  }

  redirectToRefer() {
    window.location.href = 'https://content.moola.com/moola-referral-program/';
  }

  downloadBtnClicked() {
    if (this.deviceDetectorService.isDesktop()) {
      this.infoConentDialog.open(MerchantQrCodeDialogComponent, {
        data: {},
        // position: {top: '150px'} ,
        closeOnNavigation: true,
        panelClass: 'banner-header',
      });
    } else {
      window.location.href = 'https://applink.moola.com/?action=merchantPage&referenceSource=QRCode&referenceType=Merchant&redirect=https://www.moola.com/';
    }
  }
}
