import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DealData } from '@app/shared/models/temp-data-source';
import { DealsHelper } from '@app/shared/utiles/helpers/deals.helper';
import { environment } from '@environment';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface DialogContentData {
  message: '';
}

@Component({
  selector: 'ranged-deal-dialog',
  templateUrl: './ranged-deal-dialog.component.html',
  styleUrls: ['./ranged-deal-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RangedDealDialogComponent implements OnInit, AfterViewInit {

  @Output() finalAmount = new EventEmitter<number>();

  amountFormGroup: FormGroup;

  deal: {} | undefined;
  merchantDisplayName: string = '';
  dealInfo: DealData;
  isDesktop: boolean = false;


  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<RangedDealDialogComponent>,
    private deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.merchantDisplayName = this.data.merchantDisplayName;

    this.dealInfo = this.data.dealInfo;
    this.dealInfo.faceValMin;
    this.dealInfo.faceValMax;

    this.amountFormGroup = this.fb.group({
      amount: new FormControl(null,
        [Validators.required, Validators.min(this.dealInfo.faceValMin/100), Validators.max(this.dealInfo.faceValMax/100)],
      ),
    })
  }

  ngAfterViewInit(): void {

  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.amountFormGroup.controls[controlName]
    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    )
  }

  getDealText(deal: any, returnLineIndex: number) {
    let dealText = DealsHelper.getDealText(deal, false, false);
    var firstLiner = dealText.split(' + ');
    return firstLiner[returnLineIndex];
  }

  getDealImg(deal: any) {
    return environment.moolaImagesServer + this.dealInfo.dealImg;
  }

  onBuyNow() {
    if (this.amountFormGroup.invalid) {
      this.amountFormGroup.markAllAsTouched();
      return;
    }

    this.finalAmount.emit(this.amountFormGroup.value.amount * 100); //emit amount in cents
  }
}
