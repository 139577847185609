<mat-dialog-content class="mat-typography">
  <button mat-icon-button aria-label="close dialog" mat-dialog-close class="close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mobile-dialog-wrapper" *ngIf="!isDesktop">
    <div>
      <div class="logo-container">
        <img src={{getDealImg(dealInfo)}} />
      </div>

      <div class="value-container">
        <div class="top-div">
          <span class="header-text">Set Custom Amount</span>
          <div class="value-range flex-row">
            <span>Set Value</span>
            <span class="purple">&nbsp;${{dealInfo.faceValMin / 100}} - ${{dealInfo.faceValMax / 100}}</span>
          </div>
        </div>
        <form autocomplete="off" [formGroup]="amountFormGroup" class="form-wrapper">

          <div class="value-input flex-row">
            <mat-form-field appearance="outline">
              <input matInput placeholder="Enter Amount" type="number" formControlName="amount" id="amount" required>


              <mat-error *ngIf="isControlHasError('amount', 'required')">
                Please enter an amount
              </mat-error>
              <mat-error *ngIf="isControlHasError('amount', 'min') || isControlHasError('amount', 'max')">
                Amount must be within the range
              </mat-error>
            </mat-form-field>
            <button mat-button (click)="onBuyNow()">
              Buy Now
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
  <div class="main-dialog-wrapper" *ngIf="isDesktop">
    <div class="flex-row">
      <div class="logo-container">
        <img src={{getDealImg(dealInfo)}} />
      </div>

      <div class="value-container">
        <div class="top-div">
          <span class="header-text">Set Custom Amount</span>
          <div class="value-range flex-row">
            <span>Set Value</span>
            <span class="purple">&nbsp;${{dealInfo.faceValMin / 100}} - ${{dealInfo.faceValMax / 100}}</span>
          </div>
        </div>
        <form autocomplete="off" [formGroup]="amountFormGroup" class="form-wrapper">

          <div class="value-input flex-row">
            <mat-form-field appearance="outline">
              <input matInput placeholder="Enter Amount" type="number" formControlName="amount" id="amount" required>


              <mat-error *ngIf="isControlHasError('amount', 'required')">
                Please enter an amount
              </mat-error>
              <mat-error *ngIf="isControlHasError('amount', 'min') || isControlHasError('amount', 'max')">
                Amount must be within the range
              </mat-error>
            </mat-form-field>
            <button mat-button (click)="onBuyNow()">
              Buy Now
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</mat-dialog-content>