import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root',
  })
export class NetworkInfoService {

    constructor(
        private httpClient: HttpClient,
    ) {}

    getIpClient(): Observable<any> {
        return this.httpClient.get('https://api.ipify.org/?format=json');
    }
}