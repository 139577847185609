import { Injectable } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import * as globals from '@app/shared/models/globals';
import { AppService } from './app.services';
import {
  DynamicLinkRequest,
  DynamicLinkResponse,
} from '@app/shared/models/temp-data-source';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CreateDynamicLinkService {
  link$: Observable<DynamicLinkResponse>;
  constructor(private appService: AppService) { }

  createLinkFireBase(paramMap: ParamMap): Observable<DynamicLinkResponse> {
    if (paramMap.keys.length != 0 && !paramMap.has('ref')) {
      var linkStr = globals.DYNAMIC_LINK_ROOT_PATH + '/?';
      var mapKeys = paramMap.keys;
      for (var i = 0; i < mapKeys.length; i++) {
        linkStr += mapKeys[i] + '=' + paramMap.get(mapKeys[i]);
        if (i < mapKeys.length - 1) {
          linkStr += '&'
        }
      }

      var req: DynamicLinkRequest = {
        dynamicLinkInfo: {
          domainUriPrefix: globals.REFERRAL_LINK_ROOT_PATH,
          link: linkStr,
          androidInfo: {
            androidPackageName: globals.DYNAMIC_LINK_APN,
          },
          iosInfo: {
            iosBundleId: globals.DYNAMIC_LINK_IBI,
            iosAppStoreId: globals.DYNAMIC_LINK_ISI,
          },
        },
      };
      return this.appService.createDynamicLinks(req);
    }
    return null;
  }

  createLinkFireBaseHTTP(httpParams: HttpParams): Observable<DynamicLinkResponse> {
    if (!httpParams.has('ref')) {
      var linkStr = globals.DYNAMIC_LINK_ROOT_PATH + '/?';
      var mapKeys = httpParams.keys();
      for (var i = 0; i < mapKeys.length; i++) {
        linkStr += mapKeys[i] + '=' + httpParams.get(mapKeys[i]);
        if (i < mapKeys.length - 1) {
          linkStr += '&'
        }
      }

      var req: DynamicLinkRequest = {
        dynamicLinkInfo: {
          domainUriPrefix: globals.REFERRAL_LINK_ROOT_PATH,
          link: linkStr,
          androidInfo: {
            androidPackageName: globals.DYNAMIC_LINK_APN,
          },
          iosInfo: {
            iosBundleId: globals.DYNAMIC_LINK_IBI,
            iosAppStoreId: globals.DYNAMIC_LINK_ISI,
          },
        },
      };
      return this.appService.createDynamicLinks(req);
    }
    return null;
  }

  createLinkFromRef(paramMap: ParamMap): string {
    var mapKeys: string[] = paramMap.keys;
    if (mapKeys.length == 0) {
      return '';
    }
    var link = '';

    if (paramMap.has('ref')) {
      link = globals.REFERRAL_LINK_ROOT_PATH + '/' + paramMap.get('ref');
    }
    return link;
  }
}
