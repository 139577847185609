import { ValidatorFn, AbstractControl } from '@angular/forms';

export function expireDateValidator(): ValidatorFn {
  return (control: AbstractControl) => {
        const year = new Date().getFullYear();
        // Getting two last digits of today's year to compare with what was typed into expiry date year
        const lastTwoDigits = year.toString().substr(-2);
        const controlYear = control.value.split('/')[1];
        const isValid = controlYear && controlYear < lastTwoDigits? false: true;
     return isValid ? null:  {'invalidYear': true};
  };
}