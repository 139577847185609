import { Component, Input, OnInit } from '@angular/core';
import { AngularDeviceInformationService } from 'angular-device-information';
import { environment } from "@environment";

@Component({
  selector: 'app-card-info-box',
  templateUrl: './card-info-box.component.html',
  styleUrls: ['./card-info-box.component.scss']
})
export class CardInfoBoxComponent implements OnInit {

  @Input() dealData: any;
  @Input() hasRestriction: boolean

  moolaImagesServer: string
  isDesktop: boolean
  isCollapseRestriction: boolean = true;

  constructor(
    private deviceInformationService: AngularDeviceInformationService,
  ) {
    this.moolaImagesServer = environment.moolaImagesServer;
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceInformationService.isDesktop();
  }

}
