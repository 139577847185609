import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { DealData } from '@app/shared/models/temp-data-source';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'
import * as fromApp from '@store/index'
import { Store } from '@ngrx/store';
import { AngularDeviceInformationService } from 'angular-device-information';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { environment } from '@environment';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';
import { MessageType, NotificationService } from '@app/core/services/notification.service';

@Component({
  selector: 'app-purchase-details-tab',
  templateUrl: './purchase-details-tab.component.html',
  styleUrls: ['./purchase-details-tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PurchaseDetailsTabComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isDesktop: boolean = this.deviceInformationService.isDesktop();

  constructor(
    private router: Router,
    private store: Store<fromApp.AppState>,
    private deviceInformationService: AngularDeviceInformationService,
    private sessionStorageService: SessionStorageService,
    private gas: GoogleAnalyticsService,
    private notificationService: NotificationService
  ) { }

  hasRestriction: boolean = false;

  quantity: number = 1;
  cardAmount: number = 100;
  dealData: DealData;

  infinity: number = Infinity;
  isLoading: boolean = true;

  ngOnInit(): void {
    const dealData = this.sessionStorageService.getSelectedDealData()
    this.dealData = dealData;
    // this.dealData.dealImg = environment.moolaImagesServer + this.dealData.dealImg;
    this.hasRestriction = this.dealData.restriction != null && this.dealData.restriction != "";
    setTimeout(() => {
      this.isLoading = false;
    }, 500)
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {}

  nextPage() {
    var gao: GoogleAnalyticsObject = { dealData: this.dealData, quantity: this.quantity};
    this.gas.eventEmitter(globals.GC_EVENT_WEB_ADD_TO_CART, gao);
    this.store.dispatch(
      PurchaseOrderActions.setCardQuantity(
        this.quantity
      )
    )
    this.sessionStorageService.setIsGift(false)
    this.router.navigateByUrl(AppRoutes.CUSTOMER);
  }

  nextPageGift() {
    if (this.quantity != 1) {
      this.notificationService.openSnackBar(
        'Quantity Error',
        'We do not allow multiple quantities for gift orders',
        MessageType.Error)
        return
    }
    var gao: GoogleAnalyticsObject = { dealData: this.dealData, quantity: this.quantity};
    this.gas.eventEmitter(globals.GC_EVENT_WEB_ADD_TO_CART, gao);
    this.store.dispatch(
      PurchaseOrderActions.setCardQuantity(
        this.quantity
      )
    )
    this.sessionStorageService.setIsGift(true)
    this.router.navigateByUrl(AppRoutes.GIFT);
  }

  quantityUpdate(q: number) {
    this.quantity = q;
  }

  calculateUnitTotalFaceValue(): number {
    const deal = this.dealData;

    let result = 0;
    switch (deal.bonusValType) {
      case "FixedCard":
        result = deal.faceVal + deal.bonusVal;
        break;
      case "MoolaCash":
        result = deal.faceVal + (deal.bonusVal / 10000 * deal.faceVal);
        break;
      default:
        result = deal.faceVal
    }
    result /= 100;

    return result;
  }

  calculateUnitTotalFaceValueStr(): string {
    return this.calculateUnitTotalFaceValue().toFixed(2);
  }

  calculateCardValue(): string {
    let result = 0;

    result = this.calculateUnitTotalFaceValue() * this.quantity;

    return result.toFixed(2);
  }

  calculateBonusValue(): number {
    const deal = this.dealData;

    let result = 0;
    switch (deal.bonusValType) {
      case "PercentageOff":
        result = deal.bonusVal / 100 * deal.faceVal;
        break;
      case "MoolaCash":
        result = deal.bonusVal / 10000 * deal.faceVal;
        break;
      default:
        result = deal.bonusVal;
        break;
    }
    result *= this.quantity;

    result /= 100;

    return result;
  }

  calculateBonusValueStr(): string {
    return this.calculateBonusValue().toFixed(2);
  }

  calculateTotalPrice(): string {
    const deal = this.dealData;

    let result = 0;
    switch(deal.bonusValType) {
      case "PercentageOff":
        result = this.calculateUnitTotalFaceValue() * this.quantity - this.calculateBonusValue();
        break;
      default:
        result = deal.faceVal * this.quantity;
        result /= 100;
    }

    return result.toFixed(2);
  }
}
