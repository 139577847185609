import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Merchant } from '@app/marketplace/marketplace.component';
import { environment } from '@environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { faFacebookF, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { MessageType, NotificationService } from '@app/core/services/notification.service';

export interface DialogContentData {
  message: '';
}

@Component({
  selector: 'share-socials-dialog',
  templateUrl: './share-socials-dialog.component.html',
  styleUrls: ['./share-socials-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShareSocialsDialogComponent implements OnInit, AfterViewInit {

  faFacebookF = faFacebookF;
  faWhatsapp = faWhatsapp;
  faEnvelope = faEnvelope;

  isDesktop: boolean = false;
  shareURL: string;
  merchant: Merchant;

  constructor(
    public dialogRef: MatDialogRef<ShareSocialsDialogComponent>,
    private deviceService: DeviceDetectorService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.shareURL = this.data.shareURL;
    this.merchant = this.data.merchant;
  }

  goToInfoPage(str: string) {
    switch (str) {
      case "facebook":
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.shareURL)}&amp;src=sdkpreparse`);
        break;
      case "email":
        const emailBody =
          this.shareURL;
        window.open(`mailto:?body=${emailBody}`, '_blank');
        break;
      case "whatsapp":
        window.open(`https://api.whatsapp.com/send?text=${this.shareURL}`);
        break;
    }
  }

  copyToClipboard(text: string): void {
    const tempInput = document.createElement('input');
    tempInput.style.opacity = '0';
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    this.notificationService.openSnackBar(
      'Clipboard',
      'Copied Link!',
      MessageType.Success)
  }

  ngAfterViewInit(): void {

  }
}
