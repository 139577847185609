import { BuyerInfo, CreateUserResponse, DeepLinkResponse, PaymentMethodResponse, PortalStatusResponse, PromoDealResponse, WebOrderResponse } from "@app/shared/models/temp-data-source";
import { Action, createReducer, on } from "@ngrx/store"
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'

export interface State {
  isLoading: boolean;
  cardQuantity: number;
  buyerInfo: BuyerInfo;
  createUserResponse: CreateUserResponse;
  deepLinkResponse: DeepLinkResponse;
  paymentMethodResponse: PaymentMethodResponse;
  webOrderResponse: WebOrderResponse;
  giftInfo: any;
  promoDeal: PromoDealResponse;
  merchantCardUrl: string;
  portalStatus: PortalStatusResponse
}

const initialState: State = {
  isLoading: false,
  cardQuantity: 1,
  buyerInfo: null,
  createUserResponse: null,
  deepLinkResponse: null,
  paymentMethodResponse: null,
  webOrderResponse: null,
  giftInfo: null,
  promoDeal: null,
  merchantCardUrl: null,
  portalStatus: null
}

const _purchaseOrderReducer = createReducer(
  initialState,

  on(PurchaseOrderActions.setCardQuantity, (state, action) => ({
    ...state,
    cardQuantity: action.cardQuantity
  })),
  on(PurchaseOrderActions.createGiftInfo, (state, action) => ({
    ...state,
    giftInfo: action.giftInfo
  })),
  on(PurchaseOrderActions.clearGiftInfo, (state) => ({
    ...state,
    giftInfo: null
  })),
  on(PurchaseOrderActions.createBuyerInfo, (state, action) => ({
    ...state,
    isLoading: true,
    buyerInfo: action.buyerInfo,
  })),
  on(PurchaseOrderActions.buyerInfoCreated, (state, action) => ({
    ...state,
    isLoading: false,
    createUserResponse: action.userResponse
  })),
  on(PurchaseOrderActions.buyerInfoCreationFailed, (state) => ({
    ...state,
    isLoading: false,
    buyerInfo: null,
    createUserResponse: null
  })),
  on(PurchaseOrderActions.editBuyerInfo, (state, action) => ({
    ...state,
    isLoading: true,
    buyerInfo: action.buyerInfo,
  })),
  on(PurchaseOrderActions.editBuyerInfoSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    createUserResponse: action.userResponse
  })),
  on(PurchaseOrderActions.editBuyerInfoFailed, (state) => ({
    ...state,
    isLoading: false,
    buyerInfo: null,
    createUserResponse: null
  })),
  on(PurchaseOrderActions.fetchDeepLinkParams, (state) => ({
    ...state,
    isLoading: true,
    deepLinkResponse: null
  })),
  on(PurchaseOrderActions.fetchDeepLinkParamsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    deepLinkResponse: action.deepLinkParams
  })),
  on(PurchaseOrderActions.fetchDeepLinkParamsFailed, (state) => ({
    ...state,
    isLoading: false,
    deepLinkResponse: null
  })),
  on(PurchaseOrderActions.createPaymentMethod, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PurchaseOrderActions.paymentMethodCreated, (state, action) => ({
    ...state,
    isLoading: false,
    paymentMethodResponse: action.paymentResponse
  })),
  on(PurchaseOrderActions.paymentMethodCreationFailed, (state) => ({
    ...state,
    isLoading: false,
    paymentMethodResponse: null
  })),
  on(PurchaseOrderActions.createWebOrder, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PurchaseOrderActions.webOrderCreated, (state, action) => ({
    ...state,
    isLoading: false,
    webOrderResponse: action.webOrderResponse
  })),
  on(PurchaseOrderActions.webOrderCreationFailed, (state) => ({
    ...state,
    isLoading: false,
    webOrderResponse: null
  })),
  on(PurchaseOrderActions.clearState, (state) => ({
    ...state,
    isLoading: false,
    cardQuantity: 1,
    buyerInfo: null,
    createUserResponse: null,
    paymentMethodResponse: null,
    giftInfo: null
  })),
  on(PurchaseOrderActions.createPromoOrder, (state, action) => ({
    ...state,
    isLoading: true,
    merchantCardUrl: action.merchantCardUrl
  })),
  on(PurchaseOrderActions.completedWebOrder, (state) => ({
    ...state,
    isLoading: false,
    cardQuantity: 1,
    buyerInfo: null,
    createUserResponse: null,
    paymentMethodResponse: null,
    webOrderResponse: null
  })),
  on(PurchaseOrderActions.getPromoDeal, (state) => ({
    ...state,
    isLoading: true,
    promoDeal: null
  })),
  on(PurchaseOrderActions.getPromoDealCompleted, (state, action) => ({
    ...state,
    isLoading: false,
    promoDeal: action.promoDealResponse
  })),
  on(PurchaseOrderActions.getPortalStatus, (state) => ({
    ...state,
    isLoading: true
  })),
  on(PurchaseOrderActions.getPortalStatusCompleted, (state, action) => ({
    ...state,
    portalStatus: action.portalStatusResponse,
    isLoading: false
  }))

)

export function PurchaseOrderReducer(state: State, action: Action) {
  return _purchaseOrderReducer(state, action);
}
