import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { environment } from '@environment';
import { Observable, Subject } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { DealsHelper } from '@app/shared/utiles/helpers/deals.helper';
import { AppVariables as VAR } from '@app/shared/enums/app-variables.enum';
import { AppService } from '@app/core/services/app.services';
import { CategoryItem, CategoryItemList, DealsInfo } from '@app/shared/models/deal-info.model';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { NgSelectConfig } from '@ng-select/ng-select';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';

export interface Merchant {
  category: string;
  dealText: string;
  id: number;
  image: string;
  type: string;
  urlName: string;
  value: string;
}

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchInputComponent implements OnInit, OnDestroy {
  @Input() searchInfo?: DealsInfo;
  @Input() searchValue?: string;

  merchantCtrl = new FormControl('');
  filteredMerchants: Observable<Merchant[]>;
  merchants: Merchant[];

  dealsInfo$!: Observable<DealsInfo>;
  searchControl!: UntypedFormControl;
  searchOptions!: any[];
  filteredOptions!: Observable<any>;
  imagesBase = environment.moolaImagesServer;
  nameTruncateChars: number = 10;
  categories$: Observable<CategoryItemList>;
  categories: CategoryItem[];

  placeholder: string;
  private unsubscribe: Subject<any>;

  constructor(
    private router: Router,
    private appService: AppService,
    private config: NgSelectConfig,
    private googleAnalyticsServices: GoogleAnalyticsService,
    private activedRoute : ActivatedRoute
  ) {
    this.unsubscribe = new Subject();
    this.config.notFoundText = 'Custom not found';
    this.config.appendTo = 'body';
    this.config.bindValue = 'value';
    this.placeholder = '      Search over 250 gift card brands';
  }

  ngOnInit(): void {
    this.searchControl = new UntypedFormControl(this.searchValue);
    this.dealsInfo$ =  this.appService.getMarketplaceDeals();
    this.categories$ = this.appService.getCategories();
    this.categories$.pipe(
      tap(data => {
        this.categories = data.categories.filter(cat => {
          return cat.isPromotional && cat.type == 'Consumer'
        })
      })
    ).subscribe();
    this.getBuildInfo();
  }

  handleSelect(event) {
    this.googleAnalyticsServices.eventEmitter(globals.GA_EVENT_SEARCH_CLICK, {eventValue: event.option.value} )
    var selectedMerchant = this.merchants.filter(merchant => merchant.value == event.option.value)[0];
    if (selectedMerchant != null && selectedMerchant.type === VAR.MERCHANT) {
          var merchantName = selectedMerchant.urlName.replace(/\s/g, "-").replace(/'/g, "");
          var logoFileName = this.imagesBase + selectedMerchant.image;
          this.router.navigate([merchantName], { state: { category: selectedMerchant.category, merchantId: selectedMerchant.id, logoPath: logoFileName }})
        } else {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([AppRoutes.SEARCH], { state: {keyword: selectedMerchant.id}})
        }
  }

  /**
   * Getting info from the server to build the search options
   */
   getBuildInfo() {
    this.dealsInfo$.pipe(
      tap((dealsInfo) => {
        if(!dealsInfo) return;
        this.searchOptions  = this.prepareSearchOptions(dealsInfo);
        this.setFilteredOptions();
      })
    ).subscribe();
  }

  /**
   * Preparing autocomplete options for the first moment when only
   * merchants are available to choose
   * @param info
   * @returns
   */
  prepareSearchOptions(info : any) {
    let searchOptions = [...new Set<string>(info.deals.map((deal: any) => {
      let merchantInfo = {
        value: deal.merchant.name,
        urlName: deal.merchant.webUrlName,
        type: VAR.MERCHANT,
        image: deal.merchant.logoFilename,
        id: deal.merchant.id,
        dealText: DealsHelper.getDealText(deal, true, true),
        category: deal.categories != null && deal.categories.length > 0 ? deal.categories[0].enumValue : ""
      }
      return merchantInfo
    }))];

    let categoryOptions = [...new Set<string>(info.bisCategories.map((category: any) => {
      let categoryInfo = {
        value: category.text,
        urlName: '',
        type: VAR.CATEGORY,
        image: category.iconFilename,
        id: category.enumValue,
        dealText: '',
        category: category.text,
      }
      return categoryInfo
    }))];

    categoryOptions.forEach(item => searchOptions.push(item))
    return searchOptions;
  }

  /**
   * Sets the autocomplete options' filter to only have the values
   * that match with what the user has typed
   */
  setFilteredOptions() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value).sort((a, b) => {
        // Sorting values to show merchants first than other keywords type
        if(a.type == VAR.MERCHANT && b.type != VAR.MERCHANT) return -1;
        if(a.type != VAR.MERCHANT && b.type == VAR.MERCHANT) return 1;
        if(a.value < b.value) return -1;
        else return 1
      }))
    );

    this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value).sort((a, b) => {
        // Sorting values to show merchants first than other keywords type
        if(a.type == VAR.MERCHANT && b.type != VAR.MERCHANT) return -1;
        if(a.type != VAR.MERCHANT && b.type == VAR.MERCHANT) return 1;
        if(a.value < b.value) return -1;
        else return 1
      }))
    ).subscribe((data) => {
      this.merchants = data;
      this.filteredMerchants = this.merchantCtrl.valueChanges.pipe(
        startWith(''),
        map(merchant => (merchant ? this._filterMerchant(merchant) : this.merchants.slice())),
      );
    });
  }

  private _filterMerchant(value: string): Merchant[] {

    var filterValue  = value.toLowerCase()

    return this.merchants.filter(merchant => merchant.value.toLowerCase().includes(filterValue));
  }

  private _filter(option: any): any[] {
    const seen = new Set();
    const filterValue = option.value ? option.value : option;

    let filterValues = this.searchOptions.filter(option => this.setFormattedValue(option.value).includes(this.setFormattedValue(filterValue)));

    // Removing duplicates from filtered values
    const filteredArr = filterValues.filter(el => {
      const duplicate = seen.has(el.value);
      seen.add(el.value);
      return !duplicate;
    })

    return filteredArr;
  }

  enter() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([AppRoutes.SEARCH], { state: {keyword: this.merchantCtrl.value}})
  }

  /**
   * Sets the value to compare without spaces and all lower case
   * @param value
   * @returns value without any space and formatted as lower case
   */
  setFormattedValue(value: string) {
    return value.replace(/ /g,'').toLowerCase();
  }

  getMerchantImage(dealImg: string) {
    return environment.moolaImagesServer + dealImg;
  }

  ngOnDestroy() {
    this.unsubscribe.complete();
  }
}
