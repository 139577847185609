<app-header-component [isMarketplace]="true"></app-header-component>

<div style="background-color: #7514e3; display: flex; justify-content: center; align-items: center; padding: 0 1rem;">
  <div style="width: 1216px;padding: 1rem 0;height: 78.63px;">
    <app-search-input class="search-bar"></app-search-input>
  </div>
</div>

<app-hero-component></app-hero-component>

<div class="main-page-content">
  <section class="subtitle-section">
    <h2>Send A Gift Card and <br>Collect Rewards</h2>
    <p>Moola provides unbeatable deals on the gift cards you love. Buy for a friend or better yet, for yourself and
      collect rewards. With Moola, giving the perfect gift and saving money on the brands you love has never been easier
      or more convenient. Shop over 250 brands across Canada.</p>
  </section>

  <div style="position: relative; min-height: 150px;" *ngIf="dealsInfo == null">
    <div class="spinner-container-2">
      <mat-spinner mat-primary></mat-spinner>
      <br>
    <span>Loading Deals</span>
    </div>
  </div>
  <section class="popular-deals-section">
    <div *ngFor="let deal of dealList">
      <div class="card" (click)="cardClickHandle(deal)">
        <div [class]="isHeroImg(deal) ? 'card-img-hero' : 'card-img-logo'">
          <img *ngIf="isHeroImg(deal)" class="card-logo-corner" src="{{getLogoImage(deal)}}" alt="Logo">
          <img class="card-img-top" src="{{getMerchantImage(deal)}}" alt="Card image cap">
          <!-- <img class="card-img-top" src="../../assets/media/images/TestHeroImage.png" alt="Card image cap"> -->
        </div>
        <div class="card-body">
          <h5 class="card-title" *ngIf="deal && deal.merchant.name && deal.merchant.name.length > 15">
            {{deal.merchant.name| slice:0:15 }}...</h5>
          <h5 class="card-title" *ngIf="deal && deal.merchant.name && deal.merchant.name.length <= 15">
            {{deal.merchant.name}}</h5>
          <span class="card-text" *ngIf="deal.bonusValType == 'PercentageOff' ||
          deal.bonusValType == 'MoolaCash'">Buy Now And Get</span>
          <span class="card-text" *ngIf="deal.bonusValType == 'FixedCard'">Buy
            ${{deal.faceVal/100}} and Get</span>
          <div class="card-text">
            <div class="card-title-second" *ngIf="deal && deal.bonusValType=='FixedCard'">
              ${{deal.savedAmountInCents/100}} Bonus</div>
            <div class="card-title-second"
              *ngIf="!showDealsInPercentage && deal && deal.merchant.name && deal.bonusValType!='MoolaCash' && deal.discountPercent > 0">
              {{deal.discountPercent}}% OFF</div>
            <div class="card-title-second"
              *ngIf="!showDealsInPercentage && deal && deal.merchant.name && deal.bonusValType=='MoolaCash' && deal.discountPercent > 0">
              {{deal.discountPercent / 100}}% Moola Cash</div>
            <div class="card-title-second"
              *ngIf="showDealsInPercentage && deal && deal.merchant.name && deal.bonusValType=='PercentageOff' && deal.discountPercent > 0">
              {{deal.discountPercent}}% OFF</div>
            <div class="card-title-second"
              *ngIf="showDealsInPercentage && deal && deal.merchant.name && (deal.bonusValType=='MoolaCash') && deal.discountPercent > 0">
              {{deal.discountPercent / 100}}% More</div>
            <div *ngIf="deal && deal.merchant.name && !deal.discountPercent" class="invisible">_</div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="app-desc-section">
    <div class="header-wrapper">
      <h2>Virtual Gifting With A Personal Touch</h2>
      <p>You can't always be there in person for special celebrations, but you can always send your regards and
        congratulations through email or text. <i>It's the thought that counts!!</i> Through Moola, you can send gifts
        from big
        and niche brands to your loved ones instantly.
      </p>
    </div>
    <div class="content-wrapper">
      <img src="../../assets/media/gif/attachment.gif" alt="">
      <div class="desc">
        <h3>Hassle-free <span style="display: block;">long distance gift giving</span></h3>
        <p>Say goodbye to the stress of finding the perfect gift and having it arrive on-time. With Moola, you can
          easily search, purchase and send digital gift cards in less than 1 minute.
        </p>
      </div>
      <div class="desc">
        <h3>Monitor gift delivery as if <span style="display: block;">you're there</span></h3>
        <p>Stop questioning if a recipient has received your gift. Track gift card delivery and open rates on the Moola
          App.</p>
      </div>
    </div>
  </section>

  <section class="subtitle-section">
    <h2>Moola Saves Money On Gifts & Everyday Essentials</h2>
    <div *ngIf="!isDesktop">
      <p>Gift cards aren't just for gifting (although we do that too!), they're a great way to find deals on the brands
        you love. Through Moola, you can easily buy gift cards to your favourite stores at a lower price. Why not shop
        smarter, score deals, and savour the savings!</p>
      <br>
      <p>For every $1 spent, you get Moola cash back for future purchases.</p>
    </div>
    <div *ngIf="isDesktop">
      <p>When we think about saving money, we often think about cutting back on non-essentials. However, that's usually
        not possible for special occasions or everyday purchases - gifts and dinners add up. So, making some small
        tweaks
        + great deals can make a big impact on your wallet. Through Moola, you can easily buy gift cards to your
        favourite
        stores at a lower price. Why not shop smarter, score deals, and savour the savings!</p>
    </div>
  </section>

  <section class="info-card-section">
    <div class="info-card">
      <div class="title-wrapper">
        <img src="../../assets/media/images/info-card-tag.svg" alt="info card tag">
        <h3>Deals Each Week</h3>
      </div>
      <p>Each week, we feature new deals in the Moola Marketplace where you can save up to 15% on popular brands. Check
        out this week's feature deal.</p>
      <button (click)="redirectTo('deal')">SHOP DEALS</button>
    </div>
    <div class="info-card">
      <div class="title-wrapper">
        <img src="../../assets/media/images/info-card-tag.svg" alt="info card tag">
        <h3>Everyday Essentials</h3>
      </div>
      <p>Moola has deals on all your everyday essentials such as grocery, fuel, pharmacy, food delivery, and more. It
        all adds up to great savings!</p>
      <button (click)="redirectTo('essentials')">SHOP ESSENTIALS</button>
    </div>
    <div class="info-card">
      <div class="title-wrapper">
        <img src="../../assets/media/images/info-card-tag.svg" alt="info card tag">
        <h3>Download App</h3>
      </div>
      <p>Download the Moola App and start collecting cash back and bonus gift vouchers.</p>
      <button (click)="redirectTo('download')">DOWNLOAD APP</button>
    </div>
  </section>

  <section class="cs-review-section">
    <h2>What Do Customers Think About Moola </h2>
    <div class="cards-wrapper">
      <div class="review-card">
        <p>This is a great app that saves money. Saved over 200 dollars so far!</p>
        <img src="../../assets/media/images/stars-5.png" alt="stars">
        <img src="../../assets/media/icons/apple-store-icon.png" alt="app_store">
      </div>

      <div class="review-card">
        <p>Great way to save on the stuff you buy anyway. I always check Moola first when shopping online or in-store.
          It also helped me organize all my rewards cards in one place which stream-lined my wallet.</p>
        <img src="../../assets/media/images/stars-4.png" alt="stars">
        <img src="../../assets/media/icons/google-store-icon.png" alt="google_store">
      </div>

      <div class="review-card">
        <p>It's very easy to use, has more than 200 brands, and also lots of discounts and bonuses. Once you've paid you
          can receive the gift cards within 1 minute. </p>
        <img src="../../assets/media/images/stars-5.png" alt="stars">
        <img src="../../assets/media/icons/google-play-store-icon.png" alt="app_store">
      </div>
    </div>
  </section>
</div>

<app-footer-component *ngIf="dealsInfo != null"></app-footer-component>