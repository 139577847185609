import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as globals from '@app/shared/models/globals';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { DynamicLinkResponse } from '@app/shared/models/temp-data-source';

@Component({
  selector: 'app-browser-select-dialog',
  templateUrl: './browser-select-dialog.component.html',
  styleUrls: ['./browser-select-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BrowserSelectDialogComponent implements OnInit {
  browserName: string;
  browserIconUrl: string;
  imagePath: string = '../../../../../../assets/media/icons/';
  dynamicLinkUrl: string;
  dynamicLinkObs: Observable<DynamicLinkResponse>
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private deviceService: DeviceDetectorService,
    private dialogRef: MatDialogRef<BrowserSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sessionStorageService: SessionStorageService
  ) {
    this.browserName = this.deviceService.browser;
    this.browserIconUrl =
      this.browserName != 'Chrome'
        ? this.imagePath + 'safari.svg'
        : this.imagePath + 'chrome.svg';


    this.dynamicLinkObs = this.data.dynamicLinkObs
    this.dynamicLinkUrl = this.data.dynamicLinkUrl;
  }

  ngOnInit(): void {
    if (this.dynamicLinkObs != null) {
      this.dynamicLinkObs.pipe(
        tap(data => {
          this.dynamicLinkUrl = data.shortLink;

        }), takeUntil(this.unsubscribe)
      ).subscribe()
    }
  }

  openBtnOnClick() {
    this.sessionStorageService.setBrowserPerference(globals.VIEW_PREFERENCE_APP);
    this.dialogRef.close('user choose app')
    window.open(this.dynamicLinkUrl, "_blank");
  }

  stayBtnOnClick() {
    this.sessionStorageService.setBrowserPerference(globals.VIEW_PREFERENCE_BROWSER);
    this.dialogRef.close('user choose stay');
  }
}
