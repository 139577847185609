<div>
  <app-header-component></app-header-component>

  <div class="spinner-container-2" *ngIf="isLoading">
    <mat-spinner mat-primary></mat-spinner>
  </div>

  <div class="buyer-info-mobile-wrapper" *ngIf="!isDesktop && !isLoading">
    <div class="buyer-info-container">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction"></app-card-info-box>
      <div class="title-section">
        <div class="title">
          <span>Enter Buyer Info</span>
        </div>
        <div class="stepper">
          <app-stepper [numSteps]="numSteps" [selectedStep]="selectedStep"></app-stepper>
        </div>
      </div>

      <form [formGroup]="buyerFormGroup" class="form-wrapper">
        <div class="row">
          <div class="form-field">
            <mat-label>First Name</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="firstName" id="firstName" autocomplete="given-name">
              <mat-error *ngIf="isControlHasError('firstName', 'required')">
                First Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('firstName', 'maxlength')">
                First Name cannot exceed Max Length.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-field">
            <mat-label>Last Name</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="lastName" id="lastName" autocomplete="family-name">
              <mat-error *ngIf="isControlHasError('lastName', 'required')">
                Last Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('lastName', 'maxlength')">
                Last Name cannot over Max Length.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <mat-label>Email</mat-label>

          <mat-form-field appearance="outline">
            <input type="text" inputmode="email" matInput formControlName="recipientEmail" id="recipientEmail" autocomplete="email">
            <mat-error *ngIf="isControlHasError('recipientEmail', 'required')">
              Email Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('recipientEmail', 'pattern')">
              Must be valid email
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Confirm Email</mat-label>

          <mat-form-field appearance="outline">
            <input type="text" inputmode="email" matInput formControlName="confirmEmail" id="confirmEmail" autocomplete="email">
            <mat-error *ngIf="isControlHasError('confirmEmail', 'required')">
              Confirm Email Required
            </mat-error>
            <mat-error *ngIf="!isControlHasError('confirmEmail', 'required') && isControlHasError('confirmEmail', 'notEqual')">
              Confirm Emails do not match
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Phone Number</mat-label>

          <mat-form-field appearance="outline">
            <input type="text"  autocomplete="tel" inputmode="tel" matInput formControlName="phoneNumber" id="phoneNumber" placeholder="(XXX) XXX-XXXX" [textMask]="{mask: phoneNumberMask}">
            <mat-error *ngIf="isControlHasError('phoneNumber', 'required')">
              Phone is Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('phoneNumber', 'pattern')">
              Invalid Phone Number
            </mat-error>
          </mat-form-field>
        </div>
        <div class="btn-wrapper">
          <button [disabled]="isSubmitBtnDisabled" (click)="nextPage()" class="next-btn">
            <span>Next</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <div class="buyer-info-wrapper" *ngIf="isDesktop  && !isLoading">
    <div class="buyer-info-container">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction"></app-card-info-box>
      <div class="title-section">
        <div class="title">
          <span>Enter Buyer Info</span>
        </div>
        <div class="stepper">
          <app-stepper [numSteps]="numSteps" [selectedStep]="selectedStep"></app-stepper>
        </div>
      </div>

      <form [formGroup]="buyerFormGroup" class="form-wrapper">

        <div class="row">
          <div class="form-field">
            <mat-label>First Name</mat-label>
            <mat-form-field appearance="outline">
              <input matInput type="text"  formControlName="firstName" id="firstName" autocomplete="given-name">
              <mat-error *ngIf="isControlHasError('firstName', 'required')">
                First Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('firstName', 'maxlength')">
                First Name cannot exceed Max Length.
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-field">
            <mat-label>Last Name</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="lastName" id="lastName" autocomplete="family-name">
              <mat-error *ngIf="isControlHasError('lastName', 'required')">
                Last Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('lastName', 'maxlength')">
                Last Name cannot over Max Length.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <mat-label>Email</mat-label>

          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="recipientEmail" id="recipientEmail" autocomplete="email">
            <mat-error *ngIf="isControlHasError('recipientEmail', 'required')">
              Email Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('recipientEmail', 'pattern')">
              Must be valid email
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Confirm Email</mat-label>

          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="confirmEmail" id="confirmEmail" autocomplete="email">
            <mat-error *ngIf="isControlHasError('confirmEmail', 'required')">
              Confirm Email Required
            </mat-error>
            <mat-error *ngIf="!isControlHasError('confirmEmail', 'required') && isControlHasError('confirmEmail', 'notEqual')">
              Confirm Emails do not match
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-label>Phone Number</mat-label>

          <mat-form-field appearance="outline">
            <input type="text" matInput formControlName="phoneNumber" id="phoneNumber" placeholder="(XXX) XXX-XXXX" [textMask]="{mask: phoneNumberMask}" autocomplete="tel">
            <mat-error *ngIf="isControlHasError('phoneNumber', 'required')">
              Phone is Required
            </mat-error>
            <mat-error *ngIf="isControlHasError('phoneNumber', 'pattern')">
              Invalid Phone Number
            </mat-error>
          </mat-form-field>
        </div>
        <div class="btn-wrapper">
          <button [disabled]="isSubmitBtnDisabled" (click)="nextPage()" class="next-btn">
            <span>Next</span>
          </button>
        </div>
      </form>
    </div>
  </div>

  <app-footer-component *ngIf="!isLoading"></app-footer-component>
</div>
