import { Injectable } from '@angular/core';
import { CategoryItem, CategoryItemList, ReferralInfo } from '@app/shared/models/deal-info.model';
import * as globals from '@app/shared/models/globals';
import { DealData, DeepLinkResponse } from '@app/shared/models/temp-data-source';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  private localStorage : Storage;
  private sessionStorage : Storage;

  constructor() {
    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
  }

  getBrowserPreference(): string {
    return this.sessionStorage.getItem(globals.VIEW_PREFERENCE);
  }

  setBrowserPerference(preference: string) {
    this.sessionStorage.removeItem(globals.VIEW_PREFERENCE);
    this.sessionStorage.setItem(globals.VIEW_PREFERENCE, preference);
  }

  setReferralInfo(referralInfo: ReferralInfo) {
    this.localStorage.removeItem(globals.REFERRAL_INFO);
    this.localStorage.setItem(globals.REFERRAL_INFO, JSON.stringify(referralInfo));
  }

  getReferralInfo(): ReferralInfo {
    return JSON.parse(this.localStorage.getItem(globals.REFERRAL_INFO));
  }

  setSelectedDealData(dealData: DealData) {
    this.localStorage.removeItem(globals.SELECTED_DEAL_INFO);
    this.localStorage.setItem(globals.SELECTED_DEAL_INFO, JSON.stringify(dealData));
  }

  getSelectedDealData(): DealData {
    return JSON.parse(this.localStorage.getItem(globals.SELECTED_DEAL_INFO));
  }

  setRefToken(ref: string, expirationMinutes) {
    const expirationTimestamp = Date.now() + expirationMinutes * 60 * 1000;
    this.localStorage.removeItem(globals.REF_TOKEN);
    this.localStorage.setItem(globals.REF_TOKEN, JSON.stringify({ ref: ref, expiration: expirationTimestamp }));
  }

  getRefToken(): string {
    const refToken = this.localStorage.getItem(globals.REF_TOKEN);
    if (!refToken) return null;

    const data = JSON.parse(refToken);
    if (data.expiration && Date.now() > data.expiration) {
      localStorage.removeItem(globals.REF_TOKEN);
      return null;
    }

    return data.ref;
  }

  removeRefToken() {
    this.localStorage.removeItem(globals.REF_TOKEN);
  }

  setUserReferral(userReferral: any) {
    this.localStorage.removeItem(globals.USER_REFERRAL);
    this.localStorage.setItem(globals.USER_REFERRAL, JSON.stringify(userReferral));
  }

  getUserReferral(): any {
    return JSON.parse(this.localStorage.getItem(globals.USER_REFERRAL));
  }

  removeMerchantIdFromUserRef() {
    let userRef = JSON.parse(this.localStorage.getItem(globals.USER_REFERRAL));
    delete userRef.merchantId;
    this.setUserReferral(userRef);
  }

  getUUID(): string {
    return this.localStorage.getItem(globals.UUID);
  }

  setReferralResponse(res: DeepLinkResponse) {
    this.localStorage.removeItem(globals.DEEP_LINK_RESPONSE);
    this.localStorage.setItem(globals.DEEP_LINK_RESPONSE, JSON.stringify(res));
  }

  getReferralResponse(): DeepLinkResponse {
    return JSON.parse(this.localStorage.getItem(globals.DEEP_LINK_RESPONSE));
  }

  setIsGift(isGift: boolean) {
    this.localStorage.removeItem(globals.IS_GIFT)
    this.localStorage.setItem(globals.IS_GIFT, isGift.toString());
  }

  getIsGift(): boolean {
    return this.localStorage.getItem(globals.IS_GIFT) == 'true';
  }

  setOrderEnabled(enabled: boolean) {
    this.localStorage.removeItem(globals.ORDER_ENABLED)
    this.localStorage.setItem(globals.ORDER_ENABLED, enabled.toString())
  }

  getOrderEnabled(): boolean {
    return this.localStorage.getItem(globals.ORDER_ENABLED) == 'true';
  }
}
