import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/core/services/app.services';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { environment } from '@environment';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

import { DeviceDetectorService } from 'ngx-device-detector';
import { MainPageDealInfo } from '@app/shared/models/deal-info.model';
import { Deal } from '@app/marketplace/marketplace.component';
import { MerchantQrCodeDialogComponent } from '@app/shared/partials/layouts/dialogs/merchant-qr-code-dialog/merchant-qr-code-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'
import { Store } from '@ngrx/store';
import * as fromApp from '@store/index'
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { MessageDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';

SwiperCore.use([Navigation, Autoplay]);

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MainPageComponent implements OnInit, OnDestroy {
  imagesBase = environment.moolaImagesServer;
  showDealsInPercentage: boolean = true;

  dealsInfo$!: Observable<MainPageDealInfo>;
  dealsInfo: MainPageDealInfo;

  dealList: Deal[];

  unsubscribe: Subject<void> = new Subject<void>();
  isDesktop: Boolean = false;

  //local
  // dealToShowIdss = [
  //   10127, 11992, 11993, 11941, 11894, 10100, 10321, 11853, 10102, 10116,
  // ];

  // Staging
  // dealToShowIdss = [
  //   13952, 14270, 14154, 14133, 10087, 13943, 14158, 51, 27, 10262,
  // ];

  // prod
  dealToShowIdss = [
    10087, 14939, 19842, 27, 14583, 10484, 10493, 10122, 13766, 19079,
  ];

  constructor(
    private appService: AppService,
    private deviceDetectorService: DeviceDetectorService,
    private router: Router,
    private infoConentDialog: MatDialog,
    private store: Store<fromApp.AppState>,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(PurchaseOrderActions.getPortalStatus());
    this.dealsInfo$ = this.appService.getMarketplaceDeals();
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.dealsInfo$
      .pipe(
        tap((dealsInfo) => {
          if (!dealsInfo) return;
          this.dealsInfo = dealsInfo;
          this.dealList = this.dealsInfo.deals.filter((d) =>
            this.dealToShowIdss.includes(d.merchant.id)
          );
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

      // setTimeout(() => {
      //   if (!this.sessionStorageService.getOrderEnabled()) {
      //     this.openOrderDisabledPopUp();
      //   }
      // }, 500)
  }

  openOrderDisabledPopUp() {
    this.infoConentDialog.open(MessageDialogComponent, {
      data: {
        message: 'Purchasing gift cards on www.moola.com is currently unavailable. Please check back soon.',
        title: 'Sorry!! Something went wrong on our end.'
      }, 
      width: '580px',
      closeOnNavigation: true
    })
  }

  isHeroImg(deal: any) {
    if (deal.merchant.heroFilename == null) return false;
    return true;
  }

  getMerchantImage(deal: any) {
    var imageToUse =
      deal.merchant.heroFilename == null
        ? deal.merchant.logoFilename
        : deal.merchant.heroFilename;
    return this.imagesBase + imageToUse;
  }

  getLogoImage(deal: any) {
    return this.imagesBase + deal.merchant.logoFilename;
  }

  cardClickHandle(deal: Deal) {
    var merchantName = deal.merchant.webUrlName
      .replace(/\s/g, '-')
      .replace(/'/g, '');

    var logoFileName = this.imagesBase + deal.merchant.logoFilename;
    this.router.navigate([merchantName], {
      state: {
        category: '',
        merchantId: deal.merchant.id,
        logoPath: logoFileName,
      },
    });
  }

  redirectTo(value: string) {
    switch (value) {
      case 'deal':
        this.router.navigate([AppRoutes.SEARCH], {
          state: { keyword: 'WeeklyDeals' },
        });
        break;
      case 'essentials':
        this.router.navigate([AppRoutes.SEARCH], {
          state: { keyword: 'EverydayEssentials' },
        });

        break;
      case 'download':
        this.downloadBtnClicked();
        break;

      default:
        break;
    }
  }

  downloadBtnClicked() {
    if (this.deviceDetectorService.isDesktop()) {
      this.infoConentDialog.open(MerchantQrCodeDialogComponent, {
        data: {},
        // position: {top: '150px'} ,
        closeOnNavigation: true,
        panelClass: 'banner-header',
      });
    } else {
      window.location.href =
        'https://applink.moola.com/?action=merchantPage&referenceSource=QRCode&referenceType=Merchant&redirect=https://www.moola.com/';
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
