import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotificationBarComponent implements OnInit {
  title: string;
  message: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackBarRef: MatSnackBarRef<NotificationBarComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.data.message;
    this.message = this.data.action;
  }
}
