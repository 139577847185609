import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {
  mobilemode!: Boolean | true;
  faFacebookF = faFacebookF;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faLinkedin = faLinkedin;
  currenDate: Date;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.currenDate = new Date();
    this.checkGridColumns();
  }

  goToInfoPage(content: string) {
    switch (content) {
      case 'PrivacyPolicy':
        window.open('https://content.moola.com/privacy-policy/', '_blank');
        break;

      case 'TermsConditions':
        window.open('https://content.moola.com/terms-of-service/', '_blank');
        break;

      case 'HelpCenter':
        window.open('https://content.moola.com/moola-general-help/', '_blank');
        break;

      case 'ContactUs':
        window.open('https://content.moola.com/contact-us/', '_blank');
        break;

      case 'instagram':
        window.open(
          'https://www.instagram.com/moolacanada/',
          '_blank'
        );
        break;

      case 'twitter':
        window.open(
          'https://twitter.com/moolacanada',
          '_blank'
        );
        break;

      case 'facebook':
        window.open(
          'https://www.facebook.com/MoolaCanada',
          '_blank'
        );
        break;

      case 'youtube':
        window.open(
          'https://www.youtube.com/@moola8709',
          '_blank'
        );
        break;

      case 'linkedin':
        window.open(
          'https://www.linkedin.com/company/moolacanada',
          '_blank'
        );
        break;

      case 'Apple':
        window.open(
          'https://apps.apple.com/us/app/moola-buy-store-gift-cards/id1447157495',
          '_blank'
        );
        break;

      case 'Google':
        window.open(
          'https://play.google.com/store/apps/details?id=com.skyglobal.moola',
          '_blank'
        );
        break;

      default:
        window.open('https://www.moola.com/', '_blank');
    }
  }

  /**
   * Rendering columns according to the browser screen size
   */
  checkGridColumns() {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .subscribe((result) => {
        if (
          result.breakpoints[Breakpoints.XSmall] ||
          result.breakpoints[Breakpoints.Small]
        ) {
          this.mobilemode = true;
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.mobilemode = true;
        } else if (
          result.breakpoints[Breakpoints.Large] ||
          result.breakpoints[Breakpoints.XLarge]
        ) {
          this.mobilemode = false;
        }
      });
  }
}
