<mat-dialog-content class="mat-typography">
  <div class="mobile-dialog-wrapper" *ngIf="!isDesktop">
    <div class="img">
      <img src="../../../../../../assets/media/icons/Replace_card_icon@2x.png">
    </div>
    <div class="text">
      Your Card information will be replaced
    </div>
    <div class="button">
      <button class="purple" (click)="emitCardChange(true)">Accept</button>
    </div>
    <div class="button">
      <button class="white" (click)="emitCardChange(false)">Cancel</button>
    </div>
  </div>

  <div class="main-dialog-wrapper" *ngIf="isDesktop">
    <div class="img">
      <img src="../../../../../../assets/media/icons/Replace_card_icon@2x.png">
    </div>
    <div class="text">
      Your Card information will be replaced
    </div>
    <div class="button">
      <button class="purple" (click)="emitCardChange(true)">Accept</button>
    </div>
    <div class="button">
      <button class="white" (click)="emitCardChange(false)">Cancel</button>
    </div>
  </div>
</mat-dialog-content>