<app-header-component></app-header-component>

<div style="background-color: #7514e3; display: flex; justify-content: center; align-items: center; padding: 0 1rem;">
  <div style="width: 1216px;padding: 1rem 0;height: 78.63px;">
    <app-search-input class="search-bar"></app-search-input>
  </div>
</div>

<div class="search-marketplace-content">
  <div id="filter-section" *ngIf="isDesktop">
    <!-- <app-search-input class="search-bar me-3"></app-search-input> -->
    <swiper class="search-bar-swiper" [config]="swiperFilterConfig" ng-reflect-id="filter-section">
      <!-- <ng-template swiperSlide>
        <button mat-stroked-button class="filter-btn" (click)="categoryBtnOnClick(null)">All</button>
      </ng-template> -->

      <ng-template swiperSlide *ngFor="let category of categoryItems">
        <div>
          <button class="filter-btn" (click)="categoryBtnOnClick(category)">
            <img [src]="getCategoryIcon(category)" />
            <p>{{category.text}}</p>
          </button>
        </div>
      </ng-template>
    </swiper>
    <div class="filter-nav-btn-group" style="margin-bottom: 45px">
      <div class="btn-grp">
        <button mat-stroked-button (click)="navBtnClick('swiper-button-prev', { name: 'filter-section'})"
          id="filter-btn-left"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-stroked-button (click)="navBtnClick('swiper-button-next', { name: 'filter-section'})"
          id="filter-btn-right"><mat-icon>keyboard_arrow_right</mat-icon></button>
      </div>
    </div>
  </div>

  <div class="result-row" *ngIf="merchantsInfo != undefined && merchantsInfo.totalCount > 0">
    <h1 class="result-row-header">{{currentCategory == undefined ?
      searchValue == 'all' ? 'All Deals': 'Search Results: ' + searchValue : currentCategory.text}}</h1>
    <h3 class="font-weight-bold" *ngIf="!showNoResultMessage">{{merchantsInfo.deals.length}} Brands</h3>
  </div>

  <ng-container *ngIf="showNoResultMessage">
    <div class="no-result-wrapper">
      <p class="text">Your search for <span style="font-weight: bold;">"{{searchValue}}"</span> has no results. Here are some other options for you.</p>
      <p class="title">Popular Deals:</p>
    </div>
  </ng-container>

  
  <div>
    <ng-container *ngIf="merchantsInfo != undefined && merchantsInfo.totalCount > 0">
      <app-merchant-row [isFinalSelection]="false" [contentList]="merchants" [showDealsQtd]="true" [simpleDeal]="true"
        [category]="currentCategory"></app-merchant-row>
    </ng-container>
  </div>

  <!-- No Result image -->
  <!-- <ng-container *ngIf="merchantsInfo != undefined && merchantsInfo.totalCount == 0">
    <div class="no-result-wrapper">
      <img src="./assets/media/images/no-results.png" alt="No results found" class="no-result-img mb-3">
      <h1>No Results Found. Please Try Again</h1>
    </div>
  </ng-container> -->
</div>

<app-footer-component></app-footer-component>