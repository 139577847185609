import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BuyerInfo,
  CardDetailsTemplate,
  CreateUserResponse,
  DeepLinkResponse,
  PaymentMethodResponse,
  PortalStatusResponse,
  PromoDealResponse,
  PromoReqData,
  WebOrderResponse,
  WebOrderTemplate,
} from '@app/shared/models/temp-data-source';
import { environment } from '@environment';
import { AngularDeviceInformationService } from 'angular-device-information';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService {
  paymentServer: string;
  jsonContentType = new HttpHeaders({ 'Content-type': 'application/json' });

  constructor(
    private httpClient: HttpClient,
    private deviceInformationService: AngularDeviceInformationService
  ) {
    this.paymentServer = environment.b2CServer;
  }

  createUser(
    buyerInfo: BuyerInfo
  ): Observable<HttpResponse<CreateUserResponse>> {
    const uri = this.paymentServer + '/users';
    return this.httpClient.post<CreateUserResponse>(
      uri,
      JSON.stringify({
        firstName: buyerInfo.firstName,
        lastName: buyerInfo.lastName,
        deviceOs: this.deviceInformationService.getDeviceInfo().os,
        deviceOsVersion:
          this.deviceInformationService.getDeviceInfo().osVersion,
        email: buyerInfo.email,
        phoneNumber: buyerInfo.phoneNumber,
      }),
      {
        headers: this.jsonContentType,
        observe: 'response',
      }
    );
  }

  editUser(
    buyerInfo: BuyerInfo,
    userResponse: CreateUserResponse
  ): Observable<HttpResponse<CreateUserResponse>> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'X-Auth-Token': userResponse.X_Auth_Token,
    });

    const uri = this.paymentServer + '/users';
    return this.httpClient.put<CreateUserResponse>(
      uri,
      JSON.stringify({
        firstName: buyerInfo.firstName,
        lastName: buyerInfo.lastName,
        deviceOs: this.deviceInformationService.getDeviceInfo().os,
        deviceOsVersion:
          this.deviceInformationService.getDeviceInfo().osVersion,
        email: buyerInfo.email,
        phoneNumber: buyerInfo.phoneNumber,
      }),
      {
        headers: header,
        observe: 'response',
      }
    );
  }

  fetchDeepLinkParams(
    refToken: string,
    userResponse: CreateUserResponse
  ): Observable<HttpResponse<DeepLinkResponse>> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      // "X-Auth-Token": userResponse.X_Auth_Token
    });

    const uri = this.paymentServer + '/influencer/deeplink-params/' + refToken;
    return this.httpClient.get<DeepLinkResponse>(uri, {
      headers: header,
      observe: 'response',
    });
  }

  createPaymentMethod(
    cardDetails: CardDetailsTemplate,
    userResponse: CreateUserResponse
  ): Observable<PaymentMethodResponse> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'X-Auth-Token': userResponse.X_Auth_Token,
    });

    const uri = this.paymentServer + '/users/payment-method';
    return this.httpClient.post<PaymentMethodResponse>(
      uri,
      JSON.stringify({
        cardNumber: cardDetails.cardNumber,
        cardExpMonth: cardDetails.cardExpMonth,
        cardExpYear: cardDetails.cardExpYear,
        cardCvn: cardDetails.cardCvn,
        cardHolderName: cardDetails.cardHolderName,
        address: cardDetails.address,
      }),
      {
        headers: header,
      }
    );
  }

  followRefCode(
    referralCode: string,
    userResponse: CreateUserResponse
  ): Observable<WebOrderResponse> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'X-Auth-Token': userResponse.X_Auth_Token,
    });

    const uri = this.paymentServer + '/influencer/code/follow';
    return this.httpClient.post<WebOrderResponse>(
      uri,
      {
        userId: userResponse.id,
        referralCode: referralCode,
      },
      {
        headers: header,
      }
    );
  }

  createWebOrder(
    webOrderTemplate: WebOrderTemplate,
    userResponse: CreateUserResponse
  ): Observable<WebOrderResponse> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'X-Auth-Token': userResponse.X_Auth_Token,
    });

    const uri = this.paymentServer + '/orders';
    return this.httpClient.post<WebOrderResponse>(uri, webOrderTemplate, {
      headers: header,
    });
  }

  createPromoOrder(promoReqData: PromoReqData): Observable<WebOrderResponse> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
    });

    const uri = this.paymentServer + '/orders/promo';
    return this.httpClient.post<WebOrderResponse>(uri, promoReqData, {
      headers: header,
    });
  }

  getPromoDeal(
    dealId: number,
    merchantName: string
  ): Observable<PromoDealResponse> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
    });

    const uri =
      this.paymentServer +
      '/deals/promo/' +
      dealId +
      '?merchantName=' +
      merchantName;
    return this.httpClient.get<PromoDealResponse>(uri, { headers: header });
  }

  getPortalStatus(): Observable<PortalStatusResponse> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
    });

    const uri = this.paymentServer + '/portal-status';
    return this.httpClient.get<PortalStatusResponse>(uri, { headers: header });
  }
}
