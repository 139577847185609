<h2 mat-dialog-title class="text-center">Open Moola</h2>
<mat-dialog-content class="mat-typography">
  <div class="content-wrapper">
    <div class="content-item mb-2">
      <div class="row-item-1">
        <img src="../../../../../../assets/media/icons/moola.svg" width="44">
      </div>
      <div class="row-item-2">
        <span>Moola App</span>
      </div>
      <div class="row-item-3">
        <button mat-flat-button class="select-btn" (click)="openBtnOnClick()">Open</button>
        <!-- <a href="{{dynamicLinkUrl}}" style="outline: 0!important;">
          <button mat-flat-button class="select-btn" style="border: 0!important;">Open</button>
        </a> -->
      </div>
    </div>
    <div class="content-item mb-2">
      <div class="row-item-1">
        <img src='{{browserIconUrl}}' width="44">
      </div>
      <div class="row-item-2">
        <span>{{browserName}}</span>
      </div>
      <div class="row-item-3">
        <button mat-flat-button class="select-btn" (click)="stayBtnOnClick()">Continue</button>
      </div>
    </div>
  </div>


</mat-dialog-content>
