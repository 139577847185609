'use strict';

export const VIEW_PREFERENCE = 'view_preference';
export const VIEW_PREFERENCE_BROWSER = 'browser';
export const VIEW_PREFERENCE_APP = 'app';

export const REFERRAL_INFO = 'referral_info';

export const SELECTED_DEAL_INFO = 'selected_deal_info'

export const CATEGORY_ITEM_LIST = 'category_item_list'

export const REF_TOKEN = 'ref_token';
export const USER_REFERRAL = 'user-ref';
export const DEEP_LINK_RESPONSE = 'deep_link_response';
export const IS_GIFT = 'is_gift'
export const ORDER_ENABLED = 'order_enabled'

export const GA_EVENT_VIEW_DEAL = 'web_view_deal';
export const GC_EVENT_LANDING_PAGE = 'web_landing_page';
export const GA_EVENT_DOWNLOAD_CLICK = 'web_download_click';
export const GA_EVENT_SEARCH_CLICK = 'web_click_search_icon';
export const GA_EVENT_BANNER_CLICK = 'web_add_banner';
export const GA_EVENT_KEYWORD_CLICK = 'web_keywords';

// export const GC_EVENT_HEADER_LINK_CLICK = 'header_link_click';

export const GC_EVENT_MARKETPLACE_LINK_CLICK = 'web_marketplace_header';
export const GC_EVENT_SOLUTION_LINK_CLICK = 'web_merchant_solution_header';
export const GC_EVENT_HOW_IT_WORKS_LINK_CLICK = 'web_how_it_works_header';
export const GC_EVENT_CONTACT_LINK_CLICK = 'web_contact_us_header';
export const GC_EVENT_REFERRAL_PROGRAM_CLICK = 'web_referral_program_header';
export const GC_EVENT_MONEY_TIPS_CLICK = 'web_money_tips_program_header';
export const GC_EVENT_HOLIDAY_SAVING_2023_CLICK = 'web_holiday_savings_2023_header';
export const GC_EVENT_VALENTINES_SAVING_2024_CLICK = 'web_valentines_savings_2024_header';
export const GC_EVENT_SEASONAL_CLICK = 'web_seasonal_click_header';
export const GC_EVENT_SPRING_2024_CLICK = 'web_spring_savings_2024_header';
export const GC_EVENT_WEB_ADD_TO_CART = 'web_add_to_cart';
export const GC_EVENT_WEB_ADD_BUYER_INFO = 'web_add_buyer_info';
export const GC_EVENT_WEB_ADD_PAYMENT_INFO = 'web_add_payment_info';
export const GC_EVENT_WEB_ADD_PAYMENT_INFO_ERROR = 'web_add_payment_info_error';
export const GC_EVENT_WEB_GIFT_ADD_BUYER_INFO = 'web_gift_add_buyer_info';
export const GC_EVENT_WEB_GIFT_ADD_GIFT_INFO = 'web_gift_add_gift_info';
export const GC_EVENT_WEB_GIFT_ADD_PAYMENT_INFO = 'web_gift_add_payment_info';
export const GC_EVENT_WEB_BEGIN_CHECK_OUT = 'web_begin_checkout';
export const GC_EVENT_WEB_ORDER_RECEIVED = 'web_order_received';
export const GC_EVENT_WEB_GIFT_BEGIN_CHECK_OUT = 'web_gift_begin_checkout';
export const GC_EVENT_WEB_GIFT_ORDER_RECEIVED = 'web_gift_order_received';
export const GC_EVENT_WEB_INFLUENCER_REF_ERROR = 'web_influencer_ref_error';
export const GC_EVENT_WEB_ORDER_GIFT_VIA_LINK = 'web_gift_via_link';
export const GC_EVENT_WEB_ORDER_GIFT_VIA_EMAIL = 'web_gift_via_email';
export const GC_EVENT_WEB_VIEW_MERCHANT_DETAILS = 'web_view_merchant_details';
export const GC_EVENT_WEB_LEAVE_CHECK_OUT = 'web_leave_checkout';
export const GC_EVENT_WEB_GIFT_LEAVE_CHECK_OUT = 'web_gift_leave_checkout';
export const GC_EVENT_WEB_PROMO_ALLOW_LOCATION = 'web_promo_allow_location';
export const GC_EVENT_WEB_PROMO_DISABLE_LOCATION = 'web_promo_disable_location';


export const DYNAMIC_LINK_ROOT_PATH = 'https://applink.moola.com';
export const REFERRAL_LINK_ROOT_PATH = 'https://moolamarketplace.page.link';
export const DYNAMIC_LINK_APN = 'com.skyglobal.moola';
export const DYNAMIC_LINK_ISI = '1447157495';
export const DYNAMIC_LINK_IBI = 'com.moolaInc.com.moolaInc.Moola';
export const DYNAMIC_LINK_REDIRECT = 'efr=1'

export const UUID = "uuid";
