import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DealData, DynamicLinkResponse } from '@app/shared/models/temp-data-source';
import { DealsHelper } from '@app/shared/utiles/helpers/deals.helper';
import { environment } from '@environment';
import QRCode from 'easyqrcodejs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

export interface DialogContentData {
  message: '';
}

@Component({
  selector: 'merchant_qr_code-dialog',
  templateUrl: './merchant-qr-code-dialog.component.html',
  styleUrls: ['./merchant-qr-code-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantQrCodeDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('qrcode', { static: false }) qrcode: ElementRef;

  deal: {} | undefined;
  qrBgImgUrl: string = './assets/media/images/qr-background.png';
  merchantDisplayName: string = '';
  qrCodeUrl: string = 'https://applink.moola.com';
  dealInfo: DealData;
  shareUrl: string;
  qrcodeSize: number = 140;
  isDesktop: boolean = false;
  dynamicLink: string;
  dynamicLinkObs: Observable<DynamicLinkResponse>
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<MerchantQrCodeDialogComponent>,
    private deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.merchantDisplayName = this.data.merchantDisplayName;
    if (this.data.merchantName != null && this.data.merchantId != null) {
      this.qrCodeUrl = this.data.merchantDynamicLink;
    }
    this.dealInfo = this.data.dealInfo;
    this.shareUrl = this.data.shareUrl;
    this.dynamicLink = this.data.dynamicLinkUrl;
    this.dynamicLinkObs = this.data.dynamicLinkObs;
  }

  ngAfterViewInit(): void {
    if (this.dynamicLinkObs != null) {
      this.dynamicLinkObs.pipe(
        tap(data => {
          this.createQrCode(data.shortLink);
        }), takeUntil(this.unsubscribe)
      ).subscribe()
    } else {
      this.createQrCode(this.dynamicLink)
    }
  }

  createQrCode(link: string) {

    var options = {
      text:
        this.data.dealInfo == undefined
          ? 'https://applink.moola.com/'
          // Use merchant dynamic link
          //: this.buildDynamicLink(),
          : this.dynamicLink,
      height: this.qrcodeSize,
      width: this.qrcodeSize,
      drawer: 'png',
      correctLevel: QRCode.CorrectLevel.L,
    };
    if (this.isDesktop) {
      new QRCode(this.qrcode.nativeElement, options);
    }
  }

  buildDynamicLink(): string {
    var link =
      'https://applink.moola.com/?action=MerchantPage&merchantId=' +
      this.dealInfo.merchant.id +
      '&redirect=https://www.moola.com/gift-card/' +
      this.dealInfo.merchant.webUrlName +
      '/';

    this.dynamicLink = link;
    return link;
  }

  getMerchantCardImage() {
    return environment.moolaImagesServer + this.dealInfo.dealImg;
  }

  getDealText(deal: any, returnLineIndex: number) {
    let dealText = DealsHelper.getDealText(deal, false, false);
    var firstLiner = dealText.split(' + ');
    return firstLiner[returnLineIndex];
  }

  openShareUrlPage() {
    const subject =
      'Check out these amazing deals from ' +
      this.dealInfo.merchant.name +
      ' on Moola!';
    const emailBody =
      'Check out these amazing deals from ' +
      this.dealInfo.merchant.name +
      ' on Moola! %0D%0AClick the link to download the Moola app.%0D%0A' +
      this.shareUrl;
    window.open(`mailto:?subject=${subject}&body=${emailBody}`, '_blank');
  }

  openDynamicLink() {
    window.open(this.dynamicLink, '_blank')
  }
}
