
  <div class="card-wrapper">
    <div class="card-item">
      <div class="card-img" [class.multiple-cards]="dealData.bonusValType == 'FixedCard'">
        <img src={{moolaImagesServer}}{{dealData.dealImg}} />
      </div>
      <div class="card-desc">
        <div class="card-subtitle">
          <span class="subtitle">{{dealData.merchant.name}} Gift Card</span>
        </div>
        <div class="card-title-container">
          <h5 class="card-title">Buy ${{ dealData.faceVal / 100 }}</h5>
          <h5 class="card-title" *ngIf="dealData.bonusValType != 'NoBonus'">&nbsp;& Get</h5>
        </div>
        <div class="card-deal" [ngSwitch]="dealData.bonusValType"
          [class.invisible]="dealData.bonusValType == 'NoBonus'">
          <div class="card-title-2" *ngSwitchCase="'FixedCard'"><b>+${{dealData.bonusVal / 100}}</b> Bonus</div>
          <div class="card-title-2" *ngSwitchCase="'PercentageOff'"><b>+{{dealData.bonusVal}}%</b> Off</div>
          <div class="card-title-2" *ngSwitchCase="'MoolaCash'"><b>+{{dealData.bonusVal / 100}}%</b> Moola Cash*
          </div>

          <div class="card-title-2" *ngSwitchDefault><b>+${{dealData.bonusVal / 100}}</b> Bonus</div>
        </div>
        <div class="card-restriction" *ngIf="hasRestriction">
          <div (click)="isCollapseRestriction = ! isCollapseRestriction">
            <span>Restrictions Apply</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
          <div #collapseContainer #collapse="ngbCollapse"
          [(ngbCollapse)]="isCollapseRestriction">
            <span>{{dealData.restriction}}</span>
          </div>
        </div>
      </div>
    </div>
