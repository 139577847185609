import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { MerchantDetailsComponent } from './merchant-details/merchant-details.component';
import { SearchPageComponent } from './search-page/search-page.component';
import { CardDetailsTabComponent } from './purchase-order-flow/card-details-tab/card-details-tab.component';
import { BuyerInfoTabComponent } from './purchase-order-flow/buyer-info-tab/buyer-info-tab.component';
import { OrderConfirmationTabComponent } from './purchase-order-flow/order-confirmation-tab/order-confirmation-tab.component';
import { PurchaseDetailsTabComponent } from './purchase-order-flow/purchase-details-tab/purchase-details-tab.component';
import { OrderCompletedTabComponent } from './purchase-order-flow/order-completed-tab/order-completed-tab.component';
import { GiftInfoTabComponent } from './purchase-order-flow/gift-info-tab/gift-info-tab.component';
import { MerchantPromoComponent } from './merchant-details/merchant-promo/merchant-promo.component';
import { MerchantPromoCompletedComponent } from './merchant-details/merchant-promo/merchant-promo-completed/merchant-promo-completed.component';
import { MainPageComponent } from './main-page/main-page.component';

const routes: Routes = [
  {
    path: '',
    component: MainPageComponent,
  },
  {
    path: 'shop',
    component: MarketplaceComponent,
  },
  {
    path: 'downloadapp',
    component: MarketplaceComponent,
  },
  {
    path: 'checkout/basket',
    component: PurchaseDetailsTabComponent,
  },
  {
    path: 'checkout/gift',
    component: GiftInfoTabComponent,
  },
  {
    path: 'checkout/customer',
    component: BuyerInfoTabComponent,
  },
  {
    path: 'checkout/payment',
    component: CardDetailsTabComponent,
  },
  {
    path: 'checkout/confirmation',
    component: OrderConfirmationTabComponent,
  },
  {
    path: 'checkout/complete',
    component: OrderCompletedTabComponent,
  },
  {
    path: 'category/:categoryName',
    component: SearchPageComponent,
    data: { breadcrumb: [
      {
        label: 'Marketplace',
        path: '../../marketplace'
      }
     ]
    }
  },
  {
    path: 'search',
    component: SearchPageComponent,
    data: { breadcrumb: [
      {
        label: 'Marketplace',
        path: '../../marketplace'
      }
     ]
    }
  },
  {
    path: 'weekly-deals',
    component: SearchPageComponent,
    data: { breadcrumb: [
      {
        label: 'Marketplace',
        path: '../../marketplace'
      }
     ]
    }
  },
  
  {
    path: ':merchantName',
    component: MerchantDetailsComponent,
    data: { breadcrumb: [
        {
          label: 'Marketplace',
          path: '../../../marketplace'
        }
      ]
    }
  },
  {
    path: 'promo/completed',
    component: MerchantPromoCompletedComponent
  },
  {
    // dev: 1226
    // stg: 1800
    path: ':merchantName/promotion/:id',
    component: MerchantPromoComponent
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
