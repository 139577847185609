import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { environment } from '@environment';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface DialogContentData {
  message: '';
}

@Component({
  selector: 'card-change-dialog',
  templateUrl: './card-change-dialog.component.html',
  styleUrls: ['./card-change-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CardChangeDialogComponent implements OnInit, AfterViewInit {
  
  @Output() cardChangeBoolean = new EventEmitter<boolean>();
  
  isDesktop: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CardChangeDialogComponent>,
    private deviceService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
  }

  ngAfterViewInit(): void {
  
  }

  emitCardChange(choice: boolean) {
    this.cardChangeBoolean.emit(choice);
  }
}
