<div>
  <app-header-component></app-header-component>

  <div class="spinner-container-2" *ngIf="isLoading">
    <mat-spinner mat-primary></mat-spinner>
  </div>

  <div class="order-details-mobile-wrapper" *ngIf="!isDesktop && !isLoading">
    <div class="order-details-container">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction" ></app-card-info-box>
      <div class="title-section">
        <div class="title">
          <span>{{dealData.merchant.name}}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">Quantity</span>
        </div>
        <div class="right-section">
          <app-quantity-counter (quantityUpdate)="quantityUpdate($event)"
            [_max]="dealData.maxPerAccountLimited ? dealData.maxPerAccount : infinity">
          </app-quantity-counter>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">Card Value</span>
        </div>
        <div class="right-section">
          <span class="amount-text pre-amount-text-1">${{calculateUnitTotalFaceValueStr()}} x {{quantity}}</span>
          <div class="right-text">
            <span class="amount-text amount-heading-1">${{calculateCardValue()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">Subtotal</span>
        </div>
        <div class="right-section" [ngSwitch]="dealData.bonusValType">
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'FixedCard'">Bonus
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'PercentageOff'">Savings
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'MoolaCash'">Bonus Moola Cash
            ${{calculateBonusValueStr()}}</span>

          <div class="right-text">
            <span class="amount-text amount-heading-2">${{calculateTotalPrice()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">You Pay</span>
        </div>
        <div class="right-section">
          <span class="amount-text amount-heading-3">${{calculateTotalPrice()}}</span>
        </div>
      </div>
      <div class="btn-wrapper">
        <button (click)="nextPageGift()" class="gift-btn">
          <span>Send As Gift</span><img src="../../../assets/media/icons/gift_icon.png" />
        </button>
      </div>
      <div class="btn-wrapper">
        <button (click)="nextPage()" class="next-btn">
          <span>Begin Checkout</span>
        </button>
      </div>
      <div class="footer">
        <span>*Download the Moola App to redeem your Moola Cash!</span>
      </div>
    </div>
  </div>

  <div class="order-details-wrapper" *ngIf="isDesktop && !isLoading">
    <div class="order-details-container">
      <app-card-info-box [dealData]="dealData" [hasRestriction]="hasRestriction" ></app-card-info-box>
      <div class="title-section">
        <div class="title">
          <span>{{dealData.merchant.name}}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">Quantity</span>
        </div>
        <div class="right-section">
          <app-quantity-counter (quantityUpdate)="quantityUpdate($event)"
            [_max]="dealData.maxPerAccountLimited ? dealData.maxPerAccount : infinity">
          </app-quantity-counter>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">Card Value</span>
        </div>
        <div class="right-section">
          <span class="amount-text pre-amount-text-1">${{calculateUnitTotalFaceValueStr()}} x {{quantity}}</span>
          <div class="right-text">
            <span class="amount-text amount-heading-1">${{calculateCardValue()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">Subtotal</span>
        </div>
        <div class="right-section" [ngSwitch]="dealData.bonusValType">
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'FixedCard'">Bonus
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'PercentageOff'">Savings
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'MoolaCash'">Bonus Moola Cash
            ${{calculateBonusValueStr()}}</span>

          <div class="right-text">
            <span class="amount-text amount-heading-2">${{calculateTotalPrice()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="amount-text desc-heading">You Pay</span>
        </div>
        <div class="right-section">
          <span class="amount-text amount-heading-3">${{calculateTotalPrice()}}</span>
        </div>
      </div>
      <div class="btn-wrapper">
        <button (click)="nextPageGift()" class="gift-btn">
          <span>Send As Gift</span><img src="../../../assets/media/icons/gift_icon.png" />
        </button>
      </div>
      <div class="btn-wrapper">
        <button (click)="nextPage()" class="next-btn">
          <span>Buy for Myself</span>
        </button>
      </div>
      <div class="footer">
        <span>*Download the Moola App to redeem your Moola Cash!</span>
      </div>
    </div>
  </div>

  <app-footer-component *ngIf="!isLoading"></app-footer-component>
</div>
