<app-header-component></app-header-component>
<div style="background-color: #7514e3; display: flex; justify-content: center; align-items: center; padding: 0 1rem;">
  <div style="width: 1216px;padding: 1rem 0;height: 78.63px;">
    <app-search-input class="search-bar"></app-search-input>
  </div>
</div>

<app-merchant-title [merchant]="merchant" [cateogryEnum]="cateogryEnum" [dealData]="deals[0]" [logoPath]="logoPath"
  [termsAndConditions]="termsAndConditions" [dynamicLinkUrl]="dynamicLinkUrl" [dynamicLinkObs]="dynamicLink$"
  [companyWebsite]="companyWebsite" [redemptionInstruction]="redemptionInstruction" [shareUrl]="shareUrl"
  [briefDescription]="briefDescription" [bannerColour]="bannerColour" [titleBannerUrl]="titleBannerUrl"
  [isPromo]="false"  *ngIf="merchant"></app-merchant-title>

<div id="merchant-detail-wrapper">
  <div class="merchant-detail">
    <div class="giftcard-container">
      <div class="exclusive-deal-container" *ngIf="dealsWithBonus.length > 0">
        <hr>
        <div class="deal-container-header">
          <h2 class="sub-header">Exclusive {{merchantDisplayName}} Deals</h2>
          <div *ngIf="showMoolaDesc" class="moola-cash-desc-container" #collapseButton
            (click)="isCollapseMoolaDesc = ! isCollapseMoolaDesc" [attr.aria-expanded]="!isCollapseMoolaDesc">
            <img src="../../assets/media/icons/moola_cash_icon.png" alt="moola-cash-logo" />
            <span>What is moola cash?</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </div>
        <div class="moola-desc-tooltip" #collapseContainer #collapse="ngbCollapse"
          [(ngbCollapse)]="isCollapseMoolaDesc">
          <div class="desktop">
            <div class="row">
              <div class="col">
                <div class="desc-item">
                  <img src="../../assets/media/icons/moola-logo.png" alt="moola-logo" />
                  <span>Cash back that can be used for future purchases on the Moola App.</span>
                </div>
                <div class="desc-item">
                  <img src="../../assets/media/icons/moola-logo.png" alt="moola-logo" />
                  <span>1 Moola Cash = 1 CAD</span>
                </div>
                <div class="desc-item">
                  <img src="../../assets/media/icons/moola-logo.png" alt="moola-logo" />
                  <span>Stack Moola Cash with Merchant Deals for even deeper savings!</span>
                </div>
              </div>
              <img class="moola-coins" src="../../assets/media/icons/coins-moola.png" alt="moola-cash-img" />
            </div>
          </div>
          <div class="mobile">
            <div class="col">
              <img class="moola-coins" src="../../assets/media/icons/coins-moola.png" alt="moola-cash-img" />
              <div class="title">
                <span>What is Moola Cash?</span>
              </div>
              <div class="col align-left">
                <div class="desc-item">
                  <img src="../../assets/media/icons/moola-logo.png" alt="moola-logo" />
                  <span>Cash back that can be used for future purchases on the Moola App.</span>
                </div>
                <div class="desc-item">
                  <img src="../../assets/media/icons/moola-logo.png" alt="moola-logo" />
                  <span>1 Moola Cash = 1 CAD</span>
                </div>
                <div class="desc-item">
                  <img src="../../assets/media/icons/moola-logo.png" alt="moola-logo" />
                  <span>Stack Moola Cash with Merchant Deals for even deeper savings!</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-container">
          <div class="card-item" *ngFor="let deal of dealsWithBonus">
            <div class="card" [class.unclickable]="deal.dealStatus == 'OutOfStock'" (click)="checkIfRangedDeal(deal)">
              <div class="card-img">
                <img class="card-img-top" src="{{getMerchantCardImage(deal)}}" alt="Card image cap">
              </div>
              <div class="card-body">
                <h5 class="card-title" *ngIf="deal && deal.merchant.name && deal.merchant.name.length >= 28">
                  {{deal.merchant.name| slice:0:28 }}...</h5>
                <h5 class="card-title" *ngIf="deal && deal.merchant.name && deal.merchant.name.length < 28">
                  {{deal.merchant.name}}</h5>
                <h5 class="card-text-first">
                  {{getDealText(deal, 0)}} Get
                </h5>
                <div class="card-deal-text-container">
                  <div class="card-text-second">
                    {{getDealText(deal, 1)}}
                  </div>
                  <!-- <mat-icon>keyboard_arrow_right</mat-icon> -->
                </div>
                <div *ngIf="deal.dealStatus == 'OutOfStock' || deal.quantity > 0" class="card-footer">
                  <mat-icon>error</mat-icon>
                  <h5 class="card-out-of-stock">
                    {{getRemainingDealsOrOutOfStockText(deal)}}
                  </h5>
                </div>
              </div>
              <div class="buy-now-btn-wrapper">
                <div style="line-height: 24px; color:#efefef">spaceholder</div>
                <div>
                  <button mat-flat-button color="primary" class="buy-now-btn">Buy Now </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="regular-giftcard-container" *ngIf="deals !== undefined && getNonRangeDeals().length > 0">
        <div class="deal-container-header">Choose Card Value</div>
        <mat-divider></mat-divider>
        <div class="circle-container">
          <div class="circle-item" *ngFor="let deal of getNonRangeDeals()">
            <div class="circle" [class.unclickable]="deal.dealStatus == 'OutOfStock' || deal.dealStatus == 'OutOfQuantity'" (click)="checkIfRangedDeal(deal)">
              <div class="circle-body">
                <span class="circle-text-first">
                  Buy {{getDealText(deal, 0).split(' ')[0]}}
                </span>
                <div *ngIf="deal.dealStatus == 'OutOfStock' || deal.dealStatus == 'OutOfQuantity'" class="card-footer-sp">
                  <mat-icon role="img" class="mat-icon-small" aria-hidden="true" data-mat-icon-type="font">error</mat-icon>
                  <h5 class="card-out-of-stock-small" style="margin-bottom: 0; color: #d83737;">
                    Out of Stock
                  </h5>
                </div>
              </div>

            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
      <div class="custom-amount-container" *ngIf="deals !== undefined && getRangeDeals().length > 0">
        <div class="custom-amount" (click)="checkIfRangedDeal(getRangeDeals()[0])">
          <span>Choose Custom Amount</span>
        </div>
      </div>

      <div class="qrcode-container" *ngIf="isDesktop">
        <div class="qr-description">
          <span class="qr-desc-title">
            Exclusive Mobile-App Only Deals
          </span>
          <span class="qr-desc-subtext">
            Track your Moola Cash & securely store all cards on the Moola App.
          </span>
        </div>
        <div #qrcode class="qrcode"></div>
      </div>

      <div class="mobile-app-section" *ngIf="!isDesktop">
          <span class="mobile-app-section-desc-title">
            Exclusive Mobile-App Only Deals
          </span>
          <span class="mobile-app-section-desc-subtext">
            Track your Moola Cash & securely store all cards on the Moola App.
          </span>
          <div class="app-store-img">
            <img src="../../assets/media/icons/google_store.png" />
            <img src="../../assets/media/icons/app_store.png" />
          </div>
      </div>

      <div class="merchant-about" *ngIf="merchantDescription">
        <div class="merchant-about-header">About {{merchantDisplayName}}</div>
        <div class="merchant-about-content">{{merchantDescription}}</div>
      </div>
      <hr *ngIf="merchantDescription" />


      <div class="balance-check" *ngIf="balanceCheckUrl">
        <div class="balance-check-link">
          <a target="_blank" href="{{balanceCheckUrl}}">{{merchantDisplayName}} Gift Card Balance Check</a><img src="../../assets/media/icons/launch.png" />
        </div>
        <div class="balance-check-desc">
          <span>Easily check your {{merchantDisplayName}} Gift Card balance.</span>
        </div>
      </div>
      <hr *ngIf="balanceCheckUrl" />

      <div class="tc-container ">
        <div><a class="me-3 tc-link" (click)="openTermsAndConditionsDialog()">Terms &
            Conditions</a>
          <a class="me-1 tc-link" (click)="openRedemptionInstructionsDialog()">Redemption
            Instructions</a>
        </div>
      </div>
    </div>
  </div>

  <div class="full-width-section">
    <div class="other-deal-wrapper">
      <div class="related-deal-container">
        <div class="category-header">
          <span class="category-heading">Related Deals</span>
          <div class="category-btn-grp">
            <a (click)="seeAllMerchants(cateogryEnum)" class="view-all-link">View All</a>
            <button mat-mini-fab (click)="navBtnClick('swiper-button-prev', 'related-swiper')"
              id='{{cateogryEnum}}-left'><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button mat-mini-fab (click)="navBtnClick('swiper-button-next', 'related-swiper')"
              id='{{cateogryEnum}}-right'><mat-icon>keyboard_arrow_right</mat-icon></button>
          </div>
        </div>
        <swiper id="related-swiper" [config]="swiperDealConfig">
          <ng-template swiperSlide *ngFor="let dealMerchant of relatedmerchants; let i = index">
            <ng-container class="related-cards-wrapper"
              *ngIf="dealMerchant && dealMerchant != null && dealMerchant.merchant.name && dealMerchant.merchant.name !== 'SeeAll'">
              <div class="card" (click)="cardClickHandle(dealMerchant)">
                <div [class]="isHeroImg(dealMerchant.merchant) ? 'card-hero' : 'card-logo'">
                  <img *ngIf="isHeroImg(dealMerchant.merchant)" class="card-logo-corner"
                    src="{{getLogoImage(dealMerchant.merchant)}}" alt="Logo">
                  <img class="card-img-top" src="{{getMerchantImage(dealMerchant.merchant)}}" alt="Card image cap">
                  <!-- <img class="card-img-top" src="../../assets/media/images/TestHeroImage.png" alt="Card image cap"> -->

                </div>

                <div class="card-body">
                  <h5 class="card-title"
                    *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length >= 20">
                    {{dealMerchant.merchant.name| slice:0:20 }}...</h5>
                  <h5 class="card-title"
                    *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length < 20">
                    {{dealMerchant.merchant.name}}</h5>
                  <span class="card-text" *ngIf="dealMerchant.bonusValType != 'NoBonus'">Buy Now And Get</span>
                  <div class="card-text">
                    <div class="card-title text-black"
                      *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType!='MoolaCash' && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% OFF</div>
                    <div class="card-title text-black"
                      *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='MoolaCash' && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent / 100}}% Moola Cash</div>
                    <div class="card-title text-black"
                      *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='PercentageOff' && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% OFF</div>
                    <div class="card-title text-black"
                      *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='FixedCard') && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% {{dealMerchant.merchant.name}}</div>
                    <div class="card-title text-black"
                      *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='MoolaCash') && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent / 100}}% Moola Cash</div>
                    <div *ngIf="dealMerchant && dealMerchant.merchant.name && !dealMerchant.discountPercent"
                      class="invisible">_</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </swiper>
      </div>

      <!-- <div class="related-deal-container">
        <div class="category-header">
          <span class="category-heading">Trending in Moola</span>
          <div class="category-btn-grp">
            <a (click)="seeAllMerchants(cateogryEnum)" class="view-all-link">View All</a>
            <button mat-mini-fab (click)="navBtnClick('swiper-button-prev', 'trending-swiper')"
              id='trending-swiper-left'><mat-icon>keyboard_arrow_left</mat-icon></button>
            <button mat-mini-fab (click)="navBtnClick('swiper-button-next', 'trending-swiper')"
              id='trending-swiper-right'><mat-icon>keyboard_arrow_right</mat-icon></button>
          </div>
        </div>
        <swiper id="trending-swiper" [config]="swiperDealConfig">
          <ng-template swiperSlide *ngFor="let dealMerchant of populars; let i = index">
            <ng-container class="related-cards-wrapper"
              *ngIf="dealMerchant && dealMerchant != null && dealMerchant.merchant.name && dealMerchant.merchant.name !== 'SeeAll'">
              <div class="card" (click)="cardClickHandle(dealMerchant)">
                <div class="card-logo">
                  <img class="card-img-top" src="{{getMerchantImage(dealMerchant.merchant)}}" alt="Card image cap">
                </div>

                <div class="card-body">
                  <h5 class="card-title"
                    *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length >= 15">
                    {{dealMerchant.merchant.name| slice:0:15 }}...</h5>
                  <h5 class="card-title"
                    *ngIf="dealMerchant && dealMerchant.merchant.name && dealMerchant.merchant.name.length < 15">
                    {{dealMerchant.merchant.name}}</h5>
                  <span class="card-text" *ngIf="dealMerchant.bonusValType != 'NoBonus'">Buy Now And Get</span>
                  <div class="card-text">
                    <div class="card-title text-black"
                      *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType!='MoolaCash' && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% OFF</div>
                    <div class="card-title text-black"
                      *ngIf="!showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='MoolaCash' && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% Moola Cash</div>
                    <div class="card-title text-black"
                      *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && dealMerchant.bonusValType=='PercentageOff' && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% OFF</div>
                    <div class="card-title text-black"
                      *ngIf="showDealsInPercentage && dealMerchant && dealMerchant.merchant.name && (dealMerchant.bonusValType=='MoolaCash' || dealMerchant.bonusValType=='FixedCard') && dealMerchant.discountPercent > 0">
                      {{dealMerchant.discountPercent}}% More</div>
                    <div *ngIf="dealMerchant && dealMerchant.merchant.name && !dealMerchant.discountPercent"
                      class="invisible">_</div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </swiper>
      </div> -->
    </div>
  </div>
</div>
<app-footer-component></app-footer-component>
