<mat-dialog-content class="mat-typography">
  <button mat-icon-button aria-label="close dialog" mat-dialog-close class="close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mobile-dialog-wrapper" *ngIf="!isDesktop">
    <div class="share-banner">
      <img src="../../../../../../assets/media/icons/share-banner.png" alt="share-banner"/>
    </div>
    <div class="text">
      Share Moola with a Friend
    </div>
    <ul class="social-media-grp">
      <li>
        <p (click)="goToInfoPage('facebook')" class="terms"><img src="../../../../../../assets/media/icons/facebook-share.png" alt="facebook"/></p>
      </li>
      <li>
        <p (click)="goToInfoPage('email')" class="terms"><img src="../../../../../../assets/media/icons/mail-share.png" alt="mail"/></p>
      </li>
      <li>
        <p (click)="goToInfoPage('whatsapp')" class="terms"><img src="../../../../../../assets/media/icons/whatsapp-share.png" alt="whatsapp"/></p>
      </li>
      <li>
        <p (click)="copyToClipboard(this.shareURL)" class="terms"><img src="../../../../../../assets/media/icons/copy-link.png" alt="copy-link"/></p>
      </li>
    </ul>
  </div>

  <div class="main-dialog-wrapper" *ngIf="isDesktop">
    <div class="share-banner">
      <img src="../../../../../../assets/media/icons/share-banner.png" alt="share-banner"/>
    </div>
    <div class="text">
      Share Moola with a Friend
    </div>
    <ul class="social-media-grp">
      <li>
        <p (click)="goToInfoPage('facebook')" class="terms"><img src="../../../../../../assets/media/icons/facebook-share.png" alt="facebook"/></p>
      </li>
      <li>
        <p (click)="goToInfoPage('email')" class="terms"><img src="../../../../../../assets/media/icons/mail-share.png" alt="mail"/></p>
      </li>
      <li>
        <p (click)="goToInfoPage('whatsapp')" class="terms"><img src="../../../../../../assets/media/icons/whatsapp-share.png" alt="whatsapp"/></p>
      </li>
      <li>
        <p (click)="copyToClipboard(this.shareURL)" class="terms"><img src="../../../../../../assets/media/icons/copy-link.png" alt="copy-link"/></p>
      </li>
    </ul>
  </div>
</mat-dialog-content>
