<div>
  <app-header-component></app-header-component>


  <div class="order-confirmation-mobile-wrapper" *ngIf="!isDesktop">
    <div class="spinner-container" *ngIf="isLoading">
      <div class="title-section">
        <div class="title">
          <span>Confirming Your Order</span>
        </div>
      </div>
      <mat-spinner mat-primary></mat-spinner>
    </div>
    <div class="order-confirmation-container" *ngIf="!isLoading">
      <div class="title-section">
        <div class="title">
          <span>Order Summary</span>
        </div>
        <div class="stepper">
          <app-stepper [numSteps]="numSteps" [selectedStep]="selectedStep"></app-stepper>
        </div>

      </div>
      <div class="line-row" style="padding:0px">
        <div class="card-wrapper">
          <div class="card-item">
            <div class="card-img" [class.multiple-cards]="dealData.bonusValType == 'FixedCard'">
              <img src={{dealData.dealImg}} />
            </div>
            <div class="card-desc">
              <div class="card-title-container">
                <h5 class="card-title">Buy ${{ dealData.faceVal / 100 }}</h5>
                <h5 class="card-title" *ngIf="dealData.bonusValType != 'NoBonus'">&nbsp;& Get</h5>
              </div>
              <div class="card-deal" [ngSwitch]="dealData.bonusValType"
                [class.invisible]="dealData.bonusValType == 'NoBonus'">
                <div class="card-title-2" *ngSwitchCase="'FixedCard'">+ ${{dealData.bonusVal / 100}} Bonus</div>
                <div class="card-title-2" *ngSwitchCase="'PercentageOff'">+ {{dealData.bonusVal}}% Off</div>
                <div class="card-title-2" *ngSwitchCase="'MoolaCash'">+ {{dealData.bonusVal / 100}}% Moola Cash</div>

                <div class="card-title-2" *ngSwitchDefault>+ ${{dealData.bonusVal / 100}} Bonus</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line-row"  *ngIf="!isGift">
        <div class="left-section">
          <span class="amount-text desc-heading">Quantity</span>
        </div>
        <div class="right-section">
          <app-quantity-counter (quantityUpdate)="quantityUpdate($event)" [_value]="quantity"
            [_max]="dealData.maxPerAccountLimited ? dealData.maxPerAccount : infinity">
          </app-quantity-counter>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">Order</span>
          <span class="sub-text sub-text-1">{{ quantity }} x ${{ dealData.faceVal / 100}} Gift Card</span>
        </div>
        <div class="right-section">
          <span class="pre-amount-text">Total</span>
          <div class="right-text">
            <span class="amount-text amount-heading-1">${{calculateCardValue()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">Purchase value</span>
        </div>
        <div class="right-section">
          <span class="pre-amount-text pre-amount-text-1">${{calculateUnitTotalFaceValueStr()}} x {{quantity}}</span>
          <div class="right-text">
            <span class="amount-text amount-heading-2">${{calculateCardValue()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">Subtotal</span>
        </div>
        <div class="right-section" [ngSwitch]="dealData.bonusValType">
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'FixedCard'">Bonus Card
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'PercentageOff'">Savings
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'MoolaCash'">Bonus Moola Cash
            ${{calculateBonusValueStr()}}</span>

          <div class="right-text">
            <span class="amount-text amount-heading-3">${{calculateTotalPrice()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">You Pay</span>
        </div>
        <div class="right-section">
          <span class="amount-text amount-heading-4">${{calculateTotalPrice()}}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading bottom-marg">Payment Method</span>
          <div class="card-details-grouping">
            <img src="../../../../../../assets/media/images/visa-card.png">
            <span>XXXX XXXX XXXX {{paymentResponse.cardLastFourDigits}}</span>
          </div>
        </div>
        <div class="right-section">
          <div class="edit-btn clickable-image flex-col" (click)="openCardChangeDialog()">
            <img src="../../../../../../assets/media/icons/Replace_card_icon@2x.png">
            <span>Replace Card</span>
          </div>
        </div>
      </div>

      <!-- Recipient Info -->
      <ng-container *ngIf="giftInfo">
        <mat-divider></mat-divider>
        <div class="info-head-wrapper">
          <span class="info-heading">Recipient Info:</span>
          <span class="green-text">They get ${{dealData.faceVal / 100}} Gift Card</span>
        </div>
        <div class="line-row no-padding-top ">
          <div class="left-section">
            <div class="buyer-info-grouping">
              <span class="desc-heading">To</span>
              <span class="sub-text">{{giftInfo.recipientName}}</span>
            </div>
            <!-- delivery method = email -->
            <div class="buyer-info-grouping" *ngIf="giftInfo.deliveryMethod == 'Email'">
              <span class="desc-heading">Email</span>
              <span class="sub-text">{{giftInfo.recipientEmail}}</span>
            </div>
            <!-- delivery method = link -->
            <div class="buyer-info-grouping" *ngIf="giftInfo.deliveryMethod == 'Link'">
              <span class="sub-text">We will send you a email with a link you can share yourself</span>
            </div>
          </div>
          <div class="right-section">
            <div class="edit-btn clickable-image flex-col" (click)="goToIndex(3)">
              <span class="invisible">Replace Card</span>
              <img src="../../../../../../assets/media/icons/Edit_icon@2x.png">
              <span class="invisible">Replace Card</span>
            </div>
          </div>
        </div>
      </ng-container>

      <mat-divider></mat-divider>
      <div class="info-head-wrapper">
        <span class="info-heading">Buyer Info:</span>
        <div class="green-text" *ngIf="dealData.bonusValType == 'MoolaCash' || dealData.bonusValType == 'FixedCard'">
          <span>You Get </span>
          ${{calculateBonusValueStr()}}
          <span *ngIf="dealData.bonusValType == 'FixedCard'">Bonus Card</span>
          <span *ngIf="dealData.bonusValType == 'MoolaCash'">Moola Cash</span>
        </div>
      </div>
      <div class="line-row no-padding-top">
        <div class="left-section">
          <div class="buyer-info-grouping">
            <span class="desc-heading">Name</span>
            <span class="sub-text">{{buyerInfo.firstName}} {{buyerInfo.lastName}}</span>
          </div>
          <div class="buyer-info-grouping">
            <span class="desc-heading">Email</span>
            <span class="sub-text">{{buyerInfo.email}}</span>
          </div>
          <div class="buyer-info-grouping">
            <span class="desc-heading">Phone Number</span>
            <span class="sub-text">{{formatPhoneNumber(buyerInfo.phoneNumber)}}</span>
          </div>
        </div>
        <div class="right-section">
          <div class="edit-btn clickable-image flex-col" (click)="goToIndex(1)">
            <span class="invisible">Replace Card</span>
            <img src="../../../../../../assets/media/icons/Edit_icon@2x.png">
            <span class="invisible">Replace Card</span>
          </div>
        </div>
      </div>

      <!-- <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <div class="buyer-info-grouping">
            <span class="desc-heading">Name</span>
            <span class="sub-text">{{buyerInfo.firstName}} {{buyerInfo.lastName}}</span>
          </div>
          <div class="buyer-info-grouping">
            <span class="desc-heading">Recipient Email</span>
            <span class="sub-text">{{buyerInfo.email}}</span>
          </div>
          <div class="buyer-info-grouping">
            <span class="desc-heading">Phone Number</span>
            <span class="sub-text">{{formatPhoneNumber(buyerInfo.phoneNumber)}}</span>
          </div>
        </div>
        <div class="right-section">
          <div class="edit-btn clickable-image flex-col" (click)="goToIndex(1)">
            <span class="invisible">Replace Card</span>
            <img src="../../../../../../assets/media/icons/Edit_icon@2x.png">
            <span class="invisible">Replace Card</span>
          </div>
        </div>
      </div> -->


      <mat-divider></mat-divider>
      <mat-checkbox color="primary" [(ngModel)]="mailchimpChecked" class="mailchimp-checkbox">
        Yes, I want to receive emails about exclusive Moola deals and product updates.
      </mat-checkbox>
      <div class="btn-wrapper">
        <button class="confirm-btn" (click)="nextPage()">
          <span>Confirm & Pay</span>
        </button>
      </div>
      <div class="fine-print">
        <span>
          Fine Print
          <br>
          <br>
          All sales are final. No exchanges and no refunds. While quantities last. Not redeemable for cash and cash
          equivalent. Gift cards are non-refundable. Moola Inc. reserves the right to extend, modify, eliminate, or
          reduce this promotion at any time.
          <br>
          <br>
          By purchasing I agree to Moola's
          <a class="tc-link" (click)="openTermsAndConditionsDialog()">Terms & Conditions</a>
          &nbsp;and
          <a class="tc-link" (click)="openRedemptionInstructionsDialog()">Redemption Instructions</a>
          &nbsp;.
        </span>
      </div>
    </div>
  </div>

  <div class="order-confirmation-wrapper" *ngIf="isDesktop">
    <div class="spinner-container" *ngIf="isLoading">
      <div class="title-section">
        <div class="title">
          <span>Confirming Your Order</span>
        </div>
      </div>
      <mat-spinner mat-primary></mat-spinner>
    </div>
    <div class="order-confirmation-container" *ngIf="!isLoading">
      <div class="title-section">
        <div class="title">
          <span>Order Summary</span>
        </div>
        <div class="stepper">
          <app-stepper [numSteps]="numSteps" [selectedStep]="selectedStep"></app-stepper>
        </div>

      </div>
      <div class="row" style="padding:0px">
        <div class="card-wrapper">
          <div class="card-item">
            <div class="card-img" [class.multiple-cards]="dealData.bonusValType == 'FixedCard'">
              <img src={{dealData.dealImg}} />
            </div>
            <div class="card-desc">
              <div class="card-title-container">
                <h5 class="card-title">Buy ${{ dealData.faceVal / 100 }}</h5>
                <h5 class="card-title" *ngIf="dealData.bonusValType != 'NoBonus'">&nbsp;& Get</h5>
              </div>
              <div class="card-deal" [ngSwitch]="dealData.bonusValType"
                [class.invisible]="dealData.bonusValType == 'NoBonus'">
                <div class="card-title-2" *ngSwitchCase="'FixedCard'">+ ${{dealData.bonusVal / 100}} Bonus</div>
                <div class="card-title-2" *ngSwitchCase="'PercentageOff'">+ {{dealData.bonusVal}}% Off</div>
                <div class="card-title-2" *ngSwitchCase="'MoolaCash'">+ {{dealData.bonusVal / 100}}% Moola Cash</div>

                <div class="card-title-2" *ngSwitchDefault>+ ${{dealData.bonusVal / 100}} Bonus</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="line-row" *ngIf="!isGift">
        <div class="left-section">
          <span class="amount-text desc-heading">Quantity</span>
        </div>
        <div class="right-section">
          <app-quantity-counter (quantityUpdate)="quantityUpdate($event)" [_value]="quantity"
            [_max]="dealData.maxPerAccountLimited ? dealData.maxPerAccount : infinity">
          </app-quantity-counter>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">Order</span>
          <span class="sub-text sub-text-1">{{ quantity }} x ${{ dealData.faceVal / 100}} Gift Card</span>
        </div>
        <div class="right-section">
          <span class="pre-amount-text">Total</span>
          <div class="right-text">
            <span class="amount-text amount-heading-1">${{calculateCardValue()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">Purchase value</span>
        </div>
        <div class="right-section">
          <span class="pre-amount-text pre-amount-text-1">${{calculateUnitTotalFaceValueStr()}} x {{quantity}}</span>
          <div class="right-text">
            <span class="amount-text amount-heading-2">${{calculateCardValue()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">Subtotal</span>
        </div>
        <div class="right-section" [ngSwitch]="dealData.bonusValType">
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'FixedCard'">Bonus Card
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'PercentageOff'">Savings
            ${{calculateBonusValueStr()}}</span>
          <span class="amount-text pre-amount-text-2" *ngSwitchCase="'MoolaCash'">Bonus Moola Cash
            ${{calculateBonusValueStr()}}</span>

          <div class="right-text">
            <span class="amount-text amount-heading-3">${{calculateTotalPrice()}}</span>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading">You Pay</span>
        </div>
        <div class="right-section">
          <span class="amount-text amount-heading-4">${{calculateTotalPrice()}}</span>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="line-row">
        <div class="left-section">
          <span class="desc-heading bottom-marg">Payment Method</span>
          <div class="card-details-grouping">
            <img src="../../../../../../assets/media/images/visa-card.png">
            <span>XXXX XXXX XXXX {{paymentResponse.cardLastFourDigits}}</span>
          </div>
        </div>
        <div class="right-section">
          <div class="edit-btn clickable-image flex-col" (click)="openCardChangeDialog()">
            <img src="../../../../../../assets/media/icons/Replace_card_icon@2x.png">
            <span>Replace Card</span>
          </div>
        </div>
      </div>

      <!-- Recipient Info -->
      <ng-container *ngIf="giftInfo">
        <mat-divider></mat-divider>
        <div class="info-head-wrapper">
          <span class="info-heading">Recipient Info:</span>
          <span class="green-text">They get ${{dealData.faceVal / 100}} Gift Card</span>
        </div>
        <div class="line-row no-padding-top ">
          <div class="left-section">
            <div class="buyer-info-grouping">
              <span class="desc-heading">To</span>
              <span class="sub-text">{{giftInfo.recipientName}}</span>
            </div>
            <!-- delivery method = email -->
            <div class="buyer-info-grouping" *ngIf="giftInfo.deliveryMethod == 'Email'">
              <span class="desc-heading">Email</span>
              <span class="sub-text">{{giftInfo.recipientEmail}}</span>
            </div>
            <!-- delivery method = link -->
            <div class="buyer-info-grouping" *ngIf="giftInfo.deliveryMethod == 'Link'">
              <span class="sub-text">We will send you a email with a link you can share yourself</span>
            </div>
          </div>
          <div class="right-section">
            <div class="edit-btn clickable-image flex-col" (click)="goToIndex(3)">
              <span class="invisible">Replace Card</span>
              <img src="../../../../../../assets/media/icons/Edit_icon@2x.png">
              <span class="invisible">Replace Card</span>
            </div>
          </div>
        </div>
      </ng-container>



      <mat-divider></mat-divider>
      <div class="info-head-wrapper">
        <span class="info-heading">Buyer Info:</span>
        <div class="green-text" *ngIf="dealData.bonusValType == 'MoolaCash' || dealData.bonusValType == 'FixedCard'">
          <span>You Get </span>
          ${{calculateBonusValueStr()}}
          <span *ngIf="dealData.bonusValType == 'FixedCard'">Bonus Card</span>
          <span *ngIf="dealData.bonusValType == 'MoolaCash'">Moola Cash</span>
        </div>
      </div>
      <div class="line-row no-padding-top">
        <div class="left-section">
          <div class="buyer-info-grouping">
            <span class="desc-heading">Name</span>
            <span class="sub-text">{{buyerInfo.firstName}} {{buyerInfo.lastName}}</span>
          </div>
          <div class="buyer-info-grouping">
            <span class="desc-heading">Email</span>
            <span class="sub-text">{{buyerInfo.email}}</span>
          </div>
          <div class="buyer-info-grouping">
            <span class="desc-heading">Phone Number</span>
            <span class="sub-text">{{formatPhoneNumber(buyerInfo.phoneNumber)}}</span>
          </div>
        </div>
        <div class="right-section">
          <div class="edit-btn clickable-image flex-col" (click)="goToIndex(1)">
            <span class="invisible">Replace Card</span>
            <img src="../../../../../../assets/media/icons/Edit_icon@2x.png">
            <span class="invisible">Replace Card</span>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>
      <mat-checkbox color="primary" [(ngModel)]="mailchimpChecked" class="mailchimp-checkbox">
        Yes, I want to receive emails about exclusive Moola deals and product updates.
      </mat-checkbox>
      <div class="btn-wrapper">
        <button class="confirm-btn" (click)="nextPage()">
          <span>Confirm & Pay</span>
        </button>
      </div>
      <div class="fine-print">
        <span>
          Fine Print
          <br>
          <br>
          All sales are final. No exchanges and no refunds. While quantities last. Not redeemable for cash and cash
          equivalent. Gift cards are non-refundable. Moola Inc. reserves the right to extend, modify, eliminate, or
          reduce this promotion at any time.
          <br>
          <br>
          By purchasing I agree to Moola's
          <a class="tc-link" (click)="openTermsAndConditionsDialog()">Terms & Conditions</a>
          &nbsp;and
          <a class="tc-link" (click)="openRedemptionInstructionsDialog()">Redemption Instructions</a>
          &nbsp;.
        </span>
      </div>
    </div>
  </div>

  <app-footer-component></app-footer-component>
</div>
