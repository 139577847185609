<div id="notification-bar">
  <div>
  <p class="title mat-h3">{{title}}</p>
  <p class="message">{{message}}</p>
</div>
  <div class="dismiss">
    <button mat-icon-button (click)="snackBarRef.dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
