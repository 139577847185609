<app-header-component></app-header-component>

<app-merchant-title [merchant]="merchant" [cateogryEnum]="null" [dealData]="null" *ngIf="promoDealResponse"
  [logoPath]="moolaImageServer + promoDealResponse.merchantLogoUrl" [termsAndConditions]="null" [dynamicLinkUrl]="null"
  [dynamicLinkObs]="null" [companyWebsite]="null" [redemptionInstruction]="null" [isPromo]="true" [shareUrl]="null"
  [briefDescription]="briefDescription" [bannerColour]="null" [briefDescription2]="briefDescription2"
  [showUniqueBanner]="promoDealResponse.dealText === 'Jake Milford Charity Invitational Golf Tournament' ? true:false"
  [titleBannerUrl]="promoDealResponse.dealText === 'Jake Milford Charity Invitational Golf Tournament' ? './assets/media/images/2024/nhl_banner.jpeg': moolaImageServer + promoDealResponse.merchantBannerUrl"></app-merchant-title>

<div class="promo-wrapper" *ngIf="promoDealResponse && !isLocation && !isCheckingIPLocation">
  <div class="promo-content-wrapper"  *ngIf="showLocationWarning">
    <div class="promo-header">
      <div class="header-left">
        <img src="{{moolaImageServer + promoDealResponse.merchantCardUrl}}" alt="cardImg" class="card-img">
        <h2 style="margin-left: 1rem;">Free ${{promoDealResponse.amountInCents/100 | number:'1.0-2'}}
          Voucher
        </h2>
      </div>
      <div class="header-right">
        <div class="icon-item" *ngIf="promoDealResponse.googleMap"><img src="../../../assets/media/icons/loco_pin.png"
            alt="gm-color">
          <a href="https://maps.google.com/?q={{promoDealResponse.googleMap}}" style="font-weight: 500;"
            target="_blank">{{promoDealResponse.googleMap}}</a>
        </div>
        <div class="icon-item" *ngIf="promoDealResponse.instagram"><img src="../../../assets/media/icons/ig-color.png"
            alt="ig-color">
          <a href="https://www.instagram.com/{{promoDealResponse.instagram}}"
            target="_blank">{{promoDealResponse.instagram}}</a>
        </div>
        <div class="icon-item" *ngIf="promoDealResponse.customLink">
          <span class="material-symbols-outlined">
            menu_book
          </span>
          <a href="{{promoDealResponse.customLink}}" target="_blank">MENU</a>
        </div>
      </div>
    </div>

    <div class="deal-status-wrapper">
      <div class="deal-info-container">
        <div class="deal-live-title mb-2" *ngIf="isOutsideArea">
          Location Restricted
      </div>
        <div class="deal-live-title mb-2" *ngIf="!isOutsideArea">
            Location Permission Required
        </div>
        <hr>
        <div class="deal-quantity" *ngIf="isOutsideArea">
          You are outside the vicinity of the deal. <a (click)="redirectToRegularDeal()" class="linkStyle">View {{merchant.name}} deals in your area.</a>
        </div>
        <div class="deal-quantity" *ngIf="!isOutsideArea">
          You don't have permission to view this deal. Please go to your Settings to allow Location access and then refresh the page.
        </div>
      </div>

      <div class="fine-print center">
        <span>
          <b>Disclaimer</b>
          <br><br>
          <span [innerHTML]="this.promoDealResponse.disclaimer"></span>
          <br><br>
          <b *ngIf="this.promoDealResponse.restrictionApplied">Terms and Conditions</b>
          <br  *ngIf="this.promoDealResponse.restrictionApplied"><br  *ngIf="this.promoDealResponse.restrictionApplied">
          <span [innerHTML]="this.promoDealResponse.restrictionApplied"  *ngIf="this.promoDealResponse.restrictionApplied"></span>
          <br  *ngIf="this.promoDealResponse.restrictionApplied"><br *ngIf="this.promoDealResponse.restrictionApplied">
          <b>Fine Print</b>
          <br><br>
          All sales are final. No exchanges and no refunds. While quantities last. Not redeemable for cash and
          cash equivalent. Gift Card Vouchers are non-refundable. Moola Inc. reserves the right to extend, modify,
          eliminate, reduce this promotion at any time.
        </span>
      </div>
    </div>
  </div>
</div>

<div class="promo-wrapper" *ngIf="promoDealResponse && isLocation">
  <div class="promo-content-wrapper">
    <div class="promo-header">
      <div class="header-left">
        <img src="{{moolaImageServer + promoDealResponse.merchantCardUrl}}" alt="cardImg" class="card-img">
        <h2 style="margin-left: 1rem;">Free ${{promoDealResponse.amountInCents/100 | number:'1.0-2'}}
          Voucher
        </h2>
      </div>
      <div class="header-right">
        <div class="icon-item" *ngIf="promoDealResponse.googleMap"><img src="../../../assets/media/icons/loco_pin.png"
            alt="gm-color">
          <a href="https://maps.google.com/?q={{promoDealResponse.googleMap}}" style="font-weight: 500;"
            target="_blank">{{promoDealResponse.googleMap}}</a>
        </div>
        <div class="icon-item" *ngIf="promoDealResponse.instagram"><img src="../../../assets/media/icons/ig-color.png"
            alt="ig-color">
          <a href="https://www.instagram.com/{{promoDealResponse.instagram}}"
            target="_blank">{{promoDealResponse.instagram}}</a>
        </div>
        <div class="icon-item" *ngIf="promoDealResponse.customLink">
          <span class="material-symbols-outlined">
            menu_book
          </span>
          <a href="{{promoDealResponse.customLink}}" target="_blank">MENU</a>
        </div>
      </div>
    </div>

    <div class="deal-status-wrapper" *ngIf="promoDealResponse.status == 'Verified'">
      <div class="deal-info-container">
        <div class="deal-live-title mb-2">
          Giveaway Live On: <span style="font-weight: bold">
            {{ promoDealResponse.dateStart | date: 'EEEE MMM dd, h:mma'}}
          </span>
        </div>
        <div class="deal-live-counter">
          <span style="color: green;font-weight: bold">
            {{countDownStart$ | async | formatTime}}
          </span>
        </div>
        <hr>
        <div class="deal-quantity">
          {{promoDealResponse.quantity}} Available
        </div>
      </div>

      <div class="fine-print center">
        <span>
          <b>Disclaimer</b>
          <br><br>
          <span [innerHTML]="this.promoDealResponse.disclaimer"></span>
          <br><br>
          <b>Terms and Conditions</b>
          <br><br>
          <span [innerHTML]="this.promoDealResponse.restrictionApplied"></span>
          <br><br>
          <b>Fine Print</b>
          <br><br>
          All sales are final. No exchanges and no refunds. While quantities last. Not redeemable for cash and
          cash equivalent. Gift Card Vouchers are non-refundable. Moola Inc. reserves the right to extend, modify,
          eliminate, reduce this promotion at any time.
        </span>
      </div>
    </div>

    <div class="deal-status-wrapper"
      *ngIf="promoDealResponse.status == 'Suspended' || promoDealResponse.status == 'Expired' || promoDealResponse.status == 'OutOfQuantity'">
      <div class="deal-info-container">
        <div class="deal-live-title">
          We're sorry, but the giveaway has expired or all the available cards have been claimed. Thank you for your
          interest in {{promoDealResponse.merchantName}}.
        </div>
      </div>
    </div>

    <div class="promo-form" *ngIf="promoDealResponse.status == 'Active'">
      <div class="deal-status-wrapper">
        <div class="deal-live-counter">
          <h2>Giveaway Expires In: <span style="color: green;font-weight: bold">{{countDownEnd$ | async | formatTime
              }}</span></h2>
        </div>
        <hr>
        <div class="deal-quantity">
          Remaining Quantity: {{promoDealResponse.quantity}}
        </div>
      </div>

      <form [formGroup]="promoFormGroup" class="form-wrapper">
        <div class="row">
          <div class="form-field">
            <mat-label class="asterix--after">First Name </mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="firstName" id="firstName">
              <mat-error *ngIf="isControlHasError('firstName', 'required')">
                First Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('firstName', 'maxlength')">
                First Name cannot exceed Max Length.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-field">
            <mat-label class="asterix--after">Last Name </mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="lastName" id="lastName">
              <mat-error *ngIf="isControlHasError('lastName', 'required')">
                Last Name Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('lastName', 'maxlength')">
                Last Name cannot over Max Length.
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-field">
            <mat-label class="asterix--after" *ngIf="promoDealResponse.deliveryType !== 'Phone'">Email (Delivery
              Method)</mat-label>
            <mat-label class="asterix--after" *ngIf="promoDealResponse.deliveryType === 'Phone'">Email</mat-label>
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="email" id="email">
              <mat-error *ngIf="isControlHasError('email', 'required')">
                Email Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('email', 'pattern')">
                Must be valid email
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-field">
            <mat-label class="asterix--after" *ngIf="promoDealResponse.deliveryType === 'Phone'">Phone Number (Delivery
              Method)</mat-label>
            <mat-label *ngIf="promoDealResponse.deliveryType !== 'Phone'">Phone Number</mat-label>

            <mat-form-field appearance="outline">
              <input type="text" autocomplete="tel" inputmode="tel" matInput formControlName="phone" id="phone"
                placeholder="(XXX) XXX-XXXX" [textMask]="{mask: phoneNumberMask}">
              <mat-error *ngIf="isControlHasError('phone', 'required')">
                Phone is Required
              </mat-error>
              <mat-error *ngIf="isControlHasError('phone', 'pattern')">
                Invalid Phone Number
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="form-field">
            <div class="checkbox-wrapper">
              <mat-checkbox formControlName="checkDisclaimer" id="checkDisclaimer">
              </mat-checkbox>
              <span style="margin-left: 1rem; vertical-align: center;"> I agree to the </span><span
                (click)="openTerms()" style="text-decoration: underline;vertical-align: center;">terms and
                conditions</span>
            </div>
            <mat-error *ngIf="isControlHasError('checkDisclaimer', 'required')">
              You must agree to the terms and conditions
            </mat-error>
          </div>
        </div>
        <div class="btn-wrapper">
          <button mat-button (click)="formSubmitOnClick()" class="submit-btn" [disabled]="isLoading$ | async">
            <span>Get My Gift Voucher</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<app-footer-component></app-footer-component>