import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalyticsService } from './google-analytics.service';
import * as globals from '@app/shared/models/globals';
import { GoogleAnalyticsObject } from '@app/shared/models/deal-info.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface UserIpResponse {
  ip: string
}

export interface IpLocationResponse {
  ip: string,
  city: string, 
  loc: string
}

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    private router: Router,
    private gas: GoogleAnalyticsService,
    private http: HttpClient
  ) {}

  getLocation(dealId: string, lat: number, lon: number, distance: number): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const homeLat = lat;
        const homeLon = lon;
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        if (latitude === undefined || longitude === undefined) {
          // console.log('No latitude/longitude available.');
          resolve({result: false, isMissingData: true});
        }

        var gao: GoogleAnalyticsObject = {
          dealId: dealId
        };
        this.gas.eventEmitter(
          globals.GC_EVENT_WEB_PROMO_ALLOW_LOCATION,
          gao
        );

        const latBuffer = 0.00898 * distance; // 500 meters buffer
        const lonBuffer = (1 / (111.32 * Math.cos(homeLat))) * distance; // Longitude buffer

        const minLat = homeLat - latBuffer;
        const maxLat = homeLat + latBuffer;
        const minLon = homeLon - lonBuffer;
        const maxLon = homeLon + lonBuffer;

        // console.log('User latitude:', latitude);
        // console.log('User longitude:', longitude);
        // console.log('Min lat:', minLat, 'Max lat:', maxLat);
        // console.log('Min lon:', minLon, 'Max lon:', maxLon);

        if (latitude >= minLat && latitude <= maxLat && longitude >= minLon && longitude <= maxLon) {
          // console.log('User is within the defined location');
          resolve({result: true});
        } else {
          // console.log('User is outside the defined location');
          resolve({result: false, isOutside: true});
        }
      }, (error) => {
        // console.error('Geolocation error:', error);
        var gao: GoogleAnalyticsObject = {
          dealId: dealId
        };
        this.gas.eventEmitter(
          globals.GC_EVENT_WEB_PROMO_DISABLE_LOCATION,
          gao
        );
        resolve({result: false, isMissingData: true});
      });
    });
  }

  getUserIP(): Observable<UserIpResponse> {
    return this.http.get<UserIpResponse>('https://api.ipify.org/?format=json');
  }

  getUserLocationById(ip:string):Observable<IpLocationResponse> {
    return this.http.get<IpLocationResponse>(`https://ipinfo.io/${ip}?token=3d9c94a0643f3f`);
  }

  checkLocationByIPLatLong(loc:string, lat, lon, distance):Promise<any> {
    return new Promise((resolve, reject) => {
      const latlon: string[] = loc.split(',')
      const latitude = parseFloat(latlon[0]);
      const longitude = parseFloat(latlon[1]);
      
      const latBuffer = 0.00898 * distance; // 500 meters buffer
      const lonBuffer = (1 / (111.32 * Math.cos(lat))) * distance; // Longitude buffer

      const minLat = lat - latBuffer;
      const maxLat = lat + latBuffer;
      const minLon = lon - lonBuffer;
      const maxLon = lon + lonBuffer;
      // console.log('Using user IP Lat Lon')
      // console.log('User latitude:', latitude);
      // console.log('User longitude:', longitude);
      // console.log('Min lat:', minLat, 'Max lat:', maxLat);
      // console.log('Min lon:', minLon, 'Max lon:', maxLon);

      if (latitude >= minLat && latitude <= maxLat && longitude >= minLon && longitude <= maxLon) {
        // console.log('User is within the defined location');
        resolve({result: true});
      } else {
        // console.log('User is outside the defined location');
        resolve({result: false, isOutside: true});
      }
    })
  }
}
