export enum AppRoutes {
    MARKETPLACE = '',
    MERCHANTS = '/moola/gift-card/',
    SEARCH = 'search',
    SHOP = 'shop',

    BASKET = 'checkout/basket',
    GIFT = 'checkout/gift',
    CUSTOMER = 'checkout/customer',
    PAYMENT = 'checkout/payment',
    ORDER_CONFIRMATION = 'checkout/confirmation',
    COMPLETE = 'checkout/complete',
    PROMO_COMPLETED = 'promo/completed'
}
