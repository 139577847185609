import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from '@environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MessageDialogComponent } from '../dialogs/warning-popup-dialog/warning-popup-dialog.component';
import QRCode from 'easyqrcodejs';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { DynamicLinkResponse } from '@app/shared/models/temp-data-source';
import { ShareSocialsDialogComponent } from '../dialogs/share-socials-dialog/share-socials-dialog.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CategoryItem } from '@app/shared/models/deal-info.model';
import { SessionStorageService } from '@app/core/services/session-storage.service';

@Component({
  selector: 'app-merchant-title',
  templateUrl: './merchant-title.component.html',
  styleUrls: ['./merchant-title.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MerchantTitleComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() merchant: any;
  @Input() cateogryEnum: string;
  @Input() dealData: any;
  @Input() logoPath: string;
  @Input() termsAndConditions: string;
  @Input() redemptionInstruction: string;
  @Input() shareUrl: string;
  @Input() dynamicLinkUrl: string;
  @Input() dynamicLinkObs: Observable<DynamicLinkResponse>
  @Input() briefDescription: string;
  @Input() companyWebsite: string;
  @Input() bannerColour: string;
  @Input() titleBannerUrl: string;

  @Input() briefDescription2: string
  @Input() isPromo: boolean
  @Input() showUniqueBanner: boolean = false

  color: string;
  merchantDesc: string;



  unsubscribe: Subject<void> = new Subject<void>();

  @ViewChild('qrcode', { static: false }) qrcode: ElementRef;

  createQrCode(link: string) {

    // let userRef = this.sessionStorageService.getUserReferral();
    // if(userRef != null)
    //   link += `?action=${userRef.action}&referenceId=${userRef.referenceId}&referenceSource=${userRef.referenceSource}&referenceType=${userRef.referenceType}`;

    var options = {
      text: link,
      height: 110,
      width: 110,
      drawer: 'png',
      correctLevel: this.dynamicLinkUrl.includes('?link=')
        ? QRCode.CorrectLevel.L
        : QRCode.CorrectLevel.M,
    };
    return new QRCode(this.qrcode.nativeElement, options);
  }

  setQrCodeSize() {
    let width = window.innerWidth;
    this.qrcodeSize = 140;
    if (width > 992) {
      this.qrcodeSize = 100;
    } else if (width > 767) {
      this.qrcodeSize = 80;
    }
  }

  qrcodeSize: number;

  // titleBannerUrl: string;

  imagesBase = environment.moolaImagesServer;
  // defaultTitleImgUrl: string = "./assets/media/images/title-image.png";

  isCollapseMerchantDesc: boolean = false;
  isShowToggleBtn: boolean = false;

  showQuiznosBanner: boolean;

  isDesktop: boolean;

  categoryBanner = [
    'Retail',
    'Attractions',
    'Restaurants',
    'Services',
    'SportsOutdoors',
    'DepartmentStores',
    'Pharmacy',
    'Entertainment',
    'FoodDelivery',
    'Groceries',
  ];

  constructor(
    private router: Router,
    public dialog: MatDialog,
    // private infoConentDialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private sessionStorageService: SessionStorageService,
    private deviceService: DeviceDetectorService,
  ) {
    this.titleBannerUrl = './assets/media/images/2024/nhl_banner.jpeg';
    this.cateogryEnum = '';
  }
  ngAfterViewInit(): void {
    // if (this.dynamicLinkObs != null) {
    //   this.dynamicLinkObs.pipe(
    //     tap(data => {
    //       this.createQrCode(data.shortLink);
    //     }), takeUntil(this.unsubscribe)
    //   ).subscribe()
    // } else {
    //   this.createQrCode(this.dynamicLinkUrl)
    // }

    // this.setQrCodeSize();
  }

  ngOnInit(): void {
    // this.checkGridColumns();

    //TODO use backend banner image
    // if (this.merchant.heroFilename == null) {
    //   this.titleBannerUrl = this.imagesBase + this.dealData.categories[0].heroFilename;
    // }
    // else
    //   this.titleBannerUrl = this.imagesBase + this.merchant.heroFilename;
    this.showQuiznosBanner = this.merchant.name == "Quiznos";
    this.isDesktop = this.deviceService.isDesktop();
    this.color = 'green';
    this.toggleInfoOnClick();
  }

  toggleInfoOnClick() {

    var strArr = this.briefDescription.replace(/<\/?[^>]+(>|$)/g, "").split(' ');
    this.isCollapseMerchantDesc = !this.isCollapseMerchantDesc;
    if (this.isCollapseMerchantDesc) {
      if (strArr.length > 50) {
        this.isShowToggleBtn = true;
        this.merchantDesc = strArr.slice(0,50).join(' ') + ' ...';
      } else {
        this.merchantDesc = strArr.join(' ');
      }
    } else {
      this.merchantDesc = strArr.join(' ');
    }
  }

  getMerchantImage() {
    let dealImg = this.merchant.logoFilename; //this.isFinalSelection ? deal.dealImg : deal.merchant.logoFilename;

    return this.imagesBase + dealImg;
  }

  openMerchantWebpage() {
    window.open(this.companyWebsite, '_blank');
  }

  openTermsAndConditionsDialog() {
    this.openInfoContentDialog(this.termsAndConditions, 'Terms & Conditions');
  }

  openRedemptionInstructionsDialog() {
    this.openInfoContentDialog(
      this.redemptionInstruction,
      'Redemption Instructions'
    );
  }

  openShareUrlPage() {
    if (navigator.share && !this.isDesktop) {
      navigator.share({
        title: 'Share Moola with a Friend',
        url: this.shareUrl
      })
    } else
      this.dialog.open(ShareSocialsDialogComponent, {
        data: {
          shareURL: this.shareUrl,
          merchant: this.merchant
        },
        width: '580px',
        panelClass: 'share-socials-dialog',
        closeOnNavigation: true,
      });
  }

  openInfoContentDialog(content: string, title: string) {
    this.dialog.open(MessageDialogComponent, {
      data: {
        message: content,
        title: title,
      },
      width: '580px',
      closeOnNavigation: true,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
