import { BuyerInfo, CreateUserResponse, DeepLinkResponse, GiftInfo, PaymentMethodResponse, PromoDealResponse, WebOrderResponse } from '@app/shared/models/temp-data-source';
import { createSelector } from '@ngrx/store';
import * as fromApp from '@store/index';
import { getPromoDeal } from './purchaseOrder.actions';

export const getIsLoadingSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.isLoading,
  (isLoading: boolean) => isLoading
);

export const getMerchantCardUrl = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.merchantCardUrl,
  (merchantCardUrl: string) => merchantCardUrl
)

export const getCardQuantitySelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.cardQuantity,
  (cardQuantity: number) => { return cardQuantity }
)

export const getBuyerInfoSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.buyerInfo,
  (buyerInfo: BuyerInfo) => { return buyerInfo }
)

export const getGiftInfoSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.giftInfo,
  (giftInfo: GiftInfo) => { return giftInfo }
)

export const getUserResponseSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.createUserResponse,
  (userResponse: CreateUserResponse) => { return userResponse }
)

export const getPaymentResponseSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.paymentMethodResponse,
  (paymentResponse: PaymentMethodResponse) => { return paymentResponse }
)

export const getDeepLinkResponseSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.deepLinkResponse,
  (deepLinkResponse: DeepLinkResponse) => { return deepLinkResponse }
)

export const getWebOrderResponseSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.webOrderResponse,
  (webOrderResponse: WebOrderResponse) => { return webOrderResponse }
)

export const getPromoDealSelector = createSelector(
  (state: fromApp.AppState) => state.purchaseOrder.promoDeal,
  (promoDealResponse: PromoDealResponse) => promoDealResponse
)
