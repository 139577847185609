<mat-dialog-content class="mat-typography">
  <button mat-icon-button aria-label="close dialog" mat-dialog-close class="close-btn">
    <mat-icon>close</mat-icon>
  </button>
  <div class="mobile-dialog-wrapper" *ngIf="!isDesktop">
    <div class="card-container">
      <div class="card-item">
        <a href="{{dynamicLink}}" style="outline: 0!important;text-decoration: none;">
        <div class="card">
          <div class="card-img">
            <img class="card-img-top" src="{{getMerchantCardImage()}}" alt="Card image cap">
          </div>
          <div class="card-body">
            <h5 class="card-title" *ngIf="dealInfo.merchant.name && dealInfo.merchant.name.length >= 15">
              {{dealInfo.merchant.name| slice:0:15 }}...</h5>
            <h5 class="card-title" *ngIf="dealInfo.merchant.name && dealInfo.merchant.name.length < 15">
              {{dealInfo.merchant.name}}</h5>
            <h5 class="card-text-first">
              {{dealInfo.discountPercent != 0 || dealInfo.savedAmountInCents != 0 ? getDealText(dealInfo, 0) + ' Get' :
              'Gift Card'}}
            </h5>
            <div class="card-text-second">
              {{dealInfo.discountPercent != 0 || dealInfo.savedAmountInCents != 0 ? getDealText(dealInfo, 1):
              dealInfo.faceValType == 'Range' ?
              'Custom Amount': getDealText(dealInfo, 0).split(' ')[0]}}
            </div>
          </div>
        </div>
      </a>
      </div>
    </div>
    <div class="mobile-container-desc" >
      <span class="mobile-container-heading" (click)="openDynamicLink()">Download Moola to get this deal now</span>
      <div class="mobile-btn-grp mt-3">
        <a href="{{dynamicLink}}" style="outline: 0!important;" class="pe-1">
          <img src="../../../../../../assets/media/icons/app_store_btn.svg">
        </a>
        <a href="{{dynamicLink}}" style="outline: 0!important;">
          <img src="../../../../../../assets/media/icons/google_play_btn.svg">
        </a>
        <!-- <button (click)="openDynamicLink()"><img src="../../../../../../assets/media/icons/app_store_btn.svg"></button>
        <button (click)="openDynamicLink()"><img src="../../../../../../assets/media/icons/google_play_btn.svg"></button> -->
      </div>
    </div>
  </div>
  <div class="main-dialog-wrapper" *ngIf="isDesktop">
    <div class="left-col">
      <div class="left-content">
        <div class="text-center">
          <div class="left-header mb-1">
            Download Moola now
          </div>
          <div class="left-desc">
            Scan the QR Code on your mobile phone to
          </div>
          <div class="left-desc mb-3">
            get Moola in your app store!
          </div>
          <div class="left-qrcode mb-3">
            <div class="qrcode" #qrcode></div>
            <img class="arrow-prompt" src="../../assets/media/images/Arrow-prompt-w.svg">
          </div>
          <div class="share-link mb-3">
            <a (click)="openShareUrlPage()">Share Deal
              <mat-icon>share</mat-icon></a>
          </div>
          <div class="left-rating">
            <img src="../../assets/media/icons/star_w.png">
            <span>4.9 star rating &nbsp;&nbsp;</span>
            <img src="../../assets/media/icons/download_w.png">
            <span>109,000 total downloads</span>
          </div>
        </div>
      </div>
    </div>
    <div class="right-col" *ngIf="!dealInfo">
      <div class="right-item item-1">
        <img src="../../assets/media/images/Frame_1.png">
      </div>
      <div class="right-item item-7">
        <img src="../../assets/media/images/Frame_7.png">
      </div>
      <div class="right-item md-hidden item-3">
        <img src="../../assets/media/images/Frame_3.png">
      </div>
      <div class="right-item sm-hidden item-5">
        <div class="hero-message">Gift Cards that Give Back!</div>
      </div>
      <div class="right-item md-hidden item-8">
        <img src="../../assets/media/images/Frame_8.png">
      </div>
      <div class="right-item item-2">
        <img src="../../assets/media/images/Frame_2.png">
      </div>
      <div class="right-item item-6">
        <img src="../../assets/media/images/Frame_6.png">
      </div>
      <div class="right-item sm-hidden item-4">
        <img src="../../assets/media/images/Frame_4.png">
      </div>
      <div class="right-item sm-hidden item-9">
        <img src="../../assets/media/images/Frame_8.png">
      </div>
    </div>

    <div class="right-col" *ngIf="dealInfo">
      <div class="card-item">
        <div class="card">
          <div class="card-img">
            <img class="card-img-top" src="{{getMerchantCardImage()}}" alt="Card image cap">
          </div>
          <div class="card-body">
            <h5 class="card-title" *ngIf="dealInfo.merchant.name && dealInfo.merchant.name.length >= 15">
              {{dealInfo.merchant.name| slice:0:15 }}...</h5>
            <h5 class="card-title" *ngIf="dealInfo.merchant.name && dealInfo.merchant.name.length < 15">
              {{dealInfo.merchant.name}}</h5>
            <h5 class="card-text-first">
              {{dealInfo.discountPercent != 0 || dealInfo.savedAmountInCents != 0 ? getDealText(dealInfo, 0) + ' Get' :
              'Gift Card'}}
            </h5>
            <div class="card-text-second">
              {{dealInfo.discountPercent != 0 || dealInfo.savedAmountInCents != 0 ? getDealText(dealInfo, 1):
              dealInfo.faceValType == 'Range' ?
              'Custom Amount': getDealText(dealInfo, 0).split(' ')[0]}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
