import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * @title Input with a clear button
 */
@Component({
  selector: 'app-quantity-counter',
  templateUrl: './quantity-counter.component.html',
  styleUrls: ['./quantity-counter.component.scss'],
})
export class QuantityCounterComponent {

  @Input() _max: number = Infinity;
  @Input() _value: number = 1;
  @Output() quantityUpdate = new EventEmitter<number>();

  _step: number = 1;
  _min: number = 1;
  _wrap: boolean = false;
  color: string = 'default';

  setColor(color: string): void {
    this.color = color;
  }

  getColor(): string {
    return this.color
  }

  incrementValue(step: number = 1): void {

    let inputValue = this._value + step;

    if (this._wrap) {
      inputValue = this.wrappedValue(inputValue);
    }

    this._value = inputValue;
    this.quantityUpdate.emit(this._value);
  }

  private wrappedValue(inputValue): number {
    if (inputValue > this._max) {
      return this._min + inputValue - this._max;
    }

    if (inputValue < this._min) {

      if (this._max === Infinity) {
        return 0;
      }

      return this._max + inputValue;
    }

    return inputValue;
  }

  shouldDisableDecrement(inputValue: number): boolean {
    return !this._wrap && inputValue <= this._min;
  }

  shouldDisableIncrement(inputValue: number): boolean {
    return !this._wrap && inputValue >= this._max;
  }
}
