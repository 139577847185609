// Angular
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Components
import { MerchantTitleComponent } from './layouts/merchant-header/merchant-title.component';
import { DealCardComponent } from './layouts/deal-card/deal-card.component';
import { HeaderComponent } from './layouts/header/header.component';
import { SearchInputComponent } from './layouts/search-input/search-input.component';
import { MerchantRowComponent } from './layouts/merchant-row/merchant-row.component';
import { MessageDialogComponent } from './layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';
import { MerchantQrCodeDialogComponent } from './layouts/dialogs/merchant-qr-code-dialog/merchant-qr-code-dialog.component';
import { RangedDealDialogComponent } from './layouts/dialogs/ranged-deal-dialog/ranged-deal-dialog.component';
import { CardChangeDialogComponent } from './layouts/dialogs/card-change-dialog/card-change-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { FooterComponent } from './layouts/footer/footer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HeroComponent } from './layouts/hero/hero.component';
import { BrowserSelectDialogComponent } from './layouts/dialogs/browser-select-dialog/browser-select-dialog.component';
import { SearchBarSwiperComponent } from './layouts/search-bar-swiper/search-bar-swiper.component';
import { SwiperModule } from 'swiper/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PurchaseOrderFlowComponent } from '../../purchase-order-flow/purchase-order-flow.component';
import { PurchaseDetailsTabComponent } from '../../purchase-order-flow/purchase-details-tab/purchase-details-tab.component';
import { BuyerInfoTabComponent } from '../../purchase-order-flow/buyer-info-tab/buyer-info-tab.component';
import { CardDetailsTabComponent } from '../../purchase-order-flow/card-details-tab/card-details-tab.component';
import { MatSelectModule } from '@angular/material/select';
import { AppMaterialModule } from '@app/app-material.module';
import { OrderConfirmationTabComponent } from '../../purchase-order-flow/order-confirmation-tab/order-confirmation-tab.component';
import { OrderCompletedTabComponent } from '../../purchase-order-flow/order-completed-tab/order-completed-tab.component';
import { QuantityCounterComponent } from './layouts/quantity-counter/quantity-counter.component';
import { StepperComponent } from './layouts/stepper/stepper.component';
// Text Mask
import { TextMaskModule } from 'angular2-text-mask';
import { NotificationBarComponent } from './layouts/notification-bar/notification-bar.component';
import { ShareSocialsDialogComponent } from './layouts/dialogs/share-socials-dialog/share-socials-dialog.component';
import { GiftInfoTabComponent } from '@app/purchase-order-flow/gift-info-tab/gift-info-tab.component';
import { CardInfoBoxComponent } from '@app/purchase-order-flow/shared/card-info-box/card-info-box.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormatTimePipe, MerchantPromoComponent } from '@app/merchant-details/merchant-promo/merchant-promo.component';

@NgModule({
  declarations: [
    DealCardComponent,
    MerchantTitleComponent,
    HeaderComponent,
    FooterComponent,
    SearchInputComponent,
    MerchantRowComponent,
    MessageDialogComponent,
    MerchantQrCodeDialogComponent,
    HeroComponent,
    BrowserSelectDialogComponent,
    SearchBarSwiperComponent,
    PurchaseOrderFlowComponent,
    PurchaseDetailsTabComponent,
    GiftInfoTabComponent,
    BuyerInfoTabComponent,
    CardDetailsTabComponent,
    OrderConfirmationTabComponent,
    OrderCompletedTabComponent,
    QuantityCounterComponent,
    StepperComponent,
    NotificationBarComponent,
    RangedDealDialogComponent,
    CardChangeDialogComponent,
    ShareSocialsDialogComponent,
    CardInfoBoxComponent,
    MerchantPromoComponent,
    FormatTimePipe
  ],
  imports: [
    LayoutModule,
    CommonModule,
    MatCardModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    QRCodeModule,
    NgSelectModule,
    AutocompleteLibModule,
    NgbModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDividerModule,
    SwiperModule,
    FontAwesomeModule,
    AppMaterialModule,
    MatSnackBarModule,
    TextMaskModule,
    NgbTooltipModule,
    MatCheckboxModule,
  ],
  exports: [
    DealCardComponent,
    MerchantTitleComponent,
    HeaderComponent,
    FooterComponent,
    SearchInputComponent,
    MerchantRowComponent,
    HeroComponent,
    SearchBarSwiperComponent,
  ],
})
export class PartialsModule {}
