<form class="search-form">
  <mat-form-field appearance="outline" class="mat-form-round" *ngIf="merchants != undefined && merchants.length > 0">
    <div class="search-bar">
      <!-- <mat-icon class="search-icon">search</mat-icon> -->
      <input matInput aria-label="State" [placeholder]="placeholder" [matAutocomplete]="auto" [formControl]="merchantCtrl"
        class="search-input" (keyup.enter)="enter()">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="handleSelect($event)" id="searchAutoCompletePanel">
        <mat-option *ngFor="let merchant of filteredMerchants | async" [value]="merchant.value" class="search-option">
          <div style="display: flex; padding: 0 1rem; align-items: center;">
            <img alt="" class="merchant-icon" [src]="getMerchantImage(merchant.image)" height="35" >
            <span>{{merchant.value}}</span>
            <div>
              <span *ngIf="merchant.type == 'merchant' && !merchant.dealText.includes('Gift Card')"
                style="color: rgb(81, 202, 33); font-weight: bold; font-size: 16px; margin-left: 2rem;"> {{merchant.dealText}} </span>
              <span *ngIf="merchant.type != 'merchant'" style="color: gray;margin-left: 2rem;"> Category</span>
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>
  </mat-form-field>
  <button type="button" (click)="enter()" style="height: 39.13px;
  padding-top: 7px;
  margin-top: 4px;
  width: 80px;
  border: 0;
  color: white;
  background-color: #4c0d94;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;"
  *ngIf="merchants != undefined && merchants.length > 0">
    <mat-icon>search</mat-icon>
  </button>
</form>