import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NotificationBarComponent } from '@app/shared/partials/layouts/notification-bar/notification-bar.component';

export enum MessageType {
	Success,
	Error,
  Warn,
	Persistent
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private hPosition: MatSnackBarHorizontalPosition = 'right';
  private vPosition: MatSnackBarVerticalPosition = 'top';
  private duration: number = 2000;

  constructor(private _snackBar: MatSnackBar) { }

  private getPanelClass(messageType: MessageType): string {
    switch (messageType) {
      case MessageType.Success:
        return 'success-message';
      case MessageType.Warn:
        return 'warn-message';
      case MessageType.Error:
        return 'error-message';
      default:
        return 'persistent-message'
    }
  }

  openSnackBar(message: string, action: string, messageType: MessageType) {
    this._snackBar.openFromComponent(NotificationBarComponent, {
      data: {message: message, action: action},
      horizontalPosition: this.hPosition,
      verticalPosition: this.vPosition,
      duration: this.duration,
      panelClass: this.getPanelClass(messageType)
    })
  }
}
