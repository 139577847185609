<!-- <div [innerHTML]="data.message"></div> -->


<mat-dialog-content class="mat-typography">
  <h2 class="primary-label" mat-dialog-title>{{title}}</h2>
  <div [innerHTML]="message"></div><br>

  <h2 class="primary-label" mat-dialog-title>{{title2}}</h2>
  <div [innerHTML]="message2"></div>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button class="moola-app-button" mat-button [mat-dialog-close]="true">OK</button>
</mat-dialog-actions>
