import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Deal } from '@app/marketplace/marketplace.component';
import { environment } from '@environment';

@Component({
  selector: 'app-merchant-row',
  templateUrl: './merchant-row.component.html',
  styleUrls: ['./merchant-row.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MerchantRowComponent implements OnInit {
  @Input() isFinalSelection: boolean | undefined;
  @Input() contentList: Deal[];
  @Input() isMerchantDetailPage: boolean | undefined;
  @Input() showDealsQtd?: boolean;
  @Input() simpleDeal?: boolean;
  @Input() rowHeight?: string;
  @Input() category?: any;
  nameTruncateChars = 25;

  gridColsQtd: number = 5;
  imagesBase = environment.moolaImagesServer;
  showDealsInPercentage: boolean = true;

  constructor(private breakpointObserver: BreakpointObserver,
    private router: Router) { }

  ngOnInit(): void {
    this.rowHeight = this.rowHeight || "2:1"//'220px';
    this.checkGridColumns();
  }

  getMerchantImage(deal: any) {
    var dealImg = deal.merchant.heroFilename == null ? deal.merchant.logoFilename : deal.merchant.heroFilename;

    return this.imagesBase + dealImg;
  }

  isHeroImg(deal: any) {
    if(deal.merchant.heroFilename == null) return false;

    return true;
  }

  getLogoImage(deal: any) {
    return this.imagesBase + deal.merchant.logoFilename;
  }

  cardClickHandle(deal: any) {
    // var merchantName = deal.merchant.name.replace(/\s*/g, "").replace(/'/g, "");
    // merchantName = merchantName.replace(/'/g, "");
    var merchantName = deal.merchant.webUrlName.replace(/\s/g, "-").replace(/'/g, "");
    var logoFileName = this.imagesBase + deal.merchant.logoFilename;

    this.router.navigate([merchantName], { state: { category: this.category, merchantId: deal.merchant.id, logoPath: logoFileName }});

      // this.router.navigate([AppRoutes.MERCHANTS + deal.merchant.id + "/" + category]);
  }

   /**
   * Rendering columns according to the browser screen size
   */
    checkGridColumns() {
      this.breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ]).subscribe(result => {
        if (result.breakpoints[Breakpoints.XSmall] || result.breakpoints[Breakpoints.Small]) {
          this.gridColsQtd = 3;
          this.rowHeight = "150px";
          this.nameTruncateChars = 12;
        }
        else if (result.breakpoints[Breakpoints.Medium]) {
          this.gridColsQtd = 4;
          this.rowHeight = "230px";
          this.nameTruncateChars = 15;
        }
        else if (result.breakpoints[Breakpoints.Large] || result.breakpoints[Breakpoints.XLarge]) {
          this.gridColsQtd = 6;
          this.rowHeight = "270px";
          this.nameTruncateChars = 25;
        }
      });
    }

}
