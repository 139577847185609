import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DealData } from '@app/shared/models/temp-data-source';
import { APIEndpoint } from '@app/shared/enums/APIEndpoints';
import { CategoryItem, CategoryItemList } from '@app/shared/models/deal-info.model';
import { DynamicLinkRequest, DynamicLinkResponse } from '@app/shared/models/temp-data-source';
import { environment } from '@environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  b2CServer: string;
  baseParams = new HttpParams({ fromString: 'country=CA' });
  jsonContentType: HttpHeaders;

  constructor(private http: HttpClient) {
    this.b2CServer = environment.b2CServer;
    this.jsonContentType = new HttpHeaders({
      'Content-type': 'application/json',
    });
  }

  public getMarketplaceDeals(): Observable<any> {
    const uri = this.b2CServer + APIEndpoint.DEALS;
    let params = new HttpParams({ fromString: 'country=CA' });
    return this.http.get(uri, { headers: this.jsonContentType, params });
  }

  public getMerchant(merchantId: number): Observable<any> {
    const uri = this.b2CServer + APIEndpoint.MERCHANTS + '/' + merchantId;
    let params = new HttpParams({ fromString: 'country=CA' });
    return this.http.get(uri);
  }

  public getMerchantBySimpleName(merchantSimpleName: string): Observable<any> {
    const uri =
      this.b2CServer + APIEndpoint.MERCHANT + '/' + merchantSimpleName;
    let params = new HttpParams({ fromString: 'country=CA' });
    return this.http.get(uri);
  }

  public getRelatedMerchant(cateogryEnum: string): Observable<any> {
    const uri =
      this.b2CServer +
      APIEndpoint.CATEGORIES +
      '/' +
      cateogryEnum +
      '/merchants';
    let params = new HttpParams({ fromString: 'country=CA' });
    return this.http.get(uri);
  }

  public getMerchantsByKeyword(
    keyword: string,
    sortBy: string
  ): Observable<any> {
    const uri = this.b2CServer + APIEndpoint.DEALS;
    // let params = this.baseParams.append('keyword', keyword);
    // params = params.append('sortBy', sortBy);
    let params = this.baseParams.append('sortBy', sortBy);
    if (keyword != 'all') {
      params = params.append('keyword', keyword);
    }
    return this.http.get<DealData>(uri, { params: params });
  }

  public getCategories(): Observable<CategoryItemList> {
    const uri = this.b2CServer + APIEndpoint.CATEGORIES;
    return this.http.get<CategoryItemList>(uri);
  }

  public createDynamicLinks(req: DynamicLinkRequest): Observable<DynamicLinkResponse> {
    const uri = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB2S3PF6H6V88dxmOO1-WTGlUGz9AGBSUs';
    return this.http.post<DynamicLinkResponse>(uri, req, {headers: new HttpHeaders({ 'Content-type': 'application/json' })});

  }
}
