import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppService } from '@app/core/services/app.services';
import { AppRoutes } from '@app/shared/enums/app-routes.enum';
import { environment } from '@environment';
import {
  filter,
  Observable,
  Subject,
  Subscription,
  takeUntil,
  tap,
} from 'rxjs';
import { ElementRef, NgZone } from '@angular/core';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from 'swiper';

import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from '@app/core/services/google-analytics.service';
import * as globals from '@app/shared/models/globals';
import {
  CategoryItem,
  CategoryItemList,
  GoogleAnalyticsObject,
  ReferralInfo,
} from '@app/shared/models/deal-info.model';
import { SessionStorageService } from '@app/core/services/session-storage.service';
import { BrowserSelectDialogComponent } from '@app/shared/partials/layouts/dialogs/browser-select-dialog/browser-select-dialog.component';
import { NgDialogAnimationService } from '@app/core/services/dialog-animation.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageType, NotificationService } from '@app/core/services/notification.service';
import { MailchimpTemplate } from '@app/shared/models/temp-data-source';
import { MailchimpService } from '@app/core/services/mailchimp.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '@app/shared/partials/layouts/dialogs/warning-popup-dialog/warning-popup-dialog.component';

import * as PurchaseOrderActions from '@app/core/store/purchaseOrder/purchaseOrder.actions'
import { Store } from '@ngrx/store';
import * as fromApp from '@store/index'

SwiperCore.use([Navigation, Autoplay]);

export interface Category {
  enumValue: string;
  text: string;
  dealIds: number[];
  featured: boolean;
  isCurated: boolean;
  page: number;
  type: string;
  isPromotional?: boolean;
}

export interface Merchant {
  countOfActiveDeals?: number;
  id?: number;
  logoFilename?: string;
  heroFilename? : string;
  name: string;
  status?: string;
  webUrlName?: string;
  quantityLimited?: boolean;
  savedAmountInCents?: number;
}

export interface Deal {
  bonusValType?: string;
  bonusVal?: string;
  categories?: Category[];
  dealImg?: string;
  dealMarketPlace?: string;
  discountPercent?: number;
  faceVal?: number;
  faceValMin?: number;
  faceValType?: string;
  featureImg?: string;
  featureWebImg?: string;
  id?: number;
  keywords?: string[];
  maxPerAccountLimited?: boolean;
  merchant?: Merchant;
  quantityLimited?: number;
  savedAmountInCents?: number;
}

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
  styleUrls: ['./marketplace.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarketplaceComponent implements OnInit, OnDestroy {
  isLoading: boolean = true;
  titleImgUrl: string = './assets/media/images/title-image.png';
  dealsInfo$!: Observable<any>;
  dealsInfo: any;
  categories: Category[];
  featuredDeals$!: Observable<any[]>;
  featuredDeals!: any[];
  featuredBannersDisplayed: boolean = false;
  numBannerShowed = 0;
  unsubscribe: Subject<void> = new Subject<void>;
  gridColsQtd: number = 6;
  nameTruncateChars = 4;
  // responsiveOptions!: any[];
  images: any;
  imagesBase = environment.moolaImagesServer;
  searchMode: boolean = false;
  adBannerCount: number = 0;
  marketplaceStructuredData!: {};
  mobilemode!: Boolean | true;
  showDealsInPercentage: boolean = true;
  dealsObsSubscription: Subscription;

  categoryItemList$: Observable<CategoryItemList>;
  categoryItems: CategoryItem[] = [];
  emailAddress: string;

  emailFormGroup: FormGroup
  emailFormControl = new FormControl('', [Validators.required, Validators.email])
  panelOpenState = false;

  influencerToken: string;
  isDesktop: boolean = false;

  swiperFilterConfig = {
    navigation: true,
  };

  swiperFilterBreakPoints = {
    350: {
      slidesPerView: 1,
      spaceBetween: 50,
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 0,
    },
  };

  swiperDealConfig = {
    navigation: true,
  };

  swiperDealBreakPoints = {
    350: {
      slidesPerView: 2.3,
      spaceBetween: 20,
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 25,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 32,
    },
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private meta: Meta,
    private title: Title,
    public el: ElementRef,
    public zone: NgZone,
    private deviceService: DeviceDetectorService,
    private gas: GoogleAnalyticsService,
    private sessionStorageService: SessionStorageService,
    private openInDialog: NgDialogAnimationService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private mailChimpService: MailchimpService,
    private infoConentDialog: MatDialog,
    private store: Store<fromApp.AppState>,
  ) {

    this.setTitle('Moola | Cash Back Gift Cards');
  }

  ngOnInit(): void {
    this.isDesktop = this.deviceService.isDesktop();
    this.store.dispatch(PurchaseOrderActions.getPortalStatus());
    this.emailFormGroup = this.fb.group({
      email: this.emailFormControl
    })

    this.searchMode = false;
    this.dealsInfo$ = this.appService.getMarketplaceDeals();

    this.influencerToken = this.activatedRoute.snapshot.queryParamMap.get('ref');
    this.SetUserReferral(this.activatedRoute.snapshot.queryParams);
    // this.checkGridColumns();
    this.resetSEO();
    // setTimeout(() => {
      this.showPreferenceDialog();
    // }, 500);
    this.dealsInfo$
      .pipe(
        tap((dealsInfo: { bisCategories: any[]; campaigns: any[] }) => {
          if (!dealsInfo) return;
          this.dealsInfo = dealsInfo;
          this.categories = dealsInfo.bisCategories.filter(
            (category) =>
              category.enumValue != 'Featured' && category.enumValue != 'NearYou' && category.enumValue != 'ForYou'
          ).slice(0,10);
          this.featuredDeals =
            dealsInfo.bisCategories.filter(
              (category) => category.enumValue == 'Featured'
            ) != null &&
            dealsInfo.bisCategories.filter(
              (category) => category.enumValue == 'Featured'
            ).length > 0
              ? this.getDeals(
                  dealsInfo.bisCategories.filter(
                    (category) => category.enumValue == 'Featured'
                  )[0].dealIds
                ).filter(
                  (deal) => deal.id != null && deal.featureWebImg != null
                )
              : [];
          for (let category of this.categories) {
            category.page = 1;
          }
          // this.checkGridColumns();
          this.setSEO();
          this.isLoading = false;
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    this.categoryItemList$ = this.appService.getCategories();

    this.categoryItemList$
      .pipe(
        tap((data) => {

          var item: CategoryItem = {
            enumValue: 'all',
            text: 'All',
            type: 'Consumer',
            isCurated: false,
            isPromotional: true,
            isParent: true,
            status: 'Active',
            categoryIconFilename: "cat_icon_services.png",
            categoryHeroFilename: "hero_popular.jpg"
          };

          this.categoryItems = data.categories.filter(
            (cat) => !cat.isPromotional
          );
          // this.categoryItems = data.categories.filter(obj =>
          //   this.dealsInfo?.bisCategories.some(refObj => refObj.enumValue === obj.enumValue)
          // );

          this.categoryItems = this.categoryItems.filter(c => !c.isPromotional && c.type === 'Consumer');
          this.categoryItems.unshift(item);
        }),
        takeUntil(this.unsubscribe)
      )
      .subscribe();

    this.gas.eventEmitter(globals.GC_EVENT_LANDING_PAGE);

    // setTimeout(() => {
    //   if (!this.sessionStorageService.getOrderEnabled()) {
    //     this.openOrderDisabledPopUp();
    //   }
    // }, 500)
  }


  showPreferenceDialog() {
    if (this.deviceService.isDesktop() || this.sessionStorageService.getBrowserPreference() != null) return;

    this.sessionStorageService.setBrowserPerference(globals.VIEW_PREFERENCE_APP);

    var test = window.innerHeight - 181;

    this.openInDialog.open(BrowserSelectDialogComponent, {
      data: {
        dynamicLinkUrl: this.influencerToken == null ? 'https://applink.moola.com/?action=merchantAction'
          : 'https://moolamarketplace.page.link/' + this.influencerToken
      },
      position: { top: test + 'px' },
      width: '100%',
      maxWidth: '100vw',
      animation: { to: 'top' },
      panelClass: 'select-browser-wrapper',
      autoFocus: false,
    });

  }

  setTitle(title: string) {
    this.title.setTitle(title);
  }

  getMerchantImage(deal: any) {
    var imageToUse = deal.merchant.heroFilename == null ? deal.merchant.logoFilename : deal.merchant.heroFilename;
    return this.imagesBase + imageToUse;
  }

  getLogoImage(deal: any) {
    return this.imagesBase + deal.merchant.logoFilename;
  }

  isHeroImg(deal: any) {
    if(deal.merchant.heroFilename == null) return false;
    return true;
  }

  navBtnClick(value: string, parentDivId: any) {
    var targetSwiper = document.getElementById(parentDivId.name).getElementsByTagName('swiper');
    var btn = targetSwiper[0].getElementsByClassName(value)[0] as HTMLElement;
    btn.click();
  }

  /**
   * Gets the deal by ID
   * @param dealId
   * @returns The deal according to its ID to build the card
   */
  getDeal(dealId: any) {
    let deal = this.dealsInfo.deals.find(
      (deal: { id: any }) => deal.id === dealId
    );
    return deal;
  }

  getDeals(dealIds: any[]) {
    var merchants: Deal[] = [];
    for (let dealId of dealIds) {
      var merchant: Deal = this.dealsInfo.deals.find(
        (deal: { id: any }) => deal.id === dealId
      );
      merchants.push(merchant);
    }

    if (merchants.length < this.gridColsQtd) {
      let m = {
        name: '',
      };
      let buffer = {
        merchant: m,
      };
      let temp = new Array(5);
      merchants.forEach((val) => temp.push(Object.assign({}, val)));
      merchants = temp.fill(buffer, merchants.length, 5).reverse();
    } else if (merchants.length > this.gridColsQtd) {
      var merchantItem: Merchant = {
        name: 'SeeAll',
      };
      var dealItem: Deal = {
        merchant: merchantItem,
      };
      merchants.push(dealItem);
    }
    return merchants;
  }

  getMerchantExtraInfo(deal: any, category: any) {
    //d.merchant.name category.enumValue
    return category.enumValue;
  }

  getCarouselClass(dealIds: any[], category: any) {
    if (this.mobilemode) {
      if (dealIds.length <= this.gridColsQtd) {
        return 'disable-mobile-carousel-navigation';
      }
      return 'disable-left-carousel-navigation';
    }
    if (dealIds.length <= this.gridColsQtd) {
      return 'disable-carousel-navigation';
    }
    return '';
  }

  merchantClick(
    merchantWebUrlName: string,
    merchantId: number,
    logoFilename: string,
    category: any
  ) {
    var merchantName = merchantWebUrlName.replace(/\s/g, '-').replace(/'/g, '');

    var logoFileName = this.imagesBase + logoFilename;
    this.router.navigate([merchantName], {
      state: {
        category: category,
        merchantId: merchantId,
        logoPath: logoFileName,
      },
    });
  }

  cardClickHandle(deal: Deal, category: any) {
    var gao: GoogleAnalyticsObject = { deal: deal, eventValue: category };
    this.gas.eventEmitter(globals.GA_EVENT_VIEW_DEAL, gao);
    var merchantName = deal.merchant.webUrlName
      .replace(/\s/g, '-')
      .replace(/'/g, '');

    var logoFileName = this.imagesBase + deal.merchant.logoFilename;
    this.router.navigate([merchantName], {
      state: {
        category: category,
        merchantId: deal.merchant.id,
        logoPath: logoFileName,
      },
    });
  }

  seeAllMerchants(category: any) {
    this.gas.eventEmitter(globals.GA_EVENT_BANNER_CLICK, {
      eventValue: category,
    });
    this.router.navigate([AppRoutes.SEARCH], { state: { keyword: category } });
  }

  onSearchChange(event: any) {
    if (event && event.value) {
      const keyword = event.value;
      this.searchMode = true;
    } else {
      this.searchMode = false;
    }
  }

  getBanner(counter: number) {
    let index = (counter + 1) / 3 - 1 - this.featuredDeals.length;
    if (this.dealsInfo.campaigns.length > index) {
      return this.dealsInfo.campaigns[index];
    }
  }

  getBannerImage(bannerName: string) {
    return bannerName != null ? this.imagesBase + bannerName : '';
  }

  getBannerName(banner: any) {
    const name =
      banner != null && banner.length > 0 ? banner.category.text : '';
    return name;
  }

  onBannerClick(category: string) {}

  getFeaturedBanner(counter: number) {
    let index = (counter + 1) / 3 - 1;
    return this.featuredDeals[index].id != null &&
      this.featuredDeals[index].featureWebImg != null
      ? this.featuredDeals[index]
      : null;
  }

  getFeatureBannerImage(bannerName: string) {
    const imgName = bannerName != null ? this.imagesBase + bannerName : '';
    // const imgName = "./assets/media/images/test-feature-banner.jpg";

    return imgName;
  }

  onFeatureBannerClick(featureBanner: any) {
    this.gas.eventEmitter(globals.GA_EVENT_BANNER_CLICK, {
      eventValue: featureBanner,
    });

    var merchantName = featureBanner.merchant.name;
    var logoFileName = this.imagesBase + featureBanner.merchant.logoFilename;

    this.router.navigate([merchantName], {
      state: {
        category: null,
        merchantId: featureBanner.merchant.id,
        logoPath: logoFileName,
      },
    });
  }

  getCategoryIcon(category: CategoryItem) {
    return this.imagesBase + category.categoryIconFilename;
  }

  showMarketPlace() {
    this.router.navigate([AppRoutes.MARKETPLACE]);
  }

  setSEO() {
    const script = document.createElement('script');
    script.setAttribute('type', 'application/ld+json');
    this.marketplaceStructuredData = {
      '@context': 'https://schema.org/',
      '@type': 'Product',
      name: 'Moola Marketplace',
      image: [
        'https://content.moola.com/wp-content/uploads/2019/10/Logo_Desktop_purple.svg',
      ],
      description: 'Digital gift card marketplace',
      brand: {
        '@type': 'Brand',
        name: 'Moola',
      },
      shippingDetails: {
        '@type': 'OfferShippingDetails',
        shippingRate: {
          '@type': 'MonetaryAmount',
          value: '0',
          currency: 'CAD',
        },
        shippingDestination: [
          {
            '@type': 'DefinedRegion',
            addressCountry: 'CA',
          },
        ],
      },
      offers: {
        '@type': 'Offer',
        url: 'https://market.qa.moola.com/moola',
        priceCurrency: 'CAD',
        price: '0',
        itemCondition: 'https://schema.org/New',
        availability: 'https://schema.org/InStock',
      },
    };
    script.textContent = JSON.stringify(this.marketplaceStructuredData);
    document.head.appendChild(script);

    this.meta.addTags([
      {
        name: 'description',
        content:
          'Send a gift and get a reward. Shop cash back gift cards. Buy for yourself, friends or family. Choose from over 250 brands and save up to 15%.',
      },
      { name: 'author', content: 'Moola' },
      {
        name: 'keywords',
        content:
          'Moola, Web Marketplace, Marketplace, Giftcards, Gifts, Shopping, Cash back,' +
          'Discount Code, Promo Code, Coupon Code, Savings, Gift Card'
      },

      { name: 'robots', content: 'index, follow' },
      { property:'og:title', content: 'Moola | Cash Back Gift Cards'},
      { property:'og:description', content: 'Send a gift and get a reward. Shop cash back gift cards. Buy for yourself, friends or family. Choose from over 250 brands and save up to 15%.'},
      { property:'og:type', content: 'website'},
      { property:'og:url', content: 'https://www.moola.com'},
      { property:'og:image', content: 'https://content.moola.com/wp-content/uploads/2023/04/og-image.png'},
    ]);
  }

  resetSEO() {
    if (
      document.head.getElementsByTagName('script') != null &&
      document.head.getElementsByTagName('script').length > 0
    ) {
      document.head.removeChild(
        document.head.getElementsByTagName('script')[0]
      );
    }
    (
      document.querySelector("[name='description']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[name='keywords']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[name='description']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[name='keywords']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:title']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:description']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:type']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:url']") || { remove: () => 0 }
    ).remove();
    (
      document.querySelector("[property='og:image']") || { remove: () => 0 }
    ).remove();
  }

  categoryBtnOnClick(category: CategoryItem) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    if (category == null) {
      // this.router.navigate(['/']);
      this.router.navigate([AppRoutes.SEARCH], {
        state: { keyword: 'all' },
      });
    } else {
      this.router.navigate([AppRoutes.SEARCH], {
        state: { keyword: category.enumValue },
      });
    }
  }

  SetUserReferral(routeParams) {
    let userReferralReference = this.sessionStorageService.getUserReferral();
    if (routeParams["referenceSource"]) {
      userReferralReference = {
        "referenceId": routeParams["referenceId"].toString(),
        "referenceSource": routeParams["referenceSource"],
        "referenceType": routeParams["referenceType"] ? routeParams["referenceType"] : "Merchant",
        "action": routeParams["action"]
      }
    }
    this.sessionStorageService.setUserReferral(userReferralReference);
  }

  submitEmailAddress() {
    let tempEmail: string = this.emailFormControl.value;
    this.emailFormGroup.controls['email'].setValue('');

    this.notificationService.openSnackBar(
      'Thank you!',
      tempEmail + ' is in the list!',
      MessageType.Success)

      const mailchimpTemplate: MailchimpTemplate = {
        MMERGE9: '',
        FNAME: '',
        LNAME: '',
        PHONE: ''
      }

      this.mailChimpService.addEmailToList(
        tempEmail,
        mailchimpTemplate,
        'Email List'
      ).subscribe()
  }

  redirectTo(value: string) {
    switch (value) {
      case 'game':
        this.router.navigate([AppRoutes.SEARCH], {
          state: { keyword: 'WeeklyDeals' },
        });
        break;
      case 'weeklyDeals':
        // this.router.navigate([AppRoutes.SEARCH], {
        //   state: { keyword: 'WeeklyDeals' },
        // });
        window.open('https://content.moola.com/winter-holiday-gift-sale-2024/', '_self');
        break;
      case 'doordash':
        this.router.navigate(['/doordash']);
        break;
      case 'spotify':
        this.router.navigate(['/spotify']);
        break;
      case 'how':
        window.location.href = 'https://content.moola.com/';
        break;
    
      default:
        break;
    }
  }

  openOrderDisabledPopUp() {
    this.infoConentDialog.open(MessageDialogComponent, {
      data: {
        message: 'Purchasing gift cards on www.moola.com is currently unavailable. Please check back soon.',
        title: 'Sorry!! Something went wrong on our end.'
      }, 
      width: '580px',
      closeOnNavigation: true
    })
  }

  ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }
}
