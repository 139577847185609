import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from "@environment";

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router
    ) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          gtag('config', environment.googleAnlyticsId, { 'page_path': event.urlAfterRedirects });
        }
      })
    }


  title = 'moola-web-marketplace';
}
