<div id="moola-footer">
  <div class="sub-footer">
    <div class="wrapper">
      <div class="left">
        <span class="desc">Accepted Payment Methods</span>
        <img src="../../../../../assets/media/icons/visa.svg" class="card-img" />
        <img src="../../../../../assets/media/icons/mastercard.svg" class="card-img" />
        <!-- <img src="../../../../../assets/media/icons/amex.svg" class="card-img" /> -->
        <img src="../../../../../assets/media/icons/discover.svg" class="card-img" />
      </div>
      <div class="right">
        <img src="../../../../../assets/media/icons/lock.svg" class="lock-icon"/>
        <span class="desc">Payments Powered by</span>
        <div style="padding-bottom: 13px;">
          <img src="../../../../../assets/media/icons/moneris.svg" class="moneris-icon">
        </div>

      </div>
    </div>

  </div>
  <div class="main-footer">
    <div>
      <ul class="mt-xxl-5 pt-3">
        <li>
          <a href="https://www.moola.com">
            <img width="140" height="28"
              src="https://content.moola.com/wp-content/uploads/2019/10/Logo_Desktop_white.svg" class="moola-logo"
              alt="" loading="lazy">
          </a>
        </li>
        <li>
          <p (click)="goToInfoPage('PrivacyPolicy')" class="terms">Privacy Policy</p>
        </li>
        <li>
          <p (click)="goToInfoPage('TermsConditions')" class="terms">Terms & Conditions</p>
        </li>
        <li>
          <p (click)="goToInfoPage('HelpCenter')" class="terms">Help Center</p>
        </li>
        <li>
          <p (click)="goToInfoPage('ContactUs')" class="terms">Contact Us</p>
        </li>
      </ul>
    </div>
    <div>
      <ul class="social-media-grp">
        <li>
          <p (click)="goToInfoPage('instagram')" class="terms"><fa-icon [icon]="faInstagram"></fa-icon></p>
        </li>
        <li>
          <p (click)="goToInfoPage('twitter')" class="terms"><fa-icon [icon]="faTwitter"></fa-icon></p>
        </li>
        <li>
          <p (click)="goToInfoPage('facebook')" class="terms"><fa-icon [icon]="faFacebookF"></fa-icon></p>
        </li>
        <li>
          <p (click)="goToInfoPage('youtube')" class="terms"><fa-icon [icon]="faYoutube"></fa-icon></p>
        </li>
        <li>
          <p (click)="goToInfoPage('linkedin')" class="terms"><fa-icon [icon]="faLinkedin"></fa-icon></p>
        </li>
      </ul>
    </div>
    <P class="terms-conditions p-3">
      Designated trademarks and brands are the property of their respective owners. Moola Inc. and its products are not
      affiliated or endorsed by such owners. Use of this (website/app) constitutes acceptance of Moola Inc. Privacy
      Policy and Terms of Use. Copyright © {{currenDate | date : 'yyyy'}} Moola Inc. All rights reserved.
    </P>
  </div>
  <div class="copyright-footer">
    Copyright © {{currenDate | date : 'yyyy'}} Moola Inc. All rights reserved.
  </div>
  <div *ngIf="mobilemode">
    <img src="./assets/media/images/app-store.png" alt="Apple App Store" class="app-buttons mt-3 mb-4"
      (click)="goToInfoPage('Apple')">
    <img src="./assets/media/images/play-store.png" alt="Google Play Store" class="app-buttons mt-3 mb-4"
      (click)="goToInfoPage('Google')">
  </div>
</div>
