export enum AppVariables {
    BONUS = 'Bonus',
    FIXED = 'Fixed',
    FEATURED = 'Featured',
    FIXED_CARD = 'FixedCard',
    GIFT_CARD = 'Gift Card',
    MERCHANT = 'merchant',
    NO_BONUS = 'NoBonus',
    PERCENTAGE_OFF = 'PercentageOff',
    RANGE = 'Range',
    MOOLA_CASH = 'MoolaCash',
    CATEGORY = 'Category'
}